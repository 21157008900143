/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SampleCreationDTO } from '../models/SampleCreationDTO';
import type { SampleDTO } from '../models/SampleDTO';
import type { SamplePatchDTO } from '../models/SamplePatchDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SampleService {
    /**
     * @param containerId
     * @returns SampleDTO Success
     * @throws ApiError
     */
    public static getSamples(
        containerId?: string,
    ): CancelablePromise<Array<SampleDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/samples',
            query: {
                'containerId': containerId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createSample(
        requestBody: SampleCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/samples',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param sampleId
     * @returns SampleDTO Success
     * @throws ApiError
     */
    public static getSampleById(
        sampleId: string,
    ): CancelablePromise<SampleDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/samples/{sampleId}',
            path: {
                'sampleId': sampleId,
            },
        });
    }
    /**
     * @param sampleId
     * @returns any Success
     * @throws ApiError
     */
    public static deleteSampleById(
        sampleId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/samples/{sampleId}',
            path: {
                'sampleId': sampleId,
            },
        });
    }
    /**
     * @param sampleId
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static updateSampleById(
        sampleId: string,
        requestBody: SamplePatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/samples/{sampleId}',
            path: {
                'sampleId': sampleId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
