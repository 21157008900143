import { Alert, Loader, Select, SelectProps } from '@mantine/core';
import { ReactNode } from 'react';
import { useFacilities } from '../api/facilities';
import { FacilityId } from '../rest-client';

interface FacilitySelectSpecificProps {
  value: FacilityId | null;
  onChange: (id: FacilityId | null) => void;
  emptyContent?: ReactNode;
  withAsterisk?: boolean;
}

type FacilitySelectProps = Omit<
  SelectProps,
  keyof FacilitySelectSpecificProps | 'data'
> &
  FacilitySelectSpecificProps;

export function FacilitySelect(props: FacilitySelectProps) {
  const {
    value,
    onChange,
    emptyContent = (
      <Alert title='No Facilities' color='yellow'>
        No available Facilities
      </Alert>
    ),
    withAsterisk = false,
    ...rest
  } = props;

  const facilitiesQuery = useFacilities();

  if (facilitiesQuery.isLoadingError) {
    throw facilitiesQuery.error;
  }

  if (facilitiesQuery.data) {
    const facilities = facilitiesQuery.data;
    if (facilities.length === 0) return <>{emptyContent}</>;
    return (
      <Select
        label='Facility'
        placeholder='select Facility'
        withAsterisk={withAsterisk}
        clearable
        value={value}
        onChange={onChange}
        data={facilities.map(({ id, name }) => ({ label: name, value: id }))}
        {...rest}
      />
    );
  }

  return <Select disabled data={[]} rightSection={<Loader size='xs' />} />;
}
