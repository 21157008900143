import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useFacilityContext } from '../Facility/FacilityContext';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import { useAddContainer } from '../api/container';
import { MaterialContainerCreationDTO } from '../rest-client';
import { Router } from '../router';
import {
  ContainerFormFields,
  ContainerFormValues,
  useContainerForm,
} from './ContainerForm';

export type AddContainerDrawerFormProps = {
  onSuccess?: (container: MaterialContainerCreationDTO) => void;
} & DrawerFormDrawerProps;

export function AddContainerDrawerForm(props: AddContainerDrawerFormProps) {
  const {
    onSuccess = (container: MaterialContainerCreationDTO) => {
      /**
       * Route to container detail page by default because the container will not show up in the
       * container inventory page post-add because it default filters to hide empty containers
       */
      Router.replace('ContainerDetail', { containerId: container.id });
    },
    ...drawerFormDrawerProps
  } = props;

  const createMutation = useAddContainer();
  const form = useContainerForm();
  const facility = useFacilityContext();
  const [id, setId] = useState(uuidv4);

  const formValuesToDto = ({
    name,
    containerType,
    overrideTareWeight,
  }: ContainerFormValues) => {
    if (containerType === null) {
      throw new Error('Container type cannot be null');
    }

    const container: MaterialContainerCreationDTO = {
      id,
      facilityId: facility.id,
      name: name,
      containerTypeId: containerType.id,
      overrideTareWeight: null,
    };

    if (overrideTareWeight.enabled) {
      if (overrideTareWeight.ticks === null) {
        throw new Error(
          'Tare weight override ticks cannot be null if override tare weight option is enabled',
        );
      }
      container.overrideTareWeight = {
        unit: overrideTareWeight.unit,
        tickSize: `${overrideTareWeight.tickSize}`,
        ticks: overrideTareWeight.ticks,
      };
    }

    setId(uuidv4());
    return container;
  };

  return (
    <DrawerForm
      entityName='Container'
      form={form}
      formVariant='create'
      mutation={createMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      {...drawerFormDrawerProps}
    >
      <ContainerFormFields form={form} />
    </DrawerForm>
  );
}
