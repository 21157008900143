import { Alert, Button, Flex, Group, Stack } from '@mantine/core';
import { UseFormReturnType, useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconSwitchHorizontal, IconX } from '@tabler/icons-react';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ContainerSelect from '../Container/ContainerSelect';
import { useFacilityContext } from '../Facility/FacilityContext';
import { FormActionButton } from '../Form/FormActionButton';
import { useFormNavBlocker } from '../Form/useFormNavBlocker';
import ContainerFullnessSwitch from '../Input/ContainerFullnessSwitch';
import { RequiredDayjsDateTimePicker } from '../Time/DayjsDateTimePicker';
import { useAddTruckLoadTransfer } from '../api/truckLoad';
import { MaterialTruckLoadTransferCreationDTO } from '../rest-client';
import { Router } from '../router';
import { TruckLoadSelect } from './TruckLoadSelect';

export interface TruckLoadTransferFormValues {
  timestamp: Dayjs;
  containerId: string | null;
  truckLoadId: string | null;
  sourceEmptied: boolean;
  destinationFilled: boolean;
  unload: boolean;
}

function useTruckLoadTransferForm() {
  return useForm<TruckLoadTransferFormValues>({
    initialValues: {
      timestamp: dayjs.utc(),
      containerId: null,
      truckLoadId: null,
      sourceEmptied: true,
      destinationFilled: true,
      unload: true,
    },
    validate: {
      containerId: (c) => (c ? null : 'Container is required'),
      truckLoadId: (t) => (t ? null : 'Truck load is required'),
    },
  });
}

function TruckLoadTransferFormFields(props: {
  form: UseFormReturnType<TruckLoadTransferFormValues>;
}) {
  const { form } = props;
  const facility = useFacilityContext();

  const unload = form.values.unload;

  const containerSelect = (
    <ContainerSelect
      label={unload ? 'Destination Container' : 'Source Container'}
      timestamp={form.values.timestamp}
      facilityId={facility.id}
      // TODO(2335): Allow overriding hiding
      hideEmpty={false} // TODO(2335): Do the right thing depending on load/unload
      hideFull={false} // TODO(2335): Do the right thing depending on load/unload
      clearable
      required
      w='100%'
      {...form.getInputProps('containerId')}
    />
  );

  const truckLoadSelect = (
    <TruckLoadSelect
      label={unload ? 'Source Truck Load' : 'Destination Truck Load'}
      timestamp={form.values.timestamp}
      clearable
      required
      w='100%'
      {...form.getInputProps('truckLoadId')}
    />
  );

  return (
    <>
      <RequiredDayjsDateTimePicker
        {...form.getInputProps('timestamp')}
        tz={facility.timeZoneId}
        withAsterisk
        label='Transfer Time'
        description='When the material was transferred'
      />

      <Stack>
        <Flex gap='md' mr='md'>
          {unload ? <>{truckLoadSelect}</> : <>{containerSelect}</>}
          <ContainerFullnessSwitch
            variant='source'
            orientation='horizontal'
            size='lg'
            {...form.getInputProps('sourceEmptied', { type: 'checkbox' })}
            inputWrapperProps={{
              label: 'Emptied',
            }}
          />
        </Flex>

        <Button
          leftIcon={<IconSwitchHorizontal />}
          onClick={() => form.setValues(({ unload }) => ({ unload: !unload }))}
          color='blue'
          variant='outline'
        >
          Swap Source/Destination
        </Button>

        <Flex gap='md' mr='md'>
          {unload ? <>{containerSelect}</> : <>{truckLoadSelect}</>}
          {unload ? (
            <ContainerFullnessSwitch
              variant='destination'
              orientation='horizontal'
              size='lg'
              {...form.getInputProps('destinationFilled', {
                type: 'checkbox',
              })}
              inputWrapperProps={{
                label: 'Filled',
              }}
            />
          ) : undefined}
        </Flex>
      </Stack>
    </>
  );
}

export function AddTruckLoadTransferForm() {
  const addMutation = useAddTruckLoadTransfer();
  const form = useFormNavBlocker(useTruckLoadTransferForm());
  const transferId = useMemo(() => uuidv4(), []);

  if (addMutation.isError) {
    return (
      <Alert
        color='red'
        title='Error Creating Truck Load Transfer'
        withCloseButton
        closeButtonLabel='Clear Error'
        onClose={() => addMutation.reset()}
      >
        The transfer may or may not have been saved. You can clear the error and
        try again.
      </Alert>
    );
  }

  return (
    <form
      onSubmit={form.onSubmit((formValues) => {
        if (!formValues.containerId || !formValues.truckLoadId) {
          throw new Error();
        }

        const transfer: MaterialTruckLoadTransferCreationDTO = {
          id: transferId,
          effectivelyTransferred: formValues.timestamp.utc().toISOString(),
          containerId: formValues.containerId,
          truckLoadId: formValues.truckLoadId,
          unload: formValues.unload,
          destinationFilled: formValues.destinationFilled && formValues.unload,
          sourceEmptied: formValues.sourceEmptied,
        };

        addMutation.mutate(transfer, {
          onError() {
            showNotification({
              title: 'Error Creating Truck Load Transfer',
              message: 'An error occurred creating the truck load transfer',
              color: 'red',
              icon: <IconX />,
            });
          },
          onSuccess() {
            showNotification({
              title: 'Truck Load Transfer Created',
              message: 'Truck load transfer was sucessfully recorded',
              color: 'green',
              icon: <IconCheck />,
            });
            Router.replace('LedgerHistory', {
              date: formValues.timestamp.utc().format('YYYY-MM-DD'),
            });
          },
        });
      })}
    >
      <Stack>
        <TruckLoadTransferFormFields form={form} />
        <Group position='right'>
          <FormActionButton
            action='cancel'
            onClick={() => Router.push('LedgerHistory')}
            loading={addMutation.isLoading}
          />
          <FormActionButton
            type='submit'
            action='saveCreation'
            loading={addMutation.isLoading}
          >
            Add Transfer
          </FormActionButton>
        </Group>
      </Stack>
    </form>
  );
}
