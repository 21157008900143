import { Drawer, DrawerProps, Modal } from '@mantine/core';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { UseFormReturnType } from '@mantine/form';
import { ReactNode } from 'react';
import { match } from 'ts-pattern';
import { FormWrapper, FormWrapperProps } from './FormWrapper';

export type OverlayVariant = 'drawer' | 'modal';

export type DrawerFormDrawerProps = Omit<
  DrawerProps,
  | 'children'
  | 'title'
  | 'onClose'
  | 'opened'
  | 'styles'
  | 'maxWidth'
  | 'onSubmit'
  | 'withCloseButton'
> & {
  title?: ReactNode;
  onClose: () => void;
  opened: boolean;
  overlayVariant?: OverlayVariant;
  formResetOnClose?: boolean;
};

export type DrawerFormProps<TDTO, TFormValues> = DrawerFormDrawerProps &
  Omit<FormWrapperProps<TDTO, TFormValues>, 'onCancel'> & {
    onCancel?: () => void;
  };

/**
 * This component needs to be accompanied by something to control the opening of the drawer,
 * e.g., a button or menu item.
 *
 * @remark see {@link FormWrapper} for the form-related abstraction.
 *
 * @param TDTO - the creation or patch DTO type.
 * @param TFormValues - the form values type, specified in the {@link UseFormReturnType}.
 * @param {ReactNode} children - form fields fragmant to be stacked in the drawer content.
 * @returns Controllable overlay component containing full form create or edit functionality.
 */
export function DrawerForm<TDTO, TFormValues>(
  props: DrawerFormProps<TDTO, TFormValues>,
) {
  const {
    form,
    children,
    formVariant,
    entityName,
    // DrawerProps
    title = match(formVariant)
      .with('create', () => `Add ${entityName}`)
      .with('edit', () => `Edit ${entityName}`)
      .exhaustive(),
    onClose,
    onCancel = onClose,
    opened,
    size = 'md',
    position = 'right',
    closeOnClickOutside = false,
    overlayVariant = 'drawer',
    formResetOnClose = false,
    ...formWrapperProps
  } = props;

  // TODO(2443): figure out robust nav blocker for drawer forms

  const overlayChildren = (
    <FormWrapper
      form={form}
      formVariant={formVariant}
      entityName={entityName}
      onCancel={onCancel}
      {...formWrapperProps}
    >
      {children}
    </FormWrapper>
  );

  if (!opened) {
    return null;
  }

  return match(overlayVariant)
    .with('drawer', () => (
      <Drawer
        title={title}
        opened={opened}
        onClose={() => {
          if (formResetOnClose) {
            form.reset();
          }
          onClose();
        }}
        closeOnClickOutside={closeOnClickOutside}
        size={size}
        position={position}
        withCloseButton={false}
        styles={{ inner: { height: '100%' } }}
      >
        {overlayChildren}
      </Drawer>
    ))
    .with('modal', () => (
      <Modal
        title={title}
        opened={opened}
        onClose={() => {
          if (formResetOnClose) {
            form.reset();
          }
          onClose();
        }}
        closeOnClickOutside={closeOnClickOutside}
        size={size}
        styles={{ inner: { height: '100%' } }}
      >
        {overlayChildren}
      </Modal>
    ))
    .exhaustive();
}
