import dayjs from 'dayjs';
import { useFacilityContext } from '../Facility/FacilityContext';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import {
  usePatchProcessBufferTransfer,
  useProcessBufferTransfer,
} from '../api/processBufferTransfer';
import { useSetFormInitialValuesFromQuery } from '../lib/useSetFormInitialValuesFromQuery';
import {
  ProcessBufferTransferId,
  ProcessBufferTransferPatchDTO,
} from '../rest-client';
import {
  ProcessBufferTransferFormFields,
  ProcessBufferTransferFormValues,
  useProcessBufferTransferForm,
} from './ProcessBufferTransferForm';

export type EditProcessBufferTransferDrawerFormProps = {
  processBufferTransferId: ProcessBufferTransferId;
  onSuccess?: (processBufferTransfer: ProcessBufferTransferPatchDTO) => void;
} & DrawerFormDrawerProps;

export function EditProcessBufferTransferDrawerForm(
  props: EditProcessBufferTransferDrawerFormProps,
) {
  const { processBufferTransferId, onSuccess, ...drawerFormDrawerProps } =
    props;

  const facility = useFacilityContext();
  const {
    data: processBufferTransfer,
    isLoadingError,
    error,
  } = useProcessBufferTransfer(processBufferTransferId);
  const patchMutation = usePatchProcessBufferTransfer(processBufferTransferId);
  const form = useProcessBufferTransferForm({});

  useSetFormInitialValuesFromQuery(
    form,
    processBufferTransfer && {
      timestamp: dayjs
        .utc(processBufferTransfer.effectiveTimestamp)
        .tz(facility.timeZoneId),
      sourceContainerId: processBufferTransfer.containerId,
      sourceEmptied: processBufferTransfer.sourceEmptied,
      processId: processBufferTransfer.processId,
    },
  );

  if (isLoadingError) {
    throw error;
  }

  const formValuesToDto = ({
    timestamp,
    sourceContainerId,
    sourceEmptied,
    processId,
  }: ProcessBufferTransferFormValues) => {
    if (sourceContainerId === null) {
      throw new Error('Source container cannot be null ');
    }
    if (processId === null) {
      throw new Error('Process cannot be null ');
    }

    const processBufferTransferPatch: ProcessBufferTransferPatchDTO = {
      ...(form.isDirty('timestamp') && {
        effectivelyTransferred: timestamp.utc().toISOString(),
      }),
      ...(form.isDirty('sourceContainerId') && {
        materialContainerId: sourceContainerId,
      }),
      ...(form.isDirty('sourceEmptied') && { sourceEmptied }),
    };

    return processBufferTransferPatch;
  };

  return (
    <DrawerForm
      entityName='Feedstock Transfer'
      form={form}
      formVariant='edit'
      mutation={patchMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      {...drawerFormDrawerProps}
    >
      <ProcessBufferTransferFormFields
        form={form}
        containerSelectVariant='name'
      />
    </DrawerForm>
  );
}
