import { useState } from 'react';
import { match } from 'ts-pattern';
import { LinkText } from '../../../Link';
import {
  DateRange,
  plainDateToStartOfDayInstant,
} from '../../../Temporal/dateRangeAndOffset';
import Temporal from '../../../Temporal/temporal';
import { useGetCommodityPurities } from '../../../api/commodity';
import { ProducedCommodityPuritiesDTO } from '../../../rest-client/models/ProducedCommodityPuritiesDTO';
import { Router } from '../../../router';
import { formatPercentage } from '../../../util/percentages';
import { useFacilityContext } from '../../FacilityContext';
import { useInsightsSelectedDateRange } from '../DateRange/InsightsDateRange';
import {
  MetricDetailPageTemplate,
  MetricDetailTableColumn,
} from './MetricDetailPageTemplate';

export function getSeriesData(queryData: ProducedCommodityPuritiesDTO[]) {
  return queryData.map((producedCommodityPurity) => {
    const { commodity, feedFlowGroupPurityPoints } = producedCommodityPurity;
    const commodityName = commodity.name;
    return {
      name: commodityName,
      entries: feedFlowGroupPurityPoints.map((ffgPurityPoint) => ({
        timestamp: ffgPurityPoint.feedFlowGroupStart,
        name: commodityName,
        value: +(ffgPurityPoint.purity * 100).toFixed(1),
      })),
    };
  });
}

export function CommodityPurityMetricPage() {
  const { timeZoneId: tz, id: facilityId } = useFacilityContext();
  const [nowZonedDateTime] = useState(Temporal.Now.zonedDateTimeISO(tz));
  const thirtyDaysAgo = nowZonedDateTime.subtract({ days: 30 });
  const { dateRange, setDateRange } = useInsightsSelectedDateRange({
    startDate: thirtyDaysAgo.toPlainDate(),
    endDate: nowZonedDateTime.toPlainDate(),
    name: 'Last 30 Days',
    id: 'd30',
  });

  const { startDate, endDate } = dateRange;
  const rangeInstants = {
    startInstant: plainDateToStartOfDayInstant(startDate, tz),
    // TODO end of day, probably
    endInstant: plainDateToStartOfDayInstant(endDate, tz),
  };
  const { startInstant, endInstant } = rangeInstants;
  const query = useGetCommodityPurities({
    after: startInstant,
    before: endInstant,
    facilityId,
  });

  const { seriesData, tableData } = match(query)
    .with({ status: 'loading' }, () => ({
      loading: true,
      error: null,
      seriesData: [],
      tableData: [],
    }))
    .with({ status: 'error' }, (query) => ({
      loading: false,
      error: query.error as Error,
      seriesData: [],
      tableData: [],
    }))
    .with({ status: 'success' }, (query) => ({
      loading: false,
      error: null,
      seriesData: getSeriesData(query.data),
      tableData: query.data,
    }))
    .exhaustive();

  interface RenderColumnArgs {
    purities: ProducedCommodityPuritiesDTO;
    dateRange?: DateRange;
  }

  type CommodityPurityThroughputColumn = MetricDetailTableColumn & {
    renderTableValue: ({
      purities,
      dateRange,
    }: Required<RenderColumnArgs>) => JSX.Element;
  };

  const columns: CommodityPurityThroughputColumn[] = [
    {
      title: 'Commodity',
      numeric: false,
      renderTableValue: ({ purities }: Required<RenderColumnArgs>) => (
        <td key='commodity'>
          <LinkText
            to={Router.FacilityInsightsMetricFacetDetail({
              metricName: 'material-purity',
              facetName: purities.commodity.id,
            })}
          >
            {purities.commodity.name}
          </LinkText>
        </td>
      ),
    },
    {
      title: 'Average Purity',
      numeric: true,
      renderTableValue: ({ purities }: RenderColumnArgs) => (
        <td style={{ textAlign: 'right' }} key='avg'>
          {formatPercentage(purities.avgPurity)}
        </td>
      ),
    },
    {
      title: 'Minimum Purity',
      numeric: true,
      renderTableValue: ({ purities }: RenderColumnArgs) => (
        <td style={{ textAlign: 'right' }} key='min'>
          {formatPercentage(purities.minPurity)}
        </td>
      ),
    },
    {
      title: 'Maximum Purity',
      numeric: true,
      renderTableValue: ({ purities }: RenderColumnArgs) => (
        <td style={{ textAlign: 'right' }} key='max'>
          {formatPercentage(purities.maxPurity)}
        </td>
      ),
    },
  ];

  const renderTableRow = (purities: ProducedCommodityPuritiesDTO) => {
    return (
      <tr key={purities.commodity.id}>
        {columns.map((col) => {
          return col.renderTableValue({ purities, dateRange });
        })}
      </tr>
    );
  };

  return (
    <MetricDetailPageTemplate<ProducedCommodityPuritiesDTO>
      dateRange={dateRange}
      setDateRange={setDateRange}
      title='Material Purity'
      graphTitle='Commodity Purity'
      tableTitle='Material Purity by Commodity'
      tableColumns={columns}
      tableData={tableData}
      seriesData={seriesData}
      renderTableRow={renderTableRow}
      query={query}
      yAxisLabel='Purity %'
    />
  );
}
