import { match } from 'ts-pattern';
import { The404 } from '../The404';
import { Router } from '../router';
import { AddMaterialClassSetPage } from './AddMaterialClassSetPage';
import { MaterialClassSetDetailPage } from './MaterialClassSetDetailPage';

export function MaterialClassSetArea() {
  const route = Router.useRoute([
    'MaterialClassSetDetail',
    'MaterialClassSetCreate',
  ]);

  if (route === undefined) {
    return <The404 />;
  }

  return match(route)
    .with(
      { name: 'MaterialClassSetDetail' },
      ({ params: { materialClassSetId } }) => (
        <MaterialClassSetDetailPage materialClassSetId={materialClassSetId} />
      ),
    )
    .with({ name: 'MaterialClassSetCreate' }, () => <AddMaterialClassSetPage />)
    .exhaustive();
}
