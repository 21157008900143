import {
  Group,
  Input,
  MultiSelect,
  Stack,
  Switch,
  TextInput,
} from '@mantine/core';
import { IconHistory, IconSearch } from '@tabler/icons-react';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { P, match } from 'ts-pattern';
import { AppPage } from '../App/AppPage';
import { useFacilityContext } from '../Facility/FacilityContext';
import { DayjsDateTimePicker } from '../Time/DayjsDateTimePicker';
import { useCommodities } from '../api/commodity';
import { useContainerTypes } from '../api/containerType';
import { MaterialContainerDTO, MaterialSetDTO } from '../rest-client';
import AddContainerButton from './AddContainerButton';
import { ContainerTable } from './ContainerTable';
import cssClasses from './ContainersPage.module.css';

export function ContainerInventoryPage() {
  const facility = useFacilityContext();
  const commoditiesQuery = useCommodities();
  const containerTypesQuery = useContainerTypes();

  const [showEmpty, setShowEmpty] = useState(false);
  const [selectedContainerTypes, setSelectedContainerTypes] = useState<
    Set<string>
  >(new Set());
  const [selectedCommodities, setSelectedCommodities] = useState<Set<string>>(
    new Set(),
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [timestamp, setTimestamp] = useState<Dayjs | null>(null);

  const kindOptions = containerTypesQuery.data?.map((t) => ({
    value: t.id,
    label: t.name,
  }));
  const commodityOptions =
    commoditiesQuery.data?.map(({ id, name }) => ({
      value: id,
      label: name,
    })) ?? [];

  const containerFilter = (container: MaterialContainerDTO) =>
    selectedContainerTypes.size > 0
      ? selectedContainerTypes.has(container.containerType.id)
      : true;

  const materialSetFilter =
    selectedCommodities.size > 0
      ? (materialSet: MaterialSetDTO | null) =>
          match(materialSet?.defaultCommodity)
            .with(undefined, () => false)
            .with({ kind: 'assigned' }, ({ commodityId }) =>
              selectedCommodities.has(commodityId),
            )
            .with({ kind: 'multiple' }, ({ commodityIds }) =>
              commodityIds.some((cId) => selectedCommodities.has(cId)),
            )
            .with(
              {
                kind: P.union(
                  'intermediate',
                  'no-paths',
                  'no-source',
                  'unassigned-commodity-path',
                ),
              },
              () => false,
            )
            .exhaustive()
      : undefined;

  return (
    <AppPage
      title='Containers'
      titleRight={<AddContainerButton addContainerDrawerFormProps={{}} />}
    >
      <AppPage.Section>
        <Stack>
          <Group className={cssClasses.addContainerControls}>
            <Input.Wrapper label='Container Name'>
              <TextInput
                icon={<IconSearch size={14} />}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.currentTarget.value)}
                w={200}
              />
            </Input.Wrapper>
            <MultiSelect
              label='Container Types'
              placeholder='Filter by type of container'
              className={cssClasses.multiselect}
              data={kindOptions ?? []}
              value={[...selectedContainerTypes]}
              onChange={(t) => setSelectedContainerTypes(new Set(t))}
              w={220}
            />
            <MultiSelect
              label='Commodities'
              placeholder='Filter by commodity'
              searchable
              disabled={commoditiesQuery.isLoading}
              className={cssClasses.multiselect}
              data={commodityOptions}
              value={[...selectedCommodities]}
              onChange={(g) => setSelectedCommodities(new Set(g))}
              w={240}
            />
            <Input.Wrapper label='Show Empty'>
              <Switch
                checked={showEmpty}
                onChange={(e) => setShowEmpty(e.currentTarget.checked)}
                size='lg'
              />
            </Input.Wrapper>
            <DayjsDateTimePicker
              label='State at Time'
              value={timestamp}
              onChange={setTimestamp}
              tz={facility.timeZoneId}
              icon={<IconHistory size={16} />}
              maxDate={dayjs()}
              w={200}
            />
          </Group>
        </Stack>
        <ContainerTable
          timestamp={timestamp ?? undefined}
          searchQuery={searchQuery}
          containerFilter={containerFilter}
          materialSetFilter={materialSetFilter}
          showEmpty={showEmpty}
        />
      </AppPage.Section>
    </AppPage>
  );
}
