import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import { useMaterialClass, usePatchMaterialClass } from '../api/materialClass';
import { useSetFormInitialValuesFromQuery } from '../lib/useSetFormInitialValuesFromQuery';
import { MaterialClassId, MaterialClassPatchDTO } from '../rest-client';
import {
  MaterialClassFormFields,
  MaterialClassFormValues,
  useMaterialClassForm,
} from './MaterialClassForm';

export type EditMaterialClassDrawerFormProps = {
  materialClassId: MaterialClassId;
  onSuccess?: (materialClass: MaterialClassPatchDTO) => void;
} & DrawerFormDrawerProps;

export function EditMaterialClassDrawerForm(
  props: EditMaterialClassDrawerFormProps,
) {
  const { materialClassId, onSuccess, ...drawerFormDrawerProps } = props;

  const {
    data: materialClass,
    isLoadingError,
    error,
  } = useMaterialClass(materialClassId);
  const patchMutation = usePatchMaterialClass(materialClassId);
  const form = useMaterialClassForm();

  useSetFormInitialValuesFromQuery(
    form,
    materialClass && {
      name: materialClass.name,
      description: materialClass.description ?? '',
    },
  );

  if (isLoadingError) {
    throw error;
  }

  const formValuesToDto = ({ name, description }: MaterialClassFormValues) => {
    const materialClassPatch: MaterialClassPatchDTO = {
      ...(form.isDirty('name') && { name }),
      ...(form.isDirty('description') && {
        description: description === '' ? null : description,
      }),
    };
    return materialClassPatch;
  };

  return (
    <DrawerForm
      entityName='Material Class'
      form={form}
      formVariant='edit'
      mutation={patchMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      {...drawerFormDrawerProps}
    >
      <MaterialClassFormFields form={form} />
    </DrawerForm>
  );
}
