import { Text } from '@mantine/core';
import { WeightUnit } from '../rest-client';
import { WithUnit } from './WithUnit';
import { weightUnitShorthand } from './weightUnitShorthand';

export type MoneyProps = {
  usd: number;
};

export function Money(props: MoneyProps) {
  const { usd: value } = props;
  return <Text>{formatCurrency(value)}</Text>;
}

export function formatCurrency(usd: number, currency: string = 'USD') {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(usd);
}

export type PriceProps = {
  usdPerWeightUnit: number;
  weightUnit: WeightUnit;
};

export function Price(props: PriceProps) {
  const { usdPerWeightUnit, weightUnit } = props;
  const unit = `/${weightUnitShorthand(weightUnit)}`;

  return (
    <WithUnit unitPrefix='$' unitSuffix={unit}>
      <Text>{(Math.round(usdPerWeightUnit * 100) / 100).toFixed(2)}</Text>
    </WithUnit>
  );
}
