import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import { useCreateInternalMaterialSink } from '../api/internalMaterialSink';
import { InternalMaterialSinkCreationDTO } from '../rest-client';
import { Router } from '../router';
import {
  InternalMaterialSinkFormFields,
  InternalMaterialSinkFormValues,
  useInternalMaterialSinkForm,
} from './InternalMaterialSinkForm';

export type AddInternalMaterialSinkDrawerFormProps = {
  onSuccess?: (internalMaterialSink: InternalMaterialSinkCreationDTO) => void;
} & DrawerFormDrawerProps;

export function AddInternalMaterialSinkDrawerForm(
  props: AddInternalMaterialSinkDrawerFormProps,
) {
  const {
    onSuccess = (internalMaterialSink: InternalMaterialSinkCreationDTO) => {
      Router.replace('InternalSinkDetail', {
        internalMaterialSinkId: internalMaterialSink.id,
      });
    },
    ...drawerFormDrawerProps
  } = props;

  const createMutation = useCreateInternalMaterialSink();
  const form = useInternalMaterialSinkForm();
  const [id, setId] = useState(uuidv4);

  const formValuesToDto = ({
    name,
    facilityId,
  }: InternalMaterialSinkFormValues) => {
    if (facilityId === null) {
      throw new Error('Facility cannot be null');
    }
    const internalMaterialSink: InternalMaterialSinkCreationDTO = {
      id,
      name,
      facilityId,
    };
    setId(uuidv4());
    return internalMaterialSink;
  };

  return (
    <DrawerForm
      entityName='Material Export Destination'
      form={form}
      formVariant='create'
      mutation={createMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      {...drawerFormDrawerProps}
    >
      <InternalMaterialSinkFormFields form={form} />
    </DrawerForm>
  );
}
