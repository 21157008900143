import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import { useCreateProcessBufferDepletion } from '../api/processBufferDepletion';
import { ProcessBufferDepletionCreationDTO } from '../rest-client';
import {
  ProcessBufferDepletionFormFields,
  ProcessBufferDepletionFormProps,
  ProcessBufferDepletionFormValues,
  useProcessBufferDepletionForm,
} from './ProcessBufferDepletionForm';

export type AddProcessBufferDepletionDrawerFormProps =
  ProcessBufferDepletionFormProps & {
    onSuccess?: (
      processBufferDepletion: ProcessBufferDepletionCreationDTO,
    ) => void;
  } & DrawerFormDrawerProps;

export function AddProcessBufferDepletionDrawerForm(
  props: AddProcessBufferDepletionDrawerFormProps,
) {
  const { processId, onSuccess, ...drawerFormDrawerProps } = props;

  const createMutation = useCreateProcessBufferDepletion();
  const form = useProcessBufferDepletionForm({
    processId,
  });
  const [id, setId] = useState(uuidv4);

  const formValuesToDto = ({
    depletedAt,
    processId,
  }: ProcessBufferDepletionFormValues) => {
    if (processId === null) {
      throw new Error('Process cannot be null');
    }

    const processBufferDepletion: ProcessBufferDepletionCreationDTO = {
      id,
      effectiveTimestamp: depletedAt.utc().toISOString(),
      processId,
    };
    setId(uuidv4());
    return processBufferDepletion;
  };

  return (
    <DrawerForm
      entityName='Feedstock Depletion'
      form={form}
      formVariant='create'
      mutation={createMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      {...drawerFormDrawerProps}
    >
      <ProcessBufferDepletionFormFields form={form} />
    </DrawerForm>
  );
}
