import {
  ActionIcon,
  Alert,
  Box,
  Button,
  Center,
  Drawer,
  Group,
  Loader,
  Menu,
  Modal,
  Paper,
  SegmentedControl,
  Stack,
  Tabs,
  Text,
} from '@mantine/core';
import { ScrollAreaComponent } from '@mantine/core/lib/Modal/Modal.context';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconChevronLeft,
  IconChevronRight,
  IconDotsVertical,
  IconX,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { AppPage } from '../App/AppPage';
import { useFacilityContext } from '../Facility/FacilityContext';
import { DeleteIcon, ProductionIntervalIcon } from '../Icons';
import Temporal from '../Temporal/temporal.ts';
import { DayjsDateInput } from '../Time/DayjsDateTimePicker';
import { useProcesses } from '../api/process';
import {
  useDeleteProductionInterval,
  useProductionIntervalStatus,
  useProductionIntervals,
} from '../api/production';
import { ProcessId, ProcessPlannedProductionIntervalDTO } from '../rest-client';
import { usePersistentRouteParams } from '../util/useRouteParams.ts';
import { AddProductionIntervalForm } from './AddProductionIntervalForm';
import { ProductionIntervalContextProvider } from './ProductionIntervalContext';
import { ProductionIntervalStatus } from './ProductionIntervalStatus';

export function DailyProductionPage() {
  const facility = useFacilityContext();
  const [{ date, processId: selectedProcessId }, updateRouteParams] =
    usePersistentRouteParams(['ProductionDaily']);

  const [
    addProductionIntervalOpened,
    {
      open: openProductionIntervalDrawer,
      close: closeProductionIntervalDrawer,
    },
  ] = useDisclosure(false);

  const tz = facility.timeZoneId;
  const todayPlainDate = Temporal.Now.instant()
    .toZonedDateTimeISO(tz)
    .toPlainDate();
  const selectedDate =
    date === undefined ? todayPlainDate : Temporal.PlainDate.from(date);

  const processesQuery = useProcesses(facility.id);
  const processes = processesQuery.data;

  useEffect(() => {
    if (!selectedProcessId && processes && processes.length > 0) {
      updateRouteParams({
        processId: processes[0].id,
      });
    }
  }, [processes, updateRouteParams, selectedProcessId]);

  if (processesQuery.isLoadingError) {
    throw processesQuery.error;
  }

  return (
    <>
      <Drawer
        size='md'
        opened={addProductionIntervalOpened}
        onClose={closeProductionIntervalDrawer}
        title='Add Production Shift'
        position='right'
        styles={{ inner: { height: '100%' } }}
        scrollAreaComponent={Box as ScrollAreaComponent}
      >
        <AddProductionIntervalForm
          date={selectedDate}
          onCancel={() => closeProductionIntervalDrawer()}
          onSuccess={() => closeProductionIntervalDrawer()}
        />
      </Drawer>
      <AppPage title='Daily Production'>
        <AppPage.Section>
          <Group spacing='xs'>
            {processes !== undefined && selectedProcessId !== undefined ? (
              <SegmentedControl
                size='md'
                value={selectedProcessId}
                onChange={(selectedProcessId) =>
                  updateRouteParams({
                    processId: selectedProcessId,
                    productionIntervalId: undefined,
                  })
                }
                data={
                  processes?.map((process) => ({
                    label: process.name,
                    value: process.id,
                  })) ?? []
                }
              />
            ) : (
              <Loader />
            )}

            <ActionIcon
              variant='default'
              onClick={() =>
                updateRouteParams({
                  date: selectedDate.subtract({ days: 1 }).toString(),
                  productionIntervalId: undefined,
                })
              }
            >
              <IconChevronLeft size={16} />
            </ActionIcon>
            <DayjsDateInput
              value={dayjs.tz(selectedDate.toString(), tz)}
              onChange={(d) =>
                updateRouteParams({
                  date: d?.utc().format('YYYY-MM-DD'),
                  productionIntervalId: undefined,
                })
              }
              valueFormat='L'
              tz={facility.timeZoneId}
            />
            <ActionIcon
              variant='default'
              onClick={() =>
                updateRouteParams({
                  date: selectedDate.add({ days: 1 }).toString(),
                  productionIntervalId: undefined,
                })
              }
            >
              <IconChevronRight size={16} />
            </ActionIcon>
            <Button
              onClick={() =>
                updateRouteParams({
                  date: todayPlainDate.toString(),
                  productionIntervalId: undefined,
                })
              }
              variant='default'
            >
              Today
            </Button>
            <Button
              leftIcon={<ProductionIntervalIcon />}
              onClick={openProductionIntervalDrawer}
            >
              Add Production Shift
            </Button>
          </Group>
        </AppPage.Section>

        {selectedProcessId && (
          <DailyProcessProductionView
            processId={selectedProcessId}
            date={selectedDate}
          />
        )}
      </AppPage>
    </>
  );
}

function DailyProcessProductionView(props: {
  processId: ProcessId;
  date: Temporal.PlainDate;
}) {
  const { processId, date } = props;

  const facility = useFacilityContext();
  const tz = facility.timeZoneId;
  const {
    isLoading,
    isLoadingError,
    error,
    data: productionIntervals,
  } = useProductionIntervals({
    facility: facility,
    processId,
    date: dayjs.tz(date.toString(), tz),
  });

  const [
    { productionIntervalId, processId: selectedProcessId },
    updateRouteParams,
  ] = usePersistentRouteParams(['ProductionDaily']);

  useEffect(() => {
    if (
      productionIntervalId === undefined &&
      processId === selectedProcessId &&
      productionIntervals &&
      productionIntervals.length > 0
    ) {
      updateRouteParams({
        productionIntervalId: productionIntervals[0].id,
      });
    }
  }, [
    productionIntervalId,
    processId,
    productionIntervals,
    updateRouteParams,
    selectedProcessId,
  ]);

  if (isLoadingError) {
    throw error;
  }
  if (isLoading) {
    return <Loader />;
  }

  if (productionIntervals.length === 0) {
    return (
      <Paper withBorder shadow='sm'>
        <Alert title='No Production Shifts' color='yellow'>
          No production shifts exist for this process on this day. Use the
          &apos;Add Production Shift&apos; button to add a production shift.
        </Alert>
      </Paper>
    );
  }

  return (
    <Tabs
      value={productionIntervalId}
      onTabChange={(t) =>
        updateRouteParams({
          productionIntervalId: t ?? undefined,
        })
      }
      // orientation='vertical'
    >
      <Tabs.List>
        {productionIntervals.map((productionInterval) => (
          <Tabs.Tab key={productionInterval.id} value={productionInterval.id}>
            <Group spacing={0} noWrap>
              <Text>
                {dayjs
                  .utc(productionInterval.startTime)
                  .tz(facility.timeZoneId)
                  .format('HH:mm') +
                  ' - ' +
                  dayjs
                    .utc(productionInterval.endTime)
                    .tz(facility.timeZoneId)
                    .format('HH:mm')}
              </Text>
              <ProductionIntervalTabMenu
                productionInterval={productionInterval}
              />
            </Group>
          </Tabs.Tab>
        ))}
      </Tabs.List>
      {productionIntervals.map((productionInterval) => (
        <ProductionIntervalTab
          key={productionInterval.id}
          productionIntervalId={productionInterval.id}
        />
      ))}
    </Tabs>
  );
}

function ProductionIntervalTab(props: { productionIntervalId: string }) {
  const { productionIntervalId } = props;

  const {
    isLoadingError,
    isLoading,
    error,
    data: productionIntervalStatus,
  } = useProductionIntervalStatus(productionIntervalId);

  if (isLoadingError) {
    throw error;
  }
  if (isLoading) {
    return (
      <Center>
        <Loader variant='bars' size='lg' />
      </Center>
    );
  }

  return (
    <ProductionIntervalContextProvider
      productionInterval={productionIntervalStatus.productionInterval}
      processOutputPorts={productionIntervalStatus.processOutputPorts}
    >
      <Tabs.Panel value={productionIntervalId} pt='sm'>
        <ProductionIntervalStatus productionIntervalId={productionIntervalId} />
      </Tabs.Panel>
    </ProductionIntervalContextProvider>
  );
}

interface ProductionIntervalTabMenuProps {
  productionInterval: ProcessPlannedProductionIntervalDTO;
}

function ProductionIntervalTabMenu(props: ProductionIntervalTabMenuProps) {
  const { productionInterval } = props;
  const facility = useFacilityContext();

  const [menuOpened, setMenuOpened] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const deleteMutation = useDeleteProductionInterval(facility.id);

  if (deleteMutation.isError) {
    return (
      <Alert
        color='red'
        title='Error Deleting Production Shift'
        withCloseButton
        closeButtonLabel='Clear Error'
        onClose={() => deleteMutation.reset()}
      >
        The production shift may or may not have been deleted. You can clear the
        error and try again.
      </Alert>
    );
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title='Confirm Production Shift Deletion'
        w={600}
      >
        <Stack>
          <Alert color='red' variant='filled'>
            <Text>
              Deleting this production shift is irreversible and will delete all
              user generated process stops contained in that production shift
              time interval. Any previously created process runs will not be
              deleted. Are you sure you want to delete the production shift?
            </Text>
          </Alert>

          <Group position='apart'>
            <Button
              variant='light'
              color='red'
              loading={deleteMutation.isLoading}
              onClick={() => {
                deleteMutation.mutate(productionInterval.id, {
                  onError() {
                    showNotification({
                      title: 'Error Deleting Production Shift',
                      message:
                        'An error occurred deleting the production shift.',
                      color: 'red',
                      icon: <IconX />,
                    });
                  },
                  onSuccess() {
                    showNotification({
                      title: 'Production Shift Deleted',
                      message:
                        'The production shift and all its process stops were sucessfully deleted.',
                      color: 'green',
                      icon: <IconCheck />,
                    });
                  },
                  onSettled() {
                    close();
                  },
                });
              }}
            >
              Delete Forever
            </Button>
            <Button color='teal' onClick={close}>
              Back to Safety
            </Button>
          </Group>
        </Stack>
      </Modal>

      <Menu opened={menuOpened} onChange={setMenuOpened}>
        <Menu.Target>
          <IconDotsVertical />
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            color='red'
            icon={<DeleteIcon size={14} />}
            onClick={() => {
              open();
            }}
          >
            Delete
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
}
