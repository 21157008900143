import { ReactNode, createContext, useContext } from 'react';
import {
  InternallySourcedMaterialId,
  MaterialClassSetCompositionId,
} from '../rest-client';

export enum InputCompositionInternalMaterialSourceVariant {
  unweighted = 'unweighted',
  weighted = 'weighted',
}

export type InputCompositionInternallySourcedMaterialVariant = {
  internallySourcedMaterialId: InternallySourcedMaterialId;
};

export type InputCompositionMaterialClassSetVariant = {
  materialClassSetCompositionId: MaterialClassSetCompositionId;
};

export type InputCompositionSource =
  | InputCompositionInternalMaterialSourceVariant
  | InputCompositionMaterialClassSetVariant
  | InputCompositionInternallySourcedMaterialVariant
  | null;

export interface RecoveryStrategySimulationCompositionContext {
  selectedMaterialClassSetComposition: Record<string, number>;
  setSelectedMaterialClassSetComposition: (
    composition: Record<string, number>,
  ) => void;
  selectedInputCompositionSource: InputCompositionSource;
  setSelectedInputCompositionSource: (
    inputCompositionSource: InputCompositionSource,
  ) => void;
}

const RecoveryStrategySimulationCompositionContext = createContext<
  RecoveryStrategySimulationCompositionContext | undefined
>(undefined);

export function useRecoveryStrategySimulationCompositionCtx(): RecoveryStrategySimulationCompositionContext {
  const ctx = useContext(RecoveryStrategySimulationCompositionContext);
  if (ctx === undefined) {
    throw new Error(
      'component must be within a recovery strategy simulation composition context',
    );
  }

  return ctx;
}

export function RecoveryStrategySimulationCompositionCtxProvider(
  props: {
    children: ReactNode;
  } & RecoveryStrategySimulationCompositionContext,
) {
  const { children, ...value } = props;
  return (
    <RecoveryStrategySimulationCompositionContext.Provider value={value}>
      {children}
    </RecoveryStrategySimulationCompositionContext.Provider>
  );
}
