import { Group, Loader, ScrollArea, Stack, Title } from '@mantine/core';
import { useSampleAnalysesByMaterialClassSet } from '../api/sampleAnalysis';
import { AppPage } from '../App/AppPage';
import { SamplingIcon } from '../Icons';
import { LinkButton } from '../Link';
import { MaterialClassSetName } from '../MaterialClassSet/MaterialClassSetName';
import { Router } from '../router';
import { MaterialClassSetSampleAnalysesTable } from './MaterialClassSetSampleAnalysesTable';
import { UnanalyzedSamplesTable } from './UnanalyzedSamplesTable';

export function SamplingPage() {
  return (
    <AppPage
      title='Samples'
      titleRight={
        <Group>
          <LinkButton
            leftIcon={<SamplingIcon />}
            to={Router.SampleCreateContainer()}
          >
            Record New Container Sample
          </LinkButton>
          <LinkButton
            leftIcon={<SamplingIcon />}
            to={Router.SampleCreateNotional()}
          >
            Record New Notional Sample
          </LinkButton>
        </Group>
      }
    >
      <MaterialClassSetAnalysesSections />
      <AppPage.Section>
        <UnanalyzedSamplesSection />
      </AppPage.Section>
    </AppPage>
  );
}

function MaterialClassSetAnalysesSections() {
  const { data, isError, error } = useSampleAnalysesByMaterialClassSet();

  if (data) {
    const { materialClassSetSampleAnalyses } = data;
    return Object.values(materialClassSetSampleAnalyses).map(
      (materialClassSetSampleAnalysis) =>
        materialClassSetSampleAnalysis.analyses.length > 0 ? (
          <AppPage.Section
            key={materialClassSetSampleAnalysis.materialClassSet.id}
          >
            <Stack>
              <Title order={2}>
                <MaterialClassSetName
                  materialClassSet={
                    materialClassSetSampleAnalysis.materialClassSet
                  }
                />{' '}
                Sample Analyses
              </Title>
              <ScrollArea w='100%'>
                <MaterialClassSetSampleAnalysesTable
                  materialClassSetSampleAnalyses={
                    materialClassSetSampleAnalysis
                  }
                />
              </ScrollArea>
            </Stack>
          </AppPage.Section>
        ) : undefined,
    );
  }

  if (isError) {
    throw error;
  }
}

function UnanalyzedSamplesSection() {
  const { data, isError, error } = useSampleAnalysesByMaterialClassSet();

  if (data) {
    return (
      <Stack>
        <Title order={2}>Unanalyzed Samples</Title>
        <UnanalyzedSamplesTable unanalyzedSamples={data.unanalyzedSamples} />
      </Stack>
    );
  }

  if (isError) throw error;

  return <Loader />;
}
