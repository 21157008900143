import { Loader } from '@mantine/core';
import { DateInput, DateInputProps, DateTimePicker } from '@mantine/dates';
import dayjs, { Dayjs } from 'dayjs';
import { ReactNode, useCallback, useMemo } from 'react';

// TODO(1795): handle these comments when addressing the Temporal wrapper for date and time components
export type DayjsDateInputProps = Omit<
  DateInputProps,
  | 'value'
  | 'date' // left out intentionally
  | 'onChange'
  | 'onDateChange' // left out intentionally
  | 'defaultValue' // left out intentionally
  | 'defaultDate' // left out intentionally
  | 'decadeLabelFormat' // Implement conversion
  | 'excludeDate' // Implement conversion
  | 'getDayAriaLabel' // Implement conversion
  | 'getDayProps' //
  | 'getMonthControlProps' // Implement conversion
  | 'getYearControlProps' // Implement conversion
  | 'maxDate' // Implement conversion
  | 'minDate' // Implement conversion
  | 'monthLabelFormat' // Implement conversion
  | 'onNextDecade' // Implement conversion
  | 'onNextMonth' // Implement conversion
  | 'onNextYear' // Implement conversion
  | 'onPreviousDecade' // Implement conversion
  | 'onPreviousMonth' // Implement conversion
  | 'onPreviousYear' // Implement conversion
  | 'renderDay' // Implement conversion
  | 'yearLabelFormat' // Implement conversion
> & {
  value: Dayjs | null;
  onChange: (date: Dayjs | null) => void;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  tz: string; // which time zone to display in
};

export function DayjsDateInput(props: DayjsDateInputProps) {
  const { value, onChange, minDate, maxDate, tz, ...otherProps } = props;

  const browserTz = dayjs.tz.guess();

  const date = useMemo(
    () => value?.tz(tz).tz(browserTz, true).toDate(),
    [browserTz, tz, value],
  );
  const onChangeCallback = useCallback(
    (d: Date | null) => {
      if (d === null) {
        onChange(null);
      } else {
        onChange(dayjs(d).tz(tz, true));
      }
    },
    [onChange, tz],
  );

  const minJsDate = useMemo(() => minDate?.toDate(), [minDate]);
  const maxJsDate = useMemo(() => maxDate?.toDate(), [maxDate]);

  return (
    <DateInput
      {...otherProps}
      value={date}
      minDate={minJsDate}
      maxDate={maxJsDate}
      onChange={onChangeCallback}
    />
  );
}

// TODO(1795): handle these comments when addressing the Temporal wrapper for date and time components
export type DayjsDateTimePickerProps = Omit<
  Parameters<typeof DateTimePicker>[0],
  | 'value'
  | 'defaultValue'
  | 'onChange'
  | 'maxDate'
  | 'minDate'
  | 'date'
  | 'excludeDate'
  | 'monthLabelFormat' // Implement conversion
  | 'onDateChange' // Implement conversion
  | 'onMonthSelect' // Implement conversion
  | 'onNextDecade' // Implement conversion
  | 'onNextMonth' // Implement conversion
  | 'onNextYear' // Implement conversion
  | 'onPreviousDecade' // Implement conversion
  | 'onPreviousMonth' // Implement conversion
  | 'onPreviousYear' // Implement conversion
  | 'onYearSelect' // Implement conversion
  | 'renderDay'
  | 'yearLabelFormat' // Implement conversion
  | 'getDayAriaLabel' // Implement conversion
  | 'getDayProps' // Implement conversion
  | 'getMonthControlProps' // Implement conversion
  | 'getYearControlProps' // Implement conversion
  | 'decadeLabelFormat' // Implement conversion
  | 'onPointerEnterCapture'
  | 'onPointerLeaveCapture'
  | 'placeholder'
> & {
  tz: string; // which time zone to display in
  loading?: boolean;
  value: Dayjs | null;
  onChange: (value: Dayjs | null) => void;
  maxDate?: Dayjs;
  minDate?: Dayjs;
  excludeDate?: (datetime: Dayjs) => boolean;
  placeholder?: ReactNode;
};

export function DayjsDateTimePicker(props: DayjsDateTimePickerProps) {
  const {
    value,
    onChange,
    tz,
    loading = false,
    minDate,
    maxDate,
    excludeDate,
    valueFormat = 'MMM D, YYYY h:mm A',
    placeholder,
    ...otherProps
  } = props;

  // Unfortunately, @mantine/dates (as of 6.0.2) uses javascript Date objects, but doesn't support time zones
  // see https://github.com/mantinedev/mantine/issues/3432
  // Thus hackery is required!
  // In order to make things work well, we need to create 'fake' Date objects that render as if they were in the target timezone
  // This translation determined by the difference in the user's timezone and the target timezone
  // However, because of bullshit like daylight savings time, this difference is not a fixed value - it is time dependent!

  // We assume that this always works
  const browserTz = dayjs.tz.guess();

  const toDateInTz = useCallback(
    (d: Dayjs) => d.tz(browserTz, true).toDate(),
    [browserTz],
  );

  const toOptionalDateInTz = useCallback(
    (d: Dayjs | undefined) => {
      if (d === undefined) return undefined;
      return toDateInTz(d);
    },
    [toDateInTz],
  );

  const toNullableDateInTz = useCallback(
    (d: Dayjs | null) => {
      if (d === null) return null;
      return toDateInTz(d);
    },
    [toDateInTz],
  );

  const fromDateInTz = useCallback((d: Date) => dayjs(d).tz(tz, true), [tz]);

  const fromNullableDateInTz = useCallback(
    (d: Date | null) => {
      if (d === null) return null;
      return fromDateInTz(d);
    },
    [fromDateInTz],
  );

  const datetime = useMemo(
    () => toNullableDateInTz(value),
    [value, toNullableDateInTz],
  );

  const onChangeCallback = useCallback(
    (d: Date | null) => onChange(fromNullableDateInTz(d)),
    [onChange, fromNullableDateInTz],
  );

  const excludeDateCallback = useMemo(() => {
    if (excludeDate === undefined) return undefined;
    return (d: Date) => excludeDate(fromDateInTz(d));
  }, [excludeDate, fromDateInTz]);

  return (
    <DateTimePicker
      maxDate={toOptionalDateInTz(maxDate)}
      minDate={toOptionalDateInTz(minDate)}
      hideOutsideDates
      defaultValue={undefined}
      value={datetime}
      onChange={onChangeCallback}
      disabled={loading || (otherProps.disabled ?? false)}
      rightSection={loading ? <Loader size='xs' /> : otherProps.rightSection}
      date={undefined}
      excludeDate={excludeDateCallback}
      valueFormat={valueFormat}
      placeholder={placeholder}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      {...otherProps}
    />
  );
}

export type RequiredDayjsDateTimePickerProps = Omit<
  DayjsDateTimePickerProps,
  'value' | 'onChange' | 'clearable'
> & {
  value: Dayjs;
  onChange: (value: Dayjs) => void;
  tz: string; // which time zone to display in
};

export function RequiredDayjsDateTimePicker(
  props: RequiredDayjsDateTimePickerProps,
) {
  const { value, onChange, ...otherProps } = props;

  const onChangeCallback = useCallback(
    (d: Dayjs | null) => {
      if (d === null) {
        throw new Error('null datetime should not be possible');
      } else {
        onChange(d);
      }
    },
    [onChange],
  );

  return (
    <DayjsDateTimePicker
      onChange={onChangeCallback}
      value={value}
      clearable={false}
      {...otherProps}
    />
  );
}
