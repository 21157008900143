/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ManualSampleAnalysisCreationDTO } from '../models/ManualSampleAnalysisCreationDTO';
import type { ManualSampleAnalysisDTO } from '../models/ManualSampleAnalysisDTO';
import type { ManualSampleAnalysisPatchDTO } from '../models/ManualSampleAnalysisPatchDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ManualSampleAnalysisService {
    /**
     * @param sampleId
     * @returns ManualSampleAnalysisDTO Success
     * @throws ApiError
     */
    public static getManualSampleAnalyses(
        sampleId?: string,
    ): CancelablePromise<Array<ManualSampleAnalysisDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sample-analyses/manual',
            query: {
                'sampleId': sampleId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createManualSampleAnalysis(
        requestBody: ManualSampleAnalysisCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sample-analyses/manual',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param manualSampleAnalysisId
     * @returns ManualSampleAnalysisDTO Success
     * @throws ApiError
     */
    public static getManualSampleAnalysisById(
        manualSampleAnalysisId: string,
    ): CancelablePromise<ManualSampleAnalysisDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{manualSampleAnalysisId}',
            path: {
                'manualSampleAnalysisId': manualSampleAnalysisId,
            },
        });
    }
    /**
     * @param manualSampleAnalysisId
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchManualSampleAnalysis(
        manualSampleAnalysisId: string,
        requestBody: ManualSampleAnalysisPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/{manualSampleAnalysisId}',
            path: {
                'manualSampleAnalysisId': manualSampleAnalysisId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param manualSampleAnalysisId
     * @returns any Success
     * @throws ApiError
     */
    public static deleteManualSampleAnalysisById(
        manualSampleAnalysisId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/{manualSampleAnalysisId}',
            path: {
                'manualSampleAnalysisId': manualSampleAnalysisId,
            },
        });
    }
}
