import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DrawerForm, DrawerFormDrawerProps } from '../../Form/DrawerForm';
import { useCreateCommoditySpotPrice } from '../../api/commoditySpotPrice';
import { CommodityId, CommoditySpotPriceCreationDTO } from '../../rest-client';
import {
  CommoditySpotPriceFormFields,
  CommoditySpotPriceFormValues,
  useCommoditySpotPriceForm,
} from './CommoditySpotPriceForm';

export type AddCommoditySpotPriceDrawerFormProps = {
  onSuccess?: (commodity: CommoditySpotPriceCreationDTO) => void;
  commodityId: CommodityId;
} & DrawerFormDrawerProps;

export function AddCommoditySpotPriceDrawerForm(
  props: AddCommoditySpotPriceDrawerFormProps,
) {
  const { onSuccess, onClose, ...drawerFormDrawerProps } = props;

  const createMutation = useCreateCommoditySpotPrice(props.commodityId);
  const form = useCommoditySpotPriceForm();
  const [id, setId] = useState(uuidv4);

  const formValuesToDto = ({
    pricedAt,
    weightUnit,
    usdPerUnitOfWeight,
  }: CommoditySpotPriceFormValues) => {
    const commoditySpotPrice: CommoditySpotPriceCreationDTO = {
      id,
      pricedAt: pricedAt.format('YYYY-MM-DD'),
      weightUnit,
      usdPerUnitOfWeight,
    };
    setId(uuidv4());
    return commoditySpotPrice;
  };

  return (
    <DrawerForm
      entityName='Commodity Spot Price'
      form={form}
      formVariant='create'
      mutation={createMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      onClose={onClose}
      {...drawerFormDrawerProps}
    >
      <CommoditySpotPriceFormFields form={form} />
    </DrawerForm>
  );
}
