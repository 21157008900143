// All this machinery is to align the object URL lifetime with the query cache lifetime of the key

import { QueryCacheNotifyEvent } from '@tanstack/react-query';
import { queryKeys } from './api/queryKeys';
import {
  useFullResSamplingSuiteCaptureImage,
  useSamplingSuiteCapturePreview,
} from './api/samplingSuite';
import { queryClient } from './queryClient';

// We remove object URLs from the cache when the full res query expires in the react-query cache
type CaptureImageQueryKey =
  | ReturnType<typeof queryKeys.samplingSuiteCaptureImage.full>
  | ReturnType<typeof queryKeys.samplingSuiteCaptureImage.preview>;

function queryKeyIsSamplingSuiteCaptureImageKey(
  queryKey: unknown,
): queryKey is CaptureImageQueryKey {
  if (!Array.isArray(queryKey)) return false;
  const keyPart: unknown = queryKey[0];
  if (typeof keyPart !== 'object') return false;
  if (keyPart === null) return false;

  return (
    Object.entries(queryKeys.samplingSuiteCaptureImage.all).every(
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      ([k, v]) => v === (keyPart as Record<string, unknown>)[k],
    ) && 'captureId' in keyPart
  );
}
// maps capture ids to the full res object urls
const SAMPLING_SUITE_CAPTURE_FULL_RES_OBJECT_URL_CACHE = new Map<
  string,
  string
>();
const SAMPLING_SUITE_CAPTURE_PREVIEW_OBJECT_URL_CACHE = new Map<
  string,
  string
>();
function queryCacheListener(e: QueryCacheNotifyEvent) {
  if (e.type !== 'removed') return;
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const queryKey = e.query.queryKey as unknown;
  if (!queryKeyIsSamplingSuiteCaptureImageKey(queryKey)) return;
  const [{ captureId, shape }] = queryKey;

  if (shape === 'full') {
    const fullResObjectUrl =
      SAMPLING_SUITE_CAPTURE_FULL_RES_OBJECT_URL_CACHE.get(captureId);
    if (fullResObjectUrl) {
      URL.revokeObjectURL(fullResObjectUrl);
      SAMPLING_SUITE_CAPTURE_FULL_RES_OBJECT_URL_CACHE.delete(captureId);
    }
  } else if (shape === 'preview') {
    const previewObjectUrl =
      SAMPLING_SUITE_CAPTURE_PREVIEW_OBJECT_URL_CACHE.get(captureId);
    if (previewObjectUrl) {
      URL.revokeObjectURL(previewObjectUrl);
      SAMPLING_SUITE_CAPTURE_PREVIEW_OBJECT_URL_CACHE.delete(captureId);
    }
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _: never = shape;
  }
}
queryClient.getQueryCache().subscribe(queryCacheListener);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function useSamplingSuiteFullResObjectUrl(captureId: string) {
  const fullRes = useFullResSamplingSuiteCaptureImage(captureId);

  const cachedObjectUrl =
    SAMPLING_SUITE_CAPTURE_FULL_RES_OBJECT_URL_CACHE.get(captureId);
  if (cachedObjectUrl) return cachedObjectUrl;

  if (fullRes.data) {
    const objectUrl = URL.createObjectURL(fullRes.data);
    SAMPLING_SUITE_CAPTURE_FULL_RES_OBJECT_URL_CACHE.set(captureId, objectUrl);
    return objectUrl;
  }

  // TODO(2294): Caller can't tell if it's an error or loading state
  return undefined;
}

function useSamplingSuitePreviewObjectUrl(captureId: string) {
  const previewQuery = useSamplingSuiteCapturePreview(captureId);

  const cachedObjectUrl =
    SAMPLING_SUITE_CAPTURE_PREVIEW_OBJECT_URL_CACHE.get(captureId);
  if (cachedObjectUrl) return cachedObjectUrl;

  if (previewQuery.data) {
    const objectUrl = URL.createObjectURL(previewQuery.data);
    SAMPLING_SUITE_CAPTURE_PREVIEW_OBJECT_URL_CACHE.set(captureId, objectUrl);
    return objectUrl;
  }

  // TODO(2294): Caller can't tell if it's an error or loading state
  return undefined;
}

export function useLowResCaptureObjectUrl(captureId: string) {
  return useSamplingSuitePreviewObjectUrl(captureId);
}
export function useHighResCaptureObjectUrl(captureId: string) {
  const previewObjectUrl = useSamplingSuitePreviewObjectUrl(captureId);
  const highResObjectUrl = useSamplingSuiteFullResObjectUrl(captureId);
  return highResObjectUrl ?? previewObjectUrl;
}
