import { Skeleton, Table, Text, createStyles } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import ScaleDisplayDivisionsInput from '../../Weights/ScaleDisplayDivisionsInput';
import { MaterialClassSetDTO, ScaleId } from '../../rest-client';
import { ManualSampleAnalysisFormValues } from './ManualSampleAnalysisForm';

const useManualSampleAnalysisMaterialClassTableStyles = createStyles((t) => ({
  firstCol: {
    position: 'sticky',
    width: '8em',
    zIndex: 1,
    background: t.colorScheme === 'light' ? t.white : t.black,
    left: 0,
  },
  col: {
    width: '14em',
    background: t.colorScheme === 'light' ? t.white : t.black,
  },
}));

export type ManualSampleAnalysisMaterialClassTableProps = {
  form: UseFormReturnType<ManualSampleAnalysisFormValues>;
  scaleId: ScaleId | undefined;
  materialClassSet: MaterialClassSetDTO;
};

export function ManualSampleAnalysisMaterialClassTable(
  props: ManualSampleAnalysisMaterialClassTableProps,
) {
  const { form, scaleId, materialClassSet } = props;
  const { classes } = useManualSampleAnalysisMaterialClassTableStyles();

  const materialClasses = materialClassSet.materialClasses;

  return (
    <div
      style={{
        overflowX: 'scroll',
        scrollbarGutter: 'stable',
      }}
    >
      <Table
        withBorder
        withColumnBorders
        sx={{
          whiteSpace: 'nowrap',
          width: 'min-content',
          borderCollapse: 'separate',
        }}
      >
        <thead>
          <tr>
            <th className={classes.firstCol}>
              <div style={{ background: 'white' }}>Material Class</div>
            </th>
            {materialClasses.map((m) => (
              <th
                key={m.id}
                className={classes.col}
                style={{ borderLeft: 'none' }}
              >
                <Text>{m.name}</Text>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className={classes.firstCol}>Net Weight</th>
            {materialClasses.map((m, i) => (
              <td
                key={m.id}
                className={classes.col}
                style={{ padding: 0, borderLeft: 'none' }}
              >
                {scaleId ? (
                  <ScaleDisplayDivisionsInput
                    miw='8ch'
                    hideControls
                    hideUnit
                    scaleId={scaleId}
                    {...form.getInputProps(
                      `materialClasses.${i}.netWeightScaleDisplayDivisions`,
                    )}
                  />
                ) : (
                  <Skeleton visible>...</Skeleton>
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
