import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm.tsx';
import { useCreateCommodity } from '../api/commodity.ts';
import { CommodityCreationDTO } from '../rest-client/index.ts';
import {
  CommodityFormFields,
  CommodityFormValues,
  useCommodityForm,
} from './CommodityForm.tsx';

export type AddCommodityDrawerFormProps = {
  onSuccess?: (commodity: CommodityCreationDTO) => void;
} & DrawerFormDrawerProps;

export function AddCommodityDrawerForm(props: AddCommodityDrawerFormProps) {
  const { onSuccess, ...drawerFormDrawerProps } = props;

  const createMutation = useCreateCommodity();
  const form = useCommodityForm();
  const [id, setId] = useState(uuidv4);

  const formValuesToDto = ({ name, description }: CommodityFormValues) => {
    const commodity: CommodityCreationDTO = {
      id,
      description,
      name,
    };
    setId(uuidv4());
    return commodity;
  };

  return (
    <DrawerForm
      entityName='Commodity'
      form={form}
      formVariant='create'
      mutation={createMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      {...drawerFormDrawerProps}
    >
      <CommodityFormFields form={form} />
    </DrawerForm>
  );
}
