import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import { useCreateRecoveryGoal } from '../api/recoveryGoal';
import { RecoveryGoalCreationDTO } from '../rest-client';
import {
  RecoveryGoalFormFields,
  RecoveryGoalFormValues,
  useRecoveryGoalForm,
} from './RecoveryGoalForm';

export type AddRecoveryGoalDrawerFormProps = {
  onSuccess?: (recoveryGoal: RecoveryGoalCreationDTO) => void;
} & DrawerFormDrawerProps;

export function AddRecoveryGoalDrawerForm(
  props: AddRecoveryGoalDrawerFormProps,
) {
  const { onSuccess, ...drawerFormDrawerProps } = props;

  const createMutation = useCreateRecoveryGoal();
  const form = useRecoveryGoalForm();
  const [id, setId] = useState(uuidv4);

  const formValuesToDto = ({
    name,
    shortName,
    description,
    materialClassIds,
  }: RecoveryGoalFormValues) => {
    const recoveryGoal: RecoveryGoalCreationDTO = {
      id,
      name,
      shortName,
      description,
      materialClassIds,
    };
    setId(uuidv4());
    return recoveryGoal;
  };

  return (
    <DrawerForm
      entityName='Recovery Goal'
      form={form}
      formVariant='create'
      mutation={createMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      {...drawerFormDrawerProps}
    >
      <RecoveryGoalFormFields form={form} />
    </DrawerForm>
  );
}
