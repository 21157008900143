import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import { usePatchRecoveryGoal, useRecoveryGoal } from '../api/recoveryGoal';
import { useSetFormInitialValuesFromQuery } from '../lib/useSetFormInitialValuesFromQuery';
import { RecoveryGoalId, RecoveryGoalPatchDTO } from '../rest-client';
import {
  RecoveryGoalFormFields,
  RecoveryGoalFormValues,
  useRecoveryGoalForm,
} from './RecoveryGoalForm';

export type EditRecoveryGoalDrawerFormProps = {
  recoveryGoalId: RecoveryGoalId;
  onSuccess?: (recoveryGoal: RecoveryGoalPatchDTO) => void;
} & DrawerFormDrawerProps;

export function EditRecoveryGoalDrawerForm(
  props: EditRecoveryGoalDrawerFormProps,
) {
  const { recoveryGoalId, onSuccess, ...drawerFormDrawerProps } = props;

  const {
    data: recoveryGoal,
    isLoadingError,
    error,
  } = useRecoveryGoal(recoveryGoalId);
  const patchMutation = usePatchRecoveryGoal(recoveryGoalId);
  const form = useRecoveryGoalForm();

  useSetFormInitialValuesFromQuery(
    form,
    recoveryGoal && {
      name: recoveryGoal.name,
      description: recoveryGoal.description,
      shortName: recoveryGoal.shortName,
      materialClassIds: recoveryGoal.materialClasses.map((mc) => mc.id),
    },
  );

  if (isLoadingError) {
    throw error;
  }

  const formValuesToDto = ({
    name,
    description,
    shortName,
    materialClassIds,
  }: RecoveryGoalFormValues) => {
    const recoveryGoalPatch: RecoveryGoalPatchDTO = {
      ...(form.isDirty('name') && { name }),
      ...(form.isDirty('description') && { description }),
      ...(form.isDirty('shortName') && { shortName }),
      ...(form.isDirty('materialClassIds') && { materialClassIds }),
    };

    return recoveryGoalPatch;
  };

  return (
    <DrawerForm
      entityName='Recovery Goal'
      form={form}
      formVariant='edit'
      mutation={patchMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      {...drawerFormDrawerProps}
    >
      <RecoveryGoalFormFields form={form} />
    </DrawerForm>
  );
}
