import { ButtonProps } from '@mantine/core';
import { MaterialClassIcon } from '../Icons';
import { LinkButton } from '../Link';
import { Router } from '../router';

export type AddMaterialClassSetButtonProps = Omit<ButtonProps, 'onClick'>;

export default function AddMaterialClassSetButton(
  props: AddMaterialClassSetButtonProps,
) {
  const {
    leftIcon = <MaterialClassIcon />,
    children = 'Add Material Class Set',
    ...buttonProps
  } = props;

  return (
    <>
      <LinkButton
        to={Router.MaterialClassSetCreate()}
        leftIcon={leftIcon}
        {...buttonProps}
      >
        {children}
      </LinkButton>
    </>
  );
}
