import { Alert, Badge, Group } from '@mantine/core';
import dayjs from 'dayjs';
import { match } from 'ts-pattern';
import { ContainerIdName } from '../../Container/ContainerIdName';
import { SamplingIcon } from '../../Icons';
import { LinkText } from '../../Link';
import { CalendarDateTime } from '../../common';
import { ContainerSampleEventDTO } from '../../rest-client';
import { Router } from '../../router';
import { InventoryLedgerRowTemplate } from '../InventoryLedgerRowTemplate';
import { useInventoryLedgerStatusContext } from '../LedgerStatusContext';

export const ContainerSampleRow = ({
  containerSampleEvent,
}: {
  containerSampleEvent: ContainerSampleEventDTO;
}) => {
  const ledgerStatus = useInventoryLedgerStatusContext();
  const status = ledgerStatus.containerSampleStatus(containerSampleEvent);
  const ts = containerSampleEvent.sampleTakenTimestamp;

  const eventTypeCell = (
    <Group spacing='xs'>
      {<SamplingIcon />}
      <Badge color='blue'>Container Sample</Badge>
    </Group>
  );

  const sourceCell = (
    <ContainerIdName
      containerId={containerSampleEvent.containerId}
      variant='icon-name-link'
      time={dayjs.utc(containerSampleEvent.sampleTakenTimestamp)}
    />
  );

  const destinationCell = (
    <Group spacing='xs'>
      <SamplingIcon />
      <LinkText
        to={Router.SampleDetail({
          sampleId: containerSampleEvent.id,
        })}
      >
        Sample Details
      </LinkText>
    </Group>
  );

  let errorExplanationContent = null;
  if (status.status === 'orphaned' || status.status === 'conflict') {
    const errorMsg = match(status.status)
      .with(
        'orphaned',
        () =>
          'This sample data is not attached to a valid container (e.g. the container may have been empty when the sample was taken).',
      )
      .with(
        'conflict',
        () =>
          'This sample was taken at the exact same time that a transaction occurred involving its container. A container cannot have a sample taken at the same time that a transaction occurs.',
      )
      .exhaustive();

    errorExplanationContent = (
      <tr>
        <td colSpan={6}>
          <Alert color='red' title='Material Data Error'>
            {errorMsg}
          </Alert>
        </td>
      </tr>
    );
  }

  return (
    <>
      <InventoryLedgerRowTemplate
        eventType={eventTypeCell}
        date={<CalendarDateTime iso8601={ts} />}
        entryStatus={status}
        source={sourceCell}
        destination={destinationCell}
        // TODO(1809): delete, and maybe edit, behavior
        actions={null}
      />
      {errorExplanationContent}
    </>
  );
};
