/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefaultCommodityAssignmentCreationDTO } from '../models/DefaultCommodityAssignmentCreationDTO';
import type { DefaultCommodityAssignmentDTO } from '../models/DefaultCommodityAssignmentDTO';
import type { DefaultCommodityAssignmentMaterialNodeDTO } from '../models/DefaultCommodityAssignmentMaterialNodeDTO';
import type { UnassignedCommodityPathDTO } from '../models/UnassignedCommodityPathDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DefaultCommodityAssignmentService {
    /**
     * @param recoveryStrategyGuid
     * @param internalMaterialSourceGuid
     * @returns DefaultCommodityAssignmentMaterialNodeDTO Success
     * @throws ApiError
     */
    public static getDefaultCommodityAssignmentRecoveryTree(
        recoveryStrategyGuid: string,
        internalMaterialSourceGuid?: string,
    ): CancelablePromise<DefaultCommodityAssignmentMaterialNodeDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/default-commodity-assignments/recovery-strategy/{recoveryStrategyGuid}',
            path: {
                'recoveryStrategyGuid': recoveryStrategyGuid,
            },
            query: {
                'internalMaterialSourceGuid': internalMaterialSourceGuid,
            },
        });
    }
    /**
     * @returns DefaultCommodityAssignmentDTO Success
     * @throws ApiError
     */
    public static getDefaultCommodityAssignments(): CancelablePromise<Array<DefaultCommodityAssignmentDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/default-commodity-assignments',
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createDefaultCommodityAssignment(
        requestBody: DefaultCommodityAssignmentCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/default-commodity-assignments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param defaultCommodityAssignmentGuid
     * @returns DefaultCommodityAssignmentDTO Success
     * @throws ApiError
     */
    public static getDefaultCommodityAssignment(
        defaultCommodityAssignmentGuid: string,
    ): CancelablePromise<DefaultCommodityAssignmentDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/default-commodity-assignments/{defaultCommodityAssignmentGuid}',
            path: {
                'defaultCommodityAssignmentGuid': defaultCommodityAssignmentGuid,
            },
        });
    }
    /**
     * @param defaultCommodityAssignmentGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteDefaultCommodityAssignment(
        defaultCommodityAssignmentGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/default-commodity-assignments/{defaultCommodityAssignmentGuid}',
            path: {
                'defaultCommodityAssignmentGuid': defaultCommodityAssignmentGuid,
            },
        });
    }
    /**
     * @returns UnassignedCommodityPathDTO Success
     * @throws ApiError
     */
    public static getUnassignedCommodityPaths(): CancelablePromise<Array<UnassignedCommodityPathDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/default-commodity-assignments/unassigned-commodity-paths',
        });
    }
}
