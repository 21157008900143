import {
  Alert,
  Button,
  Center,
  Group,
  Loader,
  Paper,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { match } from 'ts-pattern';
import { v4 as uuidv4 } from 'uuid';
import { AppPage } from '../App/AppPage';
import { CompositionChart } from '../CompositionChart';
import { ContainerIdName } from '../Container/ContainerIdName';
import { useFacilityContext } from '../Facility/FacilityContext';
import { MutationErrorAlert } from '../Form/MutationErrorAlert';
import { DeleteIcon, EditIcon } from '../Icons';
import { LinkButton } from '../Link';
import { MaterialClassSetName } from '../MaterialClassSet/MaterialClassSetName';
import { MaterialClassSetSelect } from '../MaterialClassSet/MaterialClassSetSelect';
import { NotionalSampleDetails } from '../NotionalSample/NotionalSampleDetails';
import NetWeight from '../Weights/NetWeight';
import {
  useAddManualSampleAnalysis,
  useDeleteManualSampleAnalysis,
} from '../api/manualSampleAnalysis';
import { useMaterialClassSets } from '../api/materialClassSet';
import { useDeleteSample, useSample } from '../api/sample';
import { useSampleAnalyses } from '../api/sampleAnalysis';
import { useSamplingSuites } from '../api/samplingSuite';
import { useCreateSamplingSuiteSampleAnalysis } from '../api/samplingSuiteSampleAnalysis';
import { LabeledValue } from '../common';
import {
  ManualSampleAnalysisDTO,
  SampleAnalysisDTO,
  SampleDTO,
} from '../rest-client';
import { Router } from '../router';
import { composition } from '../util/mixture';
import { EditManualSampleAnalysisForm } from './ManualSampleAnalysis/EditManualSampleAnalysisForm';
import { ManualSampleAnalysisTable } from './ManualSampleAnalysis/ManualSampleAnalysisTable';
import { SamplingSuiteAnalysisDetail } from './SamplingSuiteAnalysisDetail';

export function SampleDetailPage(props: { sampleId: string }) {
  const { sampleId } = props;

  const sampleQuery = useSample(sampleId);

  return (
    <AppPage
      breadcrumbs={[{ routeName: Router.SampleList(), title: 'Samples' }]}
      title={
        sampleQuery.data &&
        (sampleQuery.data.externalId ?? sampleQuery.data.numericId)
      }
    >
      <SampleDetailsSection sampleId={sampleId} />
      <SampleAnalysisSection sampleId={sampleId} />
    </AppPage>
  );
}

function SampleDetailsSection(props: { sampleId: string }) {
  const { sampleId } = props;
  const {
    data: sample,
    isLoading,
    isLoadingError,
    error,
  } = useSample(sampleId);

  const deleteMutation = useDeleteSample();

  if (isLoadingError) {
    throw error;
  }

  return (
    <AppPage.Section>
      <Stack>
        <Group position='apart'>
          <Title order={2}>Sample Details</Title>
          <Button
            variant='outline'
            leftIcon={<DeleteIcon />}
            color='red'
            disabled={isLoading}
            onClick={() => {
              deleteMutation.mutate(sampleId, {
                onError() {
                  showNotification({
                    title: 'Error Deleting Sample',
                    message: 'An error ocurred deleting the sample.',
                    color: 'red',
                    icon: <IconX />,
                  });
                },
                onSuccess() {
                  showNotification({
                    title: 'Sample Created',
                    message: 'The sample was successfully deleted.',
                    color: 'green',
                    icon: <IconCheck />,
                  });
                  Router.replace('SampleList');
                },
                onSettled() {
                  deleteMutation.reset();
                },
              });
            }}
          >
            Delete Sample
          </Button>
        </Group>
        {isLoading ? (
          <Center>
            <Loader variant='dots' />
          </Center>
        ) : (
          <SampleDetails sample={sample} />
        )}
      </Stack>
    </AppPage.Section>
  );
}

function SampleDetails(props: { sample: SampleDTO }) {
  const { sample } = props;
  const { timeZoneId } = useFacilityContext();

  return match(sample)
    .with({ link: { type: 'container' } }, (containerSample) => (
      <>
        <LabeledValue label='Sampled Container'>
          {/* TODO(2156): Indicate stacking behavior of container / whether or not top of stack was relevant */}
          <ContainerIdName
            variant='name-only-link'
            time={dayjs.utc(containerSample.link.sampleTakenTimestamp)}
            containerId={containerSample.link.containerId}
          />
        </LabeledValue>

        <LabeledValue label='Sample Taken'>
          {dayjs
            .utc(containerSample.link.sampleTakenTimestamp)
            .tz(timeZoneId)
            .format('LLLL z')}
        </LabeledValue>
      </>
    ))
    .with({ link: { type: 'notional' } }, (notionalSample) => (
      <NotionalSampleDetails
        notionalSampleLink={notionalSample.link}
        numericSampleId={notionalSample.numericId}
      />
    ))
    .exhaustive();
}

function SampleAnalysisSection(props: { sampleId: string }) {
  const { sampleId } = props;
  const sampleAnalysesQuery = useSampleAnalyses({ sampleId });
  const materialClassSetsQuery = useMaterialClassSets();
  const samplingSuitesQuery = useSamplingSuites();

  const addManualAnalysisMutation = useAddManualSampleAnalysis();
  const addSamplingSuiteAnalysisMutation =
    useCreateSamplingSuiteSampleAnalysis();

  const [materialClassSetId, setMaterialClassSetId] = useState<string | null>(
    null,
  );
  const addSamplingSuiteAnalysis = () => {
    if (materialClassSetId === null) throw new Error();
    addSamplingSuiteAnalysisMutation.mutate(
      {
        id: uuidv4(),
        sampleId,
        materialClassSetId,
      },
      {
        onError() {
          showNotification({
            title: 'Error Creating VALI-Sample Analysis',
            message: 'An error occurred creating the analysis',
            color: 'red',
            icon: <IconX />,
          });
        },
        onSuccess() {
          showNotification({
            title: 'VALI-Sample Analysis Created',
            message: 'A new VALI-Sample analysis has been created',
            color: 'green',
            icon: <IconCheck />,
          });
        },
        onSettled() {
          addManualAnalysisMutation.reset();
        },
      },
    );
  };

  const addManualAnalysis = () => {
    addManualAnalysisMutation.mutate(
      {
        sampleId,
        id: uuidv4(),
        materialClassSetId,
        analysisTime: null,
        scaleId: null,
        scaleDisplayDivisions: null,
      },

      {
        onError() {
          showNotification({
            title: 'Error Creating Manual Sample Analysis',
            message: 'An error occurred creating the analysis',
            color: 'red',
            icon: <IconX />,
          });
        },
        onSuccess() {
          showNotification({
            title: 'Manual Sample Analysis Created',
            message: 'A new manual sample analysis has been created',
            color: 'green',
            icon: <IconCheck />,
          });
        },
        onSettled() {
          addManualAnalysisMutation.reset();
        },
      },
    );
  };

  if (sampleAnalysesQuery.isLoadingError) {
    throw sampleAnalysesQuery.error;
  }

  if (addManualAnalysisMutation.isError) {
    return (
      <MutationErrorAlert
        errorTitle='Error Creating Manual Sample Analysis'
        entityName='Sample Analysis'
        mutation={addManualAnalysisMutation}
        formVariant='create'
      />
    );
  }
  if (addSamplingSuiteAnalysisMutation.isError) {
    return (
      <MutationErrorAlert
        errorTitle='Error Creating VALI-Sample Analysis'
        entityName='Sample Analysis'
        mutation={addManualAnalysisMutation}
        formVariant='create'
      />
    );
  }

  const unanalyzed =
    sampleAnalysesQuery.data && sampleAnalysesQuery.data.length === 0;

  if (
    materialClassSetsQuery.data &&
    materialClassSetsQuery.data.length > 0 &&
    materialClassSetId === null
  ) {
    setMaterialClassSetId(materialClassSetsQuery.data[0].id);
  }

  const hasSamplingSuite =
    samplingSuitesQuery.data && samplingSuitesQuery.data.length > 0;

  const addSamplingSuiteAnalysisButton = hasSamplingSuite ? (
    <Button
      size={unanalyzed ? 'lg' : 'sm'}
      variant={unanalyzed ? 'filled' : 'outline'}
      color={unanalyzed ? 'teal' : 'gray'}
      disabled={materialClassSetId === null}
      onClick={() => addSamplingSuiteAnalysis()}
      loading={addSamplingSuiteAnalysisMutation.isLoading}
    >
      {unanalyzed
        ? 'Analyze with VALI-Sample'
        : 'Add Additional VALI-Sample Analysis'}
    </Button>
  ) : null;

  const addManualAnalysisButton = (
    <Button
      size={unanalyzed ? 'lg' : 'sm'}
      variant={hasSamplingSuite || !unanalyzed ? 'outline' : 'filled'}
      color={unanalyzed ? 'teal' : 'gray'}
      onClick={addManualAnalysis}
      loading={addManualAnalysisMutation.isLoading}
      disabled={materialClassSetsQuery.data?.length === 0}
    >
      {unanalyzed ? 'Analyze Manually' : 'Add Additional Manual Analysis'}
    </Button>
  );

  const newAnalysisActionCluster = (
    <Stack>
      {materialClassSetsQuery.data &&
        match(materialClassSetsQuery.data.length)
          .with(0, () => (
            <Alert color='orange' title='No Material Class Sets'>
              <Text>
                No material class sets have been created yet. This sample cannot
                be analyzed until one is defined.
              </Text>
              <LinkButton to={Router.MaterialClassSetCreate()}>
                Add Material Class Set
              </LinkButton>
            </Alert>
          ))
          .with(1, () => null)
          .otherwise(() => (
            <MaterialClassSetSelect
              description='Material class set to use for new material sample analysis.'
              w='fit-content'
              firstSelectedByDefault
              value={materialClassSetId}
              onChange={setMaterialClassSetId}
            />
          ))}

      <Group>
        {addSamplingSuiteAnalysisButton}
        {addManualAnalysisButton}
      </Group>
    </Stack>
  );

  if (unanalyzed) {
    return newAnalysisActionCluster;
  }

  return (
    <Stack>
      {sampleAnalysesQuery.data?.map((a) => (
        <Paper key={a.id} withBorder p='sm'>
          <SampleAnalysisDetail
            analysis={a}
            isFetching={sampleAnalysesQuery.isFetching}
            isError={sampleAnalysesQuery.isError}
          />
        </Paper>
      ))}
      {newAnalysisActionCluster}
    </Stack>
  );
}

function SampleAnalysisDetail(props: {
  analysis: SampleAnalysisDTO;
  isFetching: boolean;
  isError: boolean;
}) {
  const { analysis, isFetching, isError } = props;
  return match(analysis)
    .with({ type: 'manual' }, (manualAnalysis) => (
      <ManualSampleAnalysisDetail analysis={manualAnalysis} />
    ))
    .with({ type: 'sampling-suite' }, (samplingSuiteAnalysis) => (
      <SamplingSuiteAnalysisDetail
        analysis={samplingSuiteAnalysis}
        isFetching={isFetching}
        isError={isError}
      />
    ))
    .exhaustive();
}

function ManualSampleAnalysisDetail(props: {
  analysis: ManualSampleAnalysisDTO;
}) {
  const { analysis } = props;
  const facility = useFacilityContext();
  const [isEditing, setIsEditing] = useState(false);
  const [initialOpenComplete, setInitialOpenComplete] = useState(false);
  const deleteMutation = useDeleteManualSampleAnalysis();

  if (!analysis.isComplete && !isEditing && !initialOpenComplete) {
    setIsEditing(true);
    setInitialOpenComplete(true);
  }

  const materialClassComposition = analysis.isComplete
    ? composition(
        analysis.materialClassSet.materialClasses.map(
          (mc) =>
            [
              mc.name,
              analysis.materialClassMeasurements[mc.id].weight.ticks,
            ] as const,
        ),
      )
    : undefined;

  return (
    <Stack>
      <Group position='apart'>
        <Group>
          <LabeledValue label='Analysis Time'>
            {dayjs
              .utc(analysis.analysisTime)
              .tz(facility.timeZoneId)
              .format('LLLL z')}
          </LabeledValue>
          <LabeledValue label='Material Class Set'>
            <MaterialClassSetName
              materialClassSet={analysis.materialClassSet}
            />
          </LabeledValue>
          <LabeledValue label='Status'>
            <Text
              weight='bold'
              color={analysis.isComplete ? 'green' : 'orange'}
            >
              {analysis.isComplete ? 'Complete' : 'Incomplete'}
            </Text>
          </LabeledValue>
        </Group>
        <Button
          variant='outline'
          leftIcon={<DeleteIcon />}
          color='red'
          onClick={() => {
            modals.openConfirmModal({
              title: 'Delete sample analysis forever',
              centered: true,
              children: (
                <Text size='sm'>
                  Are you sure you want to delete this analysis? This action is
                  irreversible and the analysis will be deleted forever.
                </Text>
              ),
              labels: {
                confirm: 'Delete Analysis Forever',
                cancel: 'Back to Safety',
              },
              confirmProps: { color: 'red' },
              onConfirm: () => {
                deleteMutation.mutate(analysis.id, {
                  onError() {
                    showNotification({
                      title: 'Error Deleting Analysis',
                      message: `An error occurred the analysis.`,
                      color: 'red',
                      icon: <IconX />,
                    });
                  },
                  onSuccess() {
                    showNotification({
                      title: 'Analysis Deleted',
                      message: `The analysis was successfully deleted`,
                      color: 'green',
                      icon: <IconCheck />,
                    });
                  },
                  onSettled() {
                    deleteMutation.reset();
                  },
                });
              },
            });
          }}
        >
          Delete
        </Button>
      </Group>
      <Group>
        <LabeledValue label='Measured Total Weight'>
          <div style={{ display: 'inline-flex', gap: '1ch' }}>
            {analysis.measuredTotalNetWeight ? (
              <NetWeight weight={analysis.measuredTotalNetWeight} />
            ) : (
              <Text color='dimmed'>not recorded</Text>
            )}
          </div>
        </LabeledValue>
        <LabeledValue label='Accumulated Total Weight'>
          <NetWeight weight={analysis.accumulatedTotalNetWeight} />
        </LabeledValue>
        <LabeledValue label='Scale'>
          {/* TODO(1739): Link to scale detail page, once that's implemented */}
          {analysis.scale.name}
        </LabeledValue>
      </Group>
      {materialClassComposition ? (
        <CompositionChart
          composition={materialClassComposition}
          rotateXAxisLabels
        />
      ) : null}

      {isEditing ? (
        <EditManualSampleAnalysisForm
          analysis={analysis}
          onClose={() => setIsEditing(false)}
        />
      ) : (
        <>
          <ManualSampleAnalysisTable analysis={analysis} />
          <Button
            maw='max-content'
            leftIcon={<EditIcon />}
            onClick={() => setIsEditing(true)}
            variant={analysis.isComplete ? 'outline' : 'filled'}
            color={analysis.isComplete ? 'gray' : 'teal'}
          >
            Edit Measurements
          </Button>
        </>
      )}
    </Stack>
  );
}
