import { Alert } from '@mantine/core';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AppPage } from '../App/AppPage';
import { FormWrapper } from '../Form/FormWrapper';
import { useFormNavBlocker } from '../Form/useFormNavBlocker';
import { useCreateMaterialClassSet } from '../api/materialClassSet';
import { MaterialClassSetCreationDTO } from '../rest-client';
import { Router } from '../router';
import {
  MaterialClassFormFields,
  MaterialClassSetFormValues,
  useMaterialClassSetForm,
} from './MaterialClassSetForm';

export function AddMaterialClassSetPage() {
  const form = useFormNavBlocker(useMaterialClassSetForm());
  const createMutation = useCreateMaterialClassSet();
  const [id, setId] = useState(uuidv4);

  const formValuesToDto = ({
    name,
    materialClassIds,
  }: MaterialClassSetFormValues) => {
    const materialClassSet: MaterialClassSetCreationDTO = {
      id,
      name,
      materialClassIds,
    };
    setId(uuidv4());
    return materialClassSet;
  };

  return (
    <AppPage
      breadcrumbs={[
        {
          routeName: Router.MaterialClassList(),
          title: 'Material Classes',
        },
      ]}
      title='Add Material Class Set'
    >
      <AppPage.Section>
        <FormWrapper
          entityName='Material Class Set'
          form={form}
          formVariant='create'
          mutation={createMutation}
          onSuccess={(materialClassSet: MaterialClassSetCreationDTO) =>
            Router.push('MaterialClassSetDetail', {
              materialClassSetId: materialClassSet.id,
            })
          }
          formValuesToDto={formValuesToDto}
          onCancel={() => {
            Router.replace('MaterialClassList');
          }}
        >
          <>
            <MaterialClassFormFields form={form} />
            <Alert title='Material Classes Are Final' color='blue'>
              The material classes selected to be in this material class set
              cannot be edited after form submission. However, the order of the
              material classes can be updated post-submission.
            </Alert>
          </>
        </FormWrapper>
      </AppPage.Section>
    </AppPage>
  );
}
