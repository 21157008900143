import { useState } from 'react';
import { match } from 'ts-pattern';
import { v4 as uuidv4 } from 'uuid';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import { useCreateScaleReading } from '../api/scale';
import {
  ScaleReadingContainerAssociationDTO,
  ScaleReadingCreationDTO,
  ScaleReadingTruckLoadAssociationDTO,
} from '../rest-client';
import {
  ScaleReadingFormFields,
  ScaleReadingFormProps,
  ScaleReadingFormValues,
  useScaleReadingForm,
} from './ScaleReadingForm';

export type AddScaleReadingDrawerFormProps = ScaleReadingFormProps & {
  onSuccess?: (scaleReading: ScaleReadingCreationDTO) => void;
} & DrawerFormDrawerProps;

export function AddScaleReadingDrawerForm(
  props: AddScaleReadingDrawerFormProps,
) {
  const {
    onSuccess,
    repositoryId,
    repositoryKind,
    scale,
    ...drawerFormDrawerProps
  } = props;

  const createMutation = useCreateScaleReading();
  const form = useScaleReadingForm({
    repositoryId,
    repositoryKind,
    scale,
  });
  const [id, setId] = useState(uuidv4);

  const formValuesToDto = ({
    timestamp,
    scale,
    displayDivisions,
    repositoryKind,
    repositoryId,
  }: ScaleReadingFormValues) => {
    if (timestamp === null) {
      throw new Error('Timestamp cannot be null ');
    }
    if (scale === null) {
      throw new Error('Scale cannot be null ');
    }
    if (repositoryId === null) {
      throw new Error('Repository cannot be null');
    }

    const association = match(repositoryKind)
      .with(
        'Container',
        () =>
          ({
            kind: 'Container',
            containerId: repositoryId,
          }) as ScaleReadingContainerAssociationDTO,
      )
      .with(
        'TruckLoad',
        () =>
          ({
            kind: 'TruckLoad',
            truckLoadId: repositoryId,
          }) as ScaleReadingTruckLoadAssociationDTO,
      )
      .exhaustive();

    const scaleReading: ScaleReadingCreationDTO = {
      id,
      timestamp: timestamp.utc().toISOString(),
      scaleId: scale.id,
      displayDivisions,
      repositoryAssociation: association,
    };

    setId(uuidv4());
    return scaleReading;
  };

  return (
    <DrawerForm
      entityName='Scale Reading'
      form={form}
      formVariant='create'
      mutation={createMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      {...drawerFormDrawerProps}
    >
      <ScaleReadingFormFields form={form} />
    </DrawerForm>
  );
}
