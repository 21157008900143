import { Box, Text, TextProps } from '@mantine/core';
import { ReactNode } from 'react';

export type WithUnitProps = TextProps & {
  children: ReactNode;
  unitSuffix?: string;
  unitPrefix?: string;
};

export function WithUnit(props: WithUnitProps) {
  const {
    children,
    unitSuffix,
    unitPrefix,
    size = 'xs',
    c = 'dimmed',
    ...textProps
  } = props;
  return (
    <Box
      display='inline-flex'
      style={{ alignItems: 'baseline', columnGap: '0.1rem' }}
    >
      {unitPrefix && (
        <Text size={size} c={c} {...textProps}>
          {unitPrefix}
        </Text>
      )}
      {children}
      {unitSuffix && (
        <Text size={size} c={c} {...textProps}>
          {unitSuffix}
        </Text>
      )}
    </Box>
  );
}
