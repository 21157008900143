import { Loader } from '@mantine/core';
import { BarChart } from 'echarts/charts';
import { GridComponent } from 'echarts/components';
import * as echarts from 'echarts/core';
import orderBy from 'lodash.orderby';
import { useCallback } from 'react';
import { useChutecMaterialClasses } from '../api/chutec';
import { EChart } from '../echarts/BareEChart';
import { ChutecMaterialClassId } from '../rest-client';
import cssClasses from './ChutecAreasChart.module.css';

echarts.use([BarChart, GridComponent]);

// TODO(chutec): Refactor to CompositionChart
export function ChutecAreasChart({
  areas,
}: {
  areas: Record<ChutecMaterialClassId, number>;
}) {
  const nonZeroAreas = Object.fromEntries(
    Object.entries(areas).filter(([, area]) => area > 0),
  );

  const materialClassIds = orderBy(
    Object.keys(nonZeroAreas),
    [(id) => areas[id]],
    ['desc'],
  );
  const materialClassQueries = useChutecMaterialClasses(materialClassIds);

  const percentageFormatter = useCallback(
    (val: number | null) => `${val === null ? 'unknown ' : val * 100}%`,
    [],
  );
  const percentageValueFormatter = useCallback(
    (p: { value: number }) => (p.value * 100).toFixed(1) + '%',
    [],
  );

  if (!materialClassQueries.every((q) => q.data !== undefined)) {
    return <Loader />;
  }
  const materialClassNames = materialClassQueries.map((q) => q.data?.name);
  const totalArea = Object.values(nonZeroAreas).reduce((a, b) => a + b, 0);

  return (
    <EChart
      className={cssClasses.chutecAreaEchart}
      option={{
        xAxis: {
          type: 'category',
          data: materialClassNames,
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: percentageFormatter,
          },
        },
        series: [
          {
            type: 'bar',
            colorBy: 'data',
            data: materialClassIds.map(
              (materialClassId) => areas[materialClassId] / totalArea,
            ),
            label: {
              show: true,
              position: 'top',
              formatter: percentageValueFormatter,
            },
          },
        ],
      }}
    />
  );
}
