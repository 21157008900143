import dayjs from 'dayjs';
import { ReactNode, createContext, useContext } from 'react';
import { P, match } from 'ts-pattern';
import { useInventoryLedgerStatus } from '../api/transaction';
import {
  AdditionFullDestinationErrorDTO,
  BufferDepletionErrorDTO,
  BufferRestorationErrorDTO,
  ContainerClaimFlowConcurrencyWarningDTO,
  ContainerSampleEventDTO,
  ExtractionEmptySourceErrorDTO,
  FeedFlowGroupDTO,
  MaterialTransactionApplicationErrorDTO,
  MaterialTransactionDTO,
  OutputContainerChangeDTO,
  OutputContainerChangeErrorDTO,
  OutputContainerChangeId,
  ProcessBufferDepletionDTO,
  ProcessBufferDepletionId,
  ProcessBufferRestorationDTO,
  ProcessBufferRestorationId,
  ScaleReadingDTO,
  TransactionClaimConcurrencyErrorDTO,
  TransactionFlowConcurrencyWarningDTO,
} from '../rest-client';
import { getTransactionKey } from '../util/transactionKey';

export interface EmptyingTransactionStatus {
  status: 'emptying';
  error: ExtractionEmptySourceErrorDTO;
}
export interface FillingTransactionStatus {
  status: 'filling';
  error: AdditionFullDestinationErrorDTO;
}
export interface ErrantTransactionStatus {
  status: 'errant';
  errors: MaterialTransactionApplicationErrorDTO[];
}
export interface WarningTransactionStatus {
  status: 'warning';
  flowConcurrencyWarnings: TransactionFlowConcurrencyWarningDTO[];
  commodityMixingWarning: boolean;
}
export type TransactionStatus =
  | { status: 'loading' }
  | { status: 'applied' }
  | EmptyingTransactionStatus
  | FillingTransactionStatus
  | ErrantTransactionStatus
  | WarningTransactionStatus
  | { status: 'unapplied' };

export type ClaimStatus =
  | { status: 'loading' }
  | { status: 'applied' }
  | { status: 'orphaned' }
  | { status: 'unapplied' }
  | { status: 'warning'; warnings: ContainerClaimFlowConcurrencyWarningDTO[] }
  | { status: 'conflict'; errors: TransactionClaimConcurrencyErrorDTO[] };

export type ScaleReadingStatus = ClaimStatus | { status: 'unassociated' };

export type OutputContainerChangeStatus =
  | { status: 'loading' }
  | { status: 'valid' }
  | { status: 'conflict'; errors: OutputContainerChangeErrorDTO[] };

export type BufferRestorationStatus =
  | { status: 'loading' }
  | { status: 'valid' }
  | { status: 'conflict'; errors: BufferRestorationErrorDTO[] };

export type BufferDepletionStatus =
  | { status: 'loading' }
  | { status: 'valid' }
  | { status: 'conflict'; errors: BufferDepletionErrorDTO[] };

export type FeedFlowGroupStatus =
  | { status: 'loading' }
  | { status: 'valid' }
  | {
      status: 'conflict';
      errors: (
        | BufferDepletionErrorDTO
        | BufferRestorationErrorDTO
        | MaterialTransactionApplicationErrorDTO
      )[];
    };

export function getAffectedErrantTransactions(
  applicationError: MaterialTransactionApplicationErrorDTO,
): MaterialTransactionDTO[] {
  return match(applicationError)
    .with({ kind: 'ExtractionEmptySource' }, ({ failedTransaction }) => [
      failedTransaction,
    ])
    .with({ kind: 'AdditionFullDestination' }, ({ failedTransaction }) => [
      failedTransaction,
    ])
    .with(
      { kind: 'MaterialTransactionConflict' },
      ({ transactionA, transactionB }) => [transactionA, transactionB],
    )

    .with({ kind: 'TransactionClaimConcurrency' }, ({ transaction }) => [
      transaction,
    ])
    .exhaustive();
}

export function getAffectedWarningTransaction(
  transactionFlowConcurrencyWarning: TransactionFlowConcurrencyWarningDTO,
) {
  return transactionFlowConcurrencyWarning.transaction;
}

export function getAffectedWarningClaimId(
  containerClaimConcurrencyWarning: ContainerClaimFlowConcurrencyWarningDTO,
) {
  return containerClaimConcurrencyWarning.ledgerId;
}

export function getAffectedErrantOutputContainerChanges(
  outputContainerChangeError: OutputContainerChangeErrorDTO,
): OutputContainerChangeDTO[] {
  return match(outputContainerChangeError)
    .with({ kind: 'SimultaneousOutputContainerChangeError' }, (error) => [
      error.outputContainerChangeA,
      error.outputContainerChangeB,
    ])
    .exhaustive();
}

export function getAffectedErrantProcessBufferRestorations(
  bufferRestorationError: BufferRestorationErrorDTO,
): ProcessBufferRestorationDTO[] {
  return match(bufferRestorationError)
    .with({ kind: 'DuplicateBufferRestorationError' }, (error) => [
      error.firstBufferRestoration,
      error.duplicateBufferRestoration,
    ])
    .with({ kind: 'NotImplicitlyDepletedBufferRestorationError' }, () => [])
    .exhaustive();
}

export function getAffectedErrantProcessBufferDepletions(
  bufferDepletionError: BufferDepletionErrorDTO,
): ProcessBufferDepletionDTO[] {
  return match(bufferDepletionError)
    .with({ kind: 'SimultaneousBufferDepletionError' }, (error) => [
      error.depletionA,
      error.depletionB,
    ])
    .exhaustive();
}

export interface InventoryLedgerStatusContext {
  transactionStatus: (txn: MaterialTransactionDTO) => TransactionStatus;
  scaleReadingStatus: (scaleReading: ScaleReadingDTO) => ScaleReadingStatus;
  containerSampleStatus: (
    containerSample: ContainerSampleEventDTO,
  ) => ClaimStatus;
  outputContainerChangeStatus: (
    outputContainerChange: OutputContainerChangeDTO,
  ) => OutputContainerChangeStatus;
  bufferRestorationStatus: (
    bufferRestoration: ProcessBufferRestorationDTO,
  ) => BufferRestorationStatus;
  bufferDepletionStatus: (
    bufferDepletion: ProcessBufferDepletionDTO,
  ) => BufferDepletionStatus;
  feedFlowGroupStatus: (feedFlowGroup: FeedFlowGroupDTO) => FeedFlowGroupStatus;
}

const InventoryLedgerStatusContext = createContext<
  InventoryLedgerStatusContext | undefined
>(undefined);

export function useInventoryLedgerStatusContext(): InventoryLedgerStatusContext {
  const ctx = useContext(InventoryLedgerStatusContext);
  if (ctx === undefined) {
    throw new Error(
      'component must be within an inventory ledger status context',
    );
  }
  return ctx;
}

export function InventoryLedgerStatusProvider(props: { children: ReactNode }) {
  const { children } = props;

  const statusQuery = useInventoryLedgerStatus();

  const transactionKeyErrorMap = match(statusQuery.data)
    .with({ status: 'invalid' }, (invalidStatus) => {
      const errorMap = new Map<
        string,
        MaterialTransactionApplicationErrorDTO[]
      >();

      if (invalidStatus.transactionErrors === null) return errorMap;

      const applicationErrors =
        invalidStatus.transactionErrors.transactionApplicationErrors;
      for (const applicationError of applicationErrors) {
        const affectedTxnKeys =
          getAffectedErrantTransactions(applicationError).map(
            getTransactionKey,
          );

        for (const affectedTxnKey of affectedTxnKeys) {
          const errorsForThisTxn = errorMap.get(affectedTxnKey);
          if (errorsForThisTxn !== undefined) {
            errorsForThisTxn.push(applicationError);
          } else {
            errorMap.set(affectedTxnKey, [applicationError]);
          }
        }
      }
      return errorMap;
    })
    .with({ status: 'valid' }, () => null)
    .with({ status: 'warning' }, () => null)
    .with(undefined, () => undefined)
    .exhaustive();

  const transactionFlowConcurrencyWarnings = match(statusQuery.data)
    .with({ status: 'invalid' }, () => null)
    .with({ status: 'warning' }, (warningStatus) => {
      const flowConcurrencyWarnings = new Map<
        string,
        TransactionFlowConcurrencyWarningDTO[]
      >();
      for (const transactionWarning of warningStatus.transactionFlowConcurrencyWarnings) {
        const affectedTxnKey = getTransactionKey(
          getAffectedWarningTransaction(transactionWarning),
        );
        const warningForThisTxn = flowConcurrencyWarnings.get(affectedTxnKey);
        if (warningForThisTxn !== undefined) {
          warningForThisTxn.push(transactionWarning);
        } else {
          flowConcurrencyWarnings.set(affectedTxnKey, [transactionWarning]);
        }
      }
      return flowConcurrencyWarnings;
    })
    .with({ status: 'valid' }, () => null)
    .with(undefined, () => undefined)
    .exhaustive();

  const commodityMixingTransactions = match(statusQuery.data)
    .with({ status: 'invalid' }, () => null)
    .with(
      { status: 'warning' },
      ({ commodityMixingTransactions }) =>
        new Map(commodityMixingTransactions.map((txn) => [txn.ledgerId, txn])),
    )
    .with({ status: 'valid' }, () => null)
    .with(undefined, () => undefined)
    .exhaustive();

  const containerClaimKeyWarningMap = match(statusQuery.data)
    .with({ status: 'invalid' }, () => null)
    .with({ status: 'warning' }, (warningStatus) => {
      const warningMap = new Map<
        string,
        ContainerClaimFlowConcurrencyWarningDTO[]
      >();
      if (warningStatus.containerClaimFlowConcurrencyWarnings.length === 0) {
        return warningMap;
      }
      for (const claimWarning of warningStatus.containerClaimFlowConcurrencyWarnings) {
        const affectedClaimKey = getAffectedWarningClaimId(claimWarning);
        const warningForThisClaim = warningMap.get(affectedClaimKey);
        if (warningForThisClaim !== undefined) {
          warningForThisClaim.push(claimWarning);
        } else {
          warningMap.set(affectedClaimKey, [claimWarning]);
        }
      }
      return warningMap;
    })
    .with({ status: 'valid' }, () => null)
    .with(undefined, () => undefined)
    .exhaustive();

  const outputContainerChangeKeyErrorMap = match(statusQuery.data)
    .with({ status: 'invalid' }, (invalidStatus) => {
      const outputContainerChangeErrorMap = new Map<
        OutputContainerChangeId,
        OutputContainerChangeErrorDTO[]
      >();
      if (invalidStatus.outputContainerChangeErrors === null)
        return outputContainerChangeErrorMap;
      const { outputContainerChangeErrors } = invalidStatus;
      for (const error of outputContainerChangeErrors) {
        const affectedOutputContainerChangeIds =
          getAffectedErrantOutputContainerChanges(error).map(
            (outputContainerChange) => outputContainerChange.id,
          );
        for (const id of affectedOutputContainerChangeIds) {
          const errorsForThisOutuptContainerChange =
            outputContainerChangeErrorMap.get(id);
          if (errorsForThisOutuptContainerChange !== undefined) {
            errorsForThisOutuptContainerChange.push(error);
          } else {
            outputContainerChangeErrorMap.set(id, [error]);
          }
        }
      }
      return outputContainerChangeErrorMap;
    })
    .with({ status: 'valid' }, () => null)
    .with({ status: 'warning' }, () => null)
    .with(undefined, () => undefined)
    .exhaustive();

  const bufferRestorationKeyErrorMap = match(statusQuery.data)
    .with({ status: 'invalid' }, (invalidStatus) => {
      const bufferRestorationErrorMap = new Map<
        ProcessBufferRestorationId,
        BufferRestorationErrorDTO[]
      >();
      if (invalidStatus.bufferErrors === null) return bufferRestorationErrorMap;
      const { bufferRestorationErrors } = invalidStatus.bufferErrors;
      for (const error of bufferRestorationErrors) {
        const affectedBufferRestorationIds =
          getAffectedErrantProcessBufferRestorations(error).map(
            (bufferRestoration) => bufferRestoration.id,
          );
        for (const id of affectedBufferRestorationIds) {
          const errorsForThisBufferRestoration =
            bufferRestorationErrorMap.get(id);
          if (errorsForThisBufferRestoration !== undefined) {
            errorsForThisBufferRestoration.push(error);
          } else {
            bufferRestorationErrorMap.set(id, [error]);
          }
        }
      }
      return bufferRestorationErrorMap;
    })
    .with({ status: 'valid' }, () => null)
    .with({ status: 'warning' }, () => null)
    .with(undefined, () => undefined)
    .exhaustive();

  const bufferDepletionKeyErrorMap = match(statusQuery.data)
    .with({ status: 'invalid' }, (invalidStatus) => {
      const bufferDepletionErrorMap = new Map<
        ProcessBufferDepletionId,
        BufferDepletionErrorDTO[]
      >();
      if (invalidStatus.bufferErrors === null) return bufferDepletionErrorMap;
      const { bufferDepletionErrors } = invalidStatus.bufferErrors;
      for (const error of bufferDepletionErrors) {
        const affectedBufferDepletionIds =
          getAffectedErrantProcessBufferDepletions(error).map(
            (bufferDepletion) => bufferDepletion.id,
          );
        for (const id of affectedBufferDepletionIds) {
          const errorsForThisBufferDepletion = bufferDepletionErrorMap.get(id);
          if (errorsForThisBufferDepletion !== undefined) {
            errorsForThisBufferDepletion.push(error);
          } else {
            bufferDepletionErrorMap.set(id, [error]);
          }
        }
      }

      return bufferDepletionErrorMap;
    })
    .with({ status: 'valid' }, () => null)
    .with({ status: 'warning' }, () => null)
    .with(undefined, () => undefined)
    .exhaustive();

  // TODO(1809): add warnings to status context

  const oldestUnappliedTransactionTimestamp = match(statusQuery.data)
    .with(
      { status: 'invalid', transactionErrors: P.select(P.not(null)) },
      (errors) => dayjs.utc(errors.oldestUnappliedTransactionTimestamp),
    )
    .with(undefined, () => undefined)
    .otherwise(() => null);

  let orphanedScaleReadingIds = new Set<string>();
  let orphanedContainerSampleIds = new Set<string>();

  if (statusQuery.data !== undefined && statusQuery.data.status === 'invalid') {
    if (statusQuery.data.orphanedClaims) {
      const {
        containerScaleReadingMassClaims,
        truckLoadScaleReadingMassClaims,
        containerSampleClaims,
      } = statusQuery.data.orphanedClaims;
      orphanedScaleReadingIds = new Set([
        ...containerScaleReadingMassClaims.map((c) => c.scaleReadingId),
        ...truckLoadScaleReadingMassClaims.map((c) => c.scaleReadingId),
      ]);
      orphanedContainerSampleIds = new Set(
        containerSampleClaims.map((c) => c.id),
      );
    }
  }

  const ctx: InventoryLedgerStatusContext = {
    transactionStatus(txn) {
      if (
        transactionKeyErrorMap === undefined ||
        transactionFlowConcurrencyWarnings === undefined
      ) {
        return { status: 'loading' };
      }

      if (transactionKeyErrorMap === null) {
        const mixed = commodityMixingTransactions?.has(txn.ledgerId) ?? false;
        if (
          transactionFlowConcurrencyWarnings !== null ||
          commodityMixingTransactions?.has(txn.ledgerId)
        ) {
          const txnKey = getTransactionKey(txn);
          const flowConcurrencyWarnings =
            transactionFlowConcurrencyWarnings?.get(txnKey) ?? [];

          if (flowConcurrencyWarnings.length > 0 || mixed)
            return {
              status: 'warning',
              flowConcurrencyWarnings: flowConcurrencyWarnings,
              commodityMixingWarning: mixed,
            };
        }
        return { status: 'applied' };
      }

      const txnKey = getTransactionKey(txn);
      const errors = transactionKeyErrorMap.get(txnKey);
      if (errors) {
        return { status: 'errant', errors };
      }

      if (oldestUnappliedTransactionTimestamp) {
        const unapplied = dayjs
          .utc(txn.effectiveTimestamp)
          .isSameOrAfter(oldestUnappliedTransactionTimestamp);
        if (unapplied) return { status: 'unapplied' };
      }
      return { status: 'applied' };
    },
    scaleReadingStatus(scaleReading) {
      const association = scaleReading.repositoryAssociation;
      if (association === null) return { status: 'unassociated' };

      if (
        statusQuery.data === undefined ||
        containerClaimKeyWarningMap === undefined
      ) {
        return { status: 'loading' };
      }

      if (statusQuery.data.status === 'valid') {
        return { status: 'applied' };
      }

      if (oldestUnappliedTransactionTimestamp) {
        const unapplied = dayjs
          .utc(scaleReading.timestamp)
          .isSameOrAfter(oldestUnappliedTransactionTimestamp);
        if (unapplied) return { status: 'unapplied' };
      }

      if (orphanedScaleReadingIds.has(scaleReading.id)) {
        return { status: 'orphaned' };
      }

      if (statusQuery.data.status === 'warning') {
        if (containerClaimKeyWarningMap !== null) {
          const warnings = containerClaimKeyWarningMap.get(scaleReading.id);
          if (warnings) {
            return { status: 'warning', warnings };
          }
        }
      }

      return { status: 'applied' };
    },
    containerSampleStatus(containerSample) {
      if (
        statusQuery.data === undefined ||
        containerClaimKeyWarningMap === undefined
      ) {
        return { status: 'loading' };
      }

      if (statusQuery.data.status === 'valid') {
        return { status: 'applied' };
      }

      if (oldestUnappliedTransactionTimestamp) {
        const unapplied = dayjs
          .utc(containerSample.sampleTakenTimestamp)
          .isSameOrAfter(oldestUnappliedTransactionTimestamp);
        if (unapplied) return { status: 'unapplied' };
      }

      // TODO check for conflict status

      if (orphanedContainerSampleIds.has(containerSample.id)) {
        return { status: 'orphaned' };
      }

      if (statusQuery.data.status === 'warning') {
        if (containerClaimKeyWarningMap !== null) {
          const warnings = containerClaimKeyWarningMap.get(containerSample.id);
          if (warnings) {
            return { status: 'warning', warnings };
          }
        }
      }

      return { status: 'applied' };
    },
    outputContainerChangeStatus(outputContainerChange) {
      if (outputContainerChangeKeyErrorMap === undefined) {
        return { status: 'loading' };
      }
      if (outputContainerChangeKeyErrorMap === null) return { status: 'valid' };
      const errors = outputContainerChangeKeyErrorMap.get(
        outputContainerChange.id,
      );
      if (errors) {
        return { status: 'conflict', errors };
      }
      return { status: 'valid' };
    },
    bufferRestorationStatus(bufferRestoration) {
      if (bufferRestorationKeyErrorMap === undefined) {
        return { status: 'loading' };
      }
      if (bufferRestorationKeyErrorMap === null) return { status: 'valid' };
      const errors = bufferRestorationKeyErrorMap.get(bufferRestoration.id);
      if (errors) {
        return { status: 'conflict', errors };
      }
      return { status: 'valid' };
    },
    bufferDepletionStatus(bufferDepletion) {
      if (bufferDepletionKeyErrorMap === undefined) {
        return { status: 'loading' };
      }
      if (bufferDepletionKeyErrorMap === null) return { status: 'valid' };
      const errors = bufferDepletionKeyErrorMap.get(bufferDepletion.id);
      if (errors) {
        return { status: 'conflict', errors };
      } else {
        return { status: 'valid' };
      }
    },

    feedFlowGroupStatus(feedFlowGroup) {
      if (
        bufferDepletionKeyErrorMap === undefined ||
        bufferRestorationKeyErrorMap === undefined ||
        transactionKeyErrorMap === undefined
      ) {
        return { status: 'loading' };
      }
      const errors: (
        | BufferDepletionErrorDTO
        | BufferRestorationErrorDTO
        | MaterialTransactionApplicationErrorDTO
      )[] = [];
      if (
        bufferDepletionKeyErrorMap !== null &&
        feedFlowGroup.explicitBufferDepletionId !== null
      ) {
        const bufferDepletionErrors = bufferDepletionKeyErrorMap.get(
          feedFlowGroup.explicitBufferDepletionId,
        );
        if (bufferDepletionErrors !== undefined) {
          errors.push(...bufferDepletionErrors);
        }
      }
      if (
        bufferRestorationKeyErrorMap !== null &&
        feedFlowGroup.bufferRestorationId !== null
      ) {
        const bufferRestorationErrors = bufferRestorationKeyErrorMap.get(
          feedFlowGroup.bufferRestorationId,
        );
        if (bufferRestorationErrors !== undefined) {
          errors.push(...bufferRestorationErrors);
        }
      }
      if (
        transactionKeyErrorMap !== null &&
        feedFlowGroup.latestBufferTransferId !== null
      ) {
        const bufferTransferErrors = transactionKeyErrorMap.get(
          feedFlowGroup.latestBufferTransferId,
        );
        if (bufferTransferErrors !== undefined) {
          errors.push(...bufferTransferErrors);
        }
      }
      if (errors.length === 0) {
        return { status: 'valid' };
      } else {
        return { status: 'conflict', errors: errors };
      }
    },
  };

  return (
    <InventoryLedgerStatusContext.Provider value={ctx}>
      {children}
    </InventoryLedgerStatusContext.Provider>
  );
}
