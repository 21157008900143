import { Loader, MultiSelect, MultiSelectProps } from '@mantine/core';
import { ReactNode } from 'react';
import { useFacilityContext } from '../Facility/FacilityContext';
import { useProcesses } from '../api/process';
import { ProcessId } from '../rest-client';

export type ProcessMultiSelectProps = Omit<
  MultiSelectProps,
  | 'value'
  | 'onChange'
  | 'data'
  | 'disabled'
  | 'placeholder'
  | 'nothingFound'
  | 'rightSection'
> & {
  value: ProcessId[];
  onChange: (processIds: ProcessId[]) => void;
  emptyContent: ReactNode;
};

export function ProcessMultiSelect(props: ProcessMultiSelectProps) {
  const { value, onChange, emptyContent, ...multiSelectProps } = props;
  const facility = useFacilityContext();
  const {
    isLoadingError,
    error,
    isLoading,
    data: processes,
  } = useProcesses(facility.id);
  if (isLoadingError) {
    throw error;
  }

  return (
    <MultiSelect
      data={
        processes?.map((process) => ({
          value: process.id,
          label: process.name,
        })) ?? []
      }
      disabled={isLoading}
      label='Process'
      placeholder='select Processes'
      nothingFound={emptyContent}
      rightSection={isLoading ? <Loader size='xs' /> : undefined}
      value={value}
      onChange={onChange}
      {...multiSelectProps}
    />
  );
}
