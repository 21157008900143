import { Group, Title } from '@mantine/core';
import { AppPage } from '../App/AppPage';
import AddMaterialClassSetButton from '../MaterialClassSet/AddMaterialClassSetButton';
import { MaterialClassSetTable } from '../MaterialClassSet/MaterialClassSetTable';
import AddMaterialClassButton from './AddMaterialClassButton';
import { MaterialClassTable } from './MaterialClassTable';

export function MaterialClassesPage() {
  return (
    <AppPage title='Material Classes'>
      <AppPage.Section>
        <Group>
          <Title order={2}>Material Classes</Title>
          <AddMaterialClassButton addMaterialClassDrawerFormProps={{}} />
        </Group>
        <MaterialClassTable />
      </AppPage.Section>
      <AppPage.Section>
        <Group>
          <Title order={2}>Material Class Sets</Title>
          <AddMaterialClassSetButton />
        </Group>
        <MaterialClassSetTable />
      </AppPage.Section>
    </AppPage>
  );
}
