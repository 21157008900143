import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { msalInstance } from './msalConfig';

/**
 * Tries to get a token from the cache silently, and handles interaction required conditions
 */
export async function GetMsalAccessToken(): Promise<string> {
  const accessTokenRequest = {
    scopes: [`api://${import.meta.env.VITE_AZURE_AD_CLIENT_ID}/vali-sort`],
    // TODO(2297): Make this robust against multiple accounts
    account: msalInstance.getAllAccounts()[0],
  };

  try {
    const { accessToken } =
      await msalInstance.acquireTokenSilent(accessTokenRequest);
    return accessToken;
  } catch (e) {
    if (e instanceof InteractionRequiredAuthError) {
      // TODO: Test with redirect intead of popup because it requires fewer browser permissions?
      const { accessToken } =
        await msalInstance.acquireTokenPopup(accessTokenRequest);
      return accessToken;
    }
    console.error('failed to get api access token', e);
    throw e;
  }
}
