import { TextInput } from '@mantine/core';
import { UseFormReturnType, useForm } from '@mantine/form';
import { MaterialClassSetSelect } from '../MaterialClassSet/MaterialClassSetSelect';
import { RecoveryStrategySelect } from '../RecoveryStrategy/RecoveryStrategySelect';
import { MaterialClassSetId, RecoveryStrategyId } from '../rest-client';

export interface RecoveryStrategySimulationFormProps {
  recoveryStrategyId?: RecoveryStrategyId;
  materialClassSetId?: MaterialClassSetId;
}

export interface RecoveryStrategySimulationFormValues {
  recoveryStrategyId: RecoveryStrategyId | null;
  materialClassSetId: MaterialClassSetId | null;
  name: string;
}

export function useRecoveryStrategySimulationForm(
  props: RecoveryStrategySimulationFormProps,
) {
  const { recoveryStrategyId, materialClassSetId } = props;

  return useForm<RecoveryStrategySimulationFormValues>({
    initialValues: {
      recoveryStrategyId: recoveryStrategyId ?? null,
      materialClassSetId: materialClassSetId ?? null,
      name: '',
    },
    validate: {
      recoveryStrategyId: (rs) => (rs ? null : 'Recovery strategy is required'),
      materialClassSetId: (mcs) =>
        mcs ? null : 'Material class set is required',
      name: (name) => (name.length >= 1 ? null : 'Name is required'),
    },
  });
}

export type RecoveryStrategySimulationFormFieldsProps =
  RecoveryStrategySimulationFormProps & {
    form: UseFormReturnType<RecoveryStrategySimulationFormValues>;
  };

export function RecoveryStrategySimulationFormFields(
  props: RecoveryStrategySimulationFormFieldsProps,
) {
  const { form, recoveryStrategyId, materialClassSetId } = props;

  return (
    <>
      <TextInput
        label='Simulation Name'
        withAsterisk
        {...form.getInputProps('name')}
      />
      <RecoveryStrategySelect
        description='Defines the recovery steps to be simulated.'
        withAsterisk
        disabled={recoveryStrategyId !== undefined}
        {...form.getInputProps('recoveryStrategyId')}
      />
      <MaterialClassSetSelect
        description='Defines the material classes present in the simulation parameters and outputs.'
        withAsterisk
        disabled={materialClassSetId !== undefined}
        firstSelectedByDefault
        {...form.getInputProps('materialClassSetId')}
      />
    </>
  );
}
