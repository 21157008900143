import dayjs from 'dayjs';
import { match } from 'ts-pattern';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import { usePatchScaleReading, useScaleReading } from '../api/scale';
import { useSetFormInitialValuesFromQuery } from '../lib/useSetFormInitialValuesFromQuery';
import { ScaleReadingId, ScaleReadingPatchDTO } from '../rest-client';
import {
  ScaleReadingFormFields,
  ScaleReadingFormProps,
  ScaleReadingFormValues,
  useScaleReadingForm,
} from './ScaleReadingForm';

export type EditScaleReadingDrawerFormProps = ScaleReadingFormProps & {
  scaleReadingId: ScaleReadingId;
  onSuccess?: (scaleReading: ScaleReadingPatchDTO) => void;
} & DrawerFormDrawerProps;

export function EditScaleReadingDrawerForm(
  props: EditScaleReadingDrawerFormProps,
) {
  const { scaleReadingId, onSuccess, ...drawerFormDrawerProps } = props;

  // const facility = useFacilityContext();
  const {
    data: scaleReading,
    isLoadingError: scaleReadingIsLoadingError,
    error: scaleReadingError,
  } = useScaleReading(scaleReadingId);
  const patchMutation = usePatchScaleReading(scaleReadingId);
  const form = useScaleReadingForm({});

  useSetFormInitialValuesFromQuery(
    form,
    scaleReading && {
      timestamp: dayjs.utc(scaleReading.timestamp),
      scale: scaleReading.scale,
      displayDivisions: scaleReading.displayDivisions,
      repositoryKind: scaleReading.repositoryAssociation?.kind ?? 'Container',
      repositoryId: match(scaleReading.repositoryAssociation)
        .with({ kind: 'Container' }, (repo) => repo.containerId)
        .with({ kind: 'TruckLoad' }, (repo) => repo.truckLoadId)
        .with(null, () => null)
        .exhaustive(),
    },
  );

  if (scaleReadingIsLoadingError) {
    throw scaleReadingError;
  }

  const formValuesToDto = ({
    timestamp,
    displayDivisions,
  }: ScaleReadingFormValues) => {
    if (timestamp === null) {
      throw new Error('Timestamp cannot be null ');
    }
    if (displayDivisions === null) {
      throw new Error('Weight cannot be null');
    }

    const scaleReadingPatch: ScaleReadingPatchDTO = {
      ...(form.isDirty('timestamp') && {
        timestamp: timestamp.utc().toISOString(),
      }),
      ...(form.isDirty('displayDivisions') && { displayDivisions }),
    };

    return scaleReadingPatch;
  };

  return (
    <DrawerForm
      entityName='Scale Reading'
      form={form}
      formVariant='edit'
      mutation={patchMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      {...drawerFormDrawerProps}
    >
      <ScaleReadingFormFields form={form} />
    </DrawerForm>
  );
}
