import { Alert, Flex, Input, InputWrapperProps, Loader } from '@mantine/core';
import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import { useToggle } from '@mantine/hooks';
import { IconAlertTriangle } from '@tabler/icons-react';
import dayjs, { Dayjs } from 'dayjs';
import { P, match } from 'ts-pattern';
import { z } from 'zod';
import ContainerSelect from '../Container/ContainerSelect';
import { useFacilityContext } from '../Facility/FacilityContext';
import ContainerFullnessSwitch from '../Input/ContainerFullnessSwitch';
import { InvalidVisibilityToggle } from '../Input/InvalidVisibilityToggle';
import { ProcessOutputPortSelect } from '../Process/ProcessOutputPortSelect';
import { ProcessSelect } from '../Process/ProcessSelect';
import {
  DayjsDateTimePicker,
  DayjsDateTimePickerProps,
  RequiredDayjsDateTimePicker,
} from '../Time/DayjsDateTimePicker';
import { usePreviousProcessOutputPortState } from '../api/outputContainerChange';
import { useProcess } from '../api/process';
import { MaterialContainerId, OutputPortId, ProcessId } from '../rest-client';

const OutputContainerChangeFormSchema = z
  .object({
    changeTime: z.instanceof(dayjs as unknown as typeof Dayjs, {
      message: 'Container change time is required',
    }),
    processId: z.string().uuid().nullable(),
    outputPortId: z.string().uuid().nullable(),
    newContainerId: z.string().uuid().nullable(),
    previousContainerFilled: z.boolean(),
  })
  .refine(({ processId }) => processId !== null, {
    path: ['processId'],
    message: 'Process output is required',
  })
  .refine(({ outputPortId }) => outputPortId !== null, {
    path: ['outputPortId'],
    message: 'Process is required',
  });

export type OutputContainerChangeFormValues = z.infer<
  typeof OutputContainerChangeFormSchema
>;

export type OutputContainerChangeFormProps = {
  processId?: ProcessId;
  outputPortId?: OutputPortId;
  newContainerId?: MaterialContainerId;
};

export function useOutputContainerChangeForm(
  props: OutputContainerChangeFormProps,
) {
  const { processId, outputPortId, newContainerId } = props;
  return useForm<OutputContainerChangeFormValues>({
    initialValues: {
      changeTime: dayjs.utc(),
      processId: processId ?? null,
      outputPortId: outputPortId ?? null,
      newContainerId: newContainerId ?? null,
      previousContainerFilled: false,
    },
    validate: zodResolver(OutputContainerChangeFormSchema),
  });
}

export function OutputContainerChangeFormFields(props: {
  form: UseFormReturnType<OutputContainerChangeFormValues>;
}) {
  const { form } = props;

  const facility = useFacilityContext();

  const { data: process, isLoading } = useProcess(
    form.values.processId ?? undefined,
  );

  const [showFullContainers, toggleShowFullContainers] = useToggle();

  const { data: previousProcessOutputPortState } =
    usePreviousProcessOutputPortState({
      processId: form.values.processId ?? undefined,
      outputPortId: form.values.outputPortId ?? undefined,
      beforeTime: form.values.changeTime,
    });
  const showContainerFullnessSwitch =
    previousProcessOutputPortState?.previousOutputContainerChange?.kind ===
    'Container';

  // OutputPort is specific to a Process, so if the OutputPort select is disabled, so should the Process select
  const processSelectDisabled =
    form.values.processId !== null && !form.isDirty('processId');
  // OutputPort is predicated on Process, so if the process has not been selected, then OutputPort select does not have a candidate set to select from
  const outputPortSelectDisabled =
    (form.values.outputPortId !== null && !form.isDirty('outputPortId')) ||
    process === undefined;

  return (
    <>
      <RequiredDayjsDateTimePicker
        label='Container Change Time'
        description='The time at which the process output port container was changed.'
        tz={facility.timeZoneId}
        withAsterisk
        {...form.getInputProps('changeTime')}
      />

      <Flex gap='md'>
        <ProcessSelect
          label='Process'
          description='The process which is having its output container changed.'
          placeholder='select process'
          withAsterisk
          clearable
          disabled={processSelectDisabled}
          value={form.values.processId}
          onChange={(value) => {
            form.setFieldValue('processId', value);
            if (value === null) {
              form.setFieldValue('outputPortId', null);
            }
          }}
          error={form.errors['processId']}
        />
        <ProcessOutputPortSelect
          processOutputPorts={process?.outputs}
          label='Process Output Port'
          description='Process output port having its container changed.'
          placeholder='select output'
          disabled={outputPortSelectDisabled}
          withAsterisk
          isLoading={isLoading}
          {...form.getInputProps('outputPortId')}
        />
      </Flex>

      <Flex gap='md'>
        <PreviousProcessOutputPortState
          processId={form.values.processId}
          outputPortId={form.values.outputPortId}
          beforeTime={form.values.changeTime}
        />
        {showContainerFullnessSwitch && (
          <ContainerFullnessSwitch
            inputWrapperProps={{
              label: 'Previous Container Filled',
              description:
                'Was the the previous container present on this process output filled at time of change?',
            }}
            variant='destination'
            orientation='horizontal'
            size='lg'
            {...form.getInputProps('previousContainerFilled', {
              type: 'checkbox',
            })}
          />
        )}
      </Flex>

      <Flex gap='md'>
        <ContainerSelect
          label='New Output Container'
          description='The new output for the specific process output port. Leave blank if the output port is being left empty.'
          facilityId={facility.id}
          timestamp={form.values.changeTime}
          hideEmpty={false}
          hideFull={!showFullContainers}
          clearable
          style={{ flexBasis: '75%' }}
          {...form.getInputProps('newContainerId')}
        />
        <InvalidVisibilityToggle
          labelVariant='top'
          label='Show Full Containers'
          value={showFullContainers}
          onToggle={toggleShowFullContainers}
          actionIconProps={{
            style: { alignSelf: 'flex-end' },
          }}
          inputWrapperProps={{
            description:
              'Include full containers in new output container select?',
          }}
        />
      </Flex>

      {showFullContainers ? (
        <Alert color='orange' icon={<IconAlertTriangle />} title=''>
          Creating a feedstock transfer from an empty source contaiener will
          result in an ledger error. You can still create the feedstock
          transfer, but the error will need to be corrected later.
        </Alert>
      ) : undefined}
    </>
  );
}

type PreviousProcessOutputPortStateBaseProps = {
  processId: ProcessId | null;
  outputPortId: OutputPortId | null;
  beforeTime: Dayjs;
};

type PreviousProcessOutputPortStateProps = {
  containerInputWrapperProps?: Omit<
    InputWrapperProps,
    'children' | 'required' | 'withAsterisk'
  >;
  dayjsDateTimePickerProps?: Omit<
    DayjsDateTimePickerProps,
    'value' | 'onChange' | 'tz' | 'disabled' | 'loading'
  >;
} & PreviousProcessOutputPortStateBaseProps;

function PreviousProcessOutputPortState(
  props: PreviousProcessOutputPortStateProps,
) {
  const {
    processId,
    outputPortId,
    beforeTime,
    containerInputWrapperProps = {
      label: 'Previous Container',
      description:
        'The container previously present on the process output port at the time of this container change.',
    },
    dayjsDateTimePickerProps = {
      label: 'Previous Change Time',
      description:
        'Time the process output port output was changed to the previous container.',
    },
  } = props;

  const {
    data: previousState,
    isFetching,
    isLoadingError,
    error,
  } = usePreviousProcessOutputPortState({
    processId: processId ?? undefined,
    outputPortId: outputPortId ?? undefined,
    beforeTime,
  });
  const facility = useFacilityContext();

  if (isLoadingError) {
    throw error;
  }

  const { previousContainerName, previousChangeTime } = isFetching
    ? { previousContainerName: 'Loading...', previousChangeTime: null }
    : match(previousState)
        .with(undefined, () => {
          return {
            previousContainerName: 'Select time, process, and output port',
            previousChangeTime: null,
          };
        })
        .with({ previousOutputContainerChange: null }, () => {
          return {
            previousContainerName: 'Never Initialized',
            previousChangeTime: null,
          };
        })
        .with(
          { previousOutputContainerChange: P.not(null) },
          ({ previousOutputContainerChange }) => {
            return {
              previousContainerName: match(previousOutputContainerChange)
                .with(
                  { kind: 'Container' },
                  (withContainer) => withContainer.container.name,
                )
                .with({ kind: 'Empty' }, () => 'No Container')
                .exhaustive(),
              previousChangeTime: dayjs
                .utc(previousOutputContainerChange.changeTime)
                .tz(facility.timeZoneId),
            };
          },
        )
        .exhaustive();

  return (
    <>
      <Input.Wrapper {...containerInputWrapperProps}>
        <Input
          disabled
          value={previousContainerName}
          rightSection={isFetching ? <Loader size='xs' /> : undefined}
        />
      </Input.Wrapper>
      <DayjsDateTimePicker
        value={previousChangeTime?.tz(facility.timeZoneId) ?? null}
        placeholder='select time, process, and output'
        onChange={() => undefined}
        tz={facility.timeZoneId}
        disabled
        loading={isFetching}
        {...dayjsDateTimePickerProps}
      />
    </>
  );
}
