import { useFacilityStore } from '../App/facilityStore';
import { FacilityDTO } from '../rest-client';

export function useFacilityContext(): FacilityDTO {
  const facility = useFacilityStore((s) => s.facility);
  if (facility === undefined) {
    throw new Error('facility is not set');
  }
  return facility;
}
