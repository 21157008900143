import dayjs from 'dayjs';
import { useFacilityContext } from '../Facility/FacilityContext';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import {
  useInternallySourcedMaterial,
  usePatchInternallySourcedMaterial,
} from '../api/internallySourcedMaterial';
import { useSetFormInitialValuesFromQuery } from '../lib/useSetFormInitialValuesFromQuery';
import {
  InternallySourcedMaterialId,
  InternallySourcedMaterialPatchDTO,
  WeightUnit,
} from '../rest-client';
import {
  InternallySourcedMaterialFormFields,
  InternallySourcedMaterialFormValues,
  useInternallySourcedMaterialForm,
} from './InternallySourcedMaterialForm';

export type EditInternallySourcedMaterialDrawerFormProps = {
  internallySourcedMaterialId: InternallySourcedMaterialId;
  onSuccess?: (
    internallySourcedMaterial: InternallySourcedMaterialPatchDTO,
  ) => void;
} & DrawerFormDrawerProps;

export function EditInternallySourcedMaterialDrawerForm(
  props: EditInternallySourcedMaterialDrawerFormProps,
) {
  const { internallySourcedMaterialId, onSuccess, ...drawerFormDrawerProps } =
    props;

  const facility = useFacilityContext();
  const {
    data: internallySourcedMaterial,
    isLoadingError,
    error,
  } = useInternallySourcedMaterial(internallySourcedMaterialId);
  const patchMutation = usePatchInternallySourcedMaterial(
    internallySourcedMaterialId,
  );
  const form = useInternallySourcedMaterialForm({});

  useSetFormInitialValuesFromQuery(
    form,
    internallySourcedMaterial && {
      name: internallySourcedMaterial.name,
      creationTime: dayjs
        .utc(internallySourcedMaterial.creationTime)
        .tz(facility.timeZoneId),
      internalMaterialSourceId:
        internallySourcedMaterial.internalMaterialSourceId,
      ...(internallySourcedMaterial.weight
        ? {
            weight: {
              enabled: true,
              unit: internallySourcedMaterial.weight.unit,
              tickSize: Number(internallySourcedMaterial.weight.tickSize),
              ticks: Number(internallySourcedMaterial.weight.ticks),
            },
          }
        : {
            weight: {
              enabled: false,
              unit: WeightUnit.POUND,
              tickSize: 1,
              ticks: null,
            },
          }),
    },
  );

  if (isLoadingError) {
    throw error;
  }

  const formValuesToDto = ({
    name,
    internalMaterialSourceId,
    creationTime,
    weight,
  }: InternallySourcedMaterialFormValues) => {
    if (internalMaterialSourceId === null) {
      throw new Error('Internal material source cannot be null');
    }

    const internallySourcedMaterialPatch: InternallySourcedMaterialPatchDTO = {
      ...(form.isDirty('name') && { name }),
      ...(form.isDirty('creationTime') && {
        creationTime: creationTime.utc().toISOString(),
      }),
      ...(form.isDirty('internalMaterialSourceId') && {
        internalMaterialSourceId,
      }),
      ...((form.isDirty('weight.enabled') ||
        form.isDirty('weight.unit') ||
        form.isDirty('weight.tickSize') ||
        form.isDirty('weight.ticks')) &&
        (weight.enabled && weight.tickSize && weight.unit && weight.ticks
          ? {
              weight: {
                unit: weight.unit,
                tickSize: `${weight.tickSize}`,
                ticks: weight.ticks,
              },
            }
          : {
              weight: null,
            })),
    };

    return internallySourcedMaterialPatch;
  };

  return (
    <DrawerForm
      entityName='Material Export'
      form={form}
      formVariant='edit'
      mutation={patchMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      {...drawerFormDrawerProps}
    >
      <InternallySourcedMaterialFormFields form={form} />
    </DrawerForm>
  );
}
