import * as signalR from '@microsoft/signalr';
import { HttpTransportType } from '@microsoft/signalr';
import { useEffect, useRef } from 'react';
import { create } from 'zustand';
import { GetMsalAccessToken } from '../Microsoft/msalUtil';

let signalRHubConnectionBuilder: signalR.HubConnectionBuilder | undefined =
  undefined;

export function configureSamplingSessionSignalRHubConnectionBuilder(opts: {
  apiBaseUrl: string;
}) {
  const { apiBaseUrl } = opts;

  const samplingSessionHubUrl = `${apiBaseUrl}/sampling-suites/sessions/hub`;

  signalRHubConnectionBuilder = new signalR.HubConnectionBuilder()
    // TODO: configure logging?
    .withAutomaticReconnect()
    .withUrl(samplingSessionHubUrl, {
      transport: HttpTransportType.LongPolling, // TODO: Try to get authentication working with websockets or SSE - see https://learn.microsoft.com/en-us/aspnet/core/signalr/authn-and-authz?view=aspnetcore-9.0#bearer-token-authentication
      accessTokenFactory: GetMsalAccessToken,
      withCredentials: false, // turn this off so that we don't bother with cookies, which requires more CORS permissions
    });
}

export type SamplingSessionSignalRState =
  | 'connecting'
  | 'connected'
  | 'reconnecting'
  | 'disconnected';

interface SamplingSessionSignalRStore {
  state: SamplingSessionSignalRState; // initializes to undefined
  setState: (state: SamplingSessionSignalRState) => void;
}

export const useSamplingSessionSignalRStore =
  create<SamplingSessionSignalRStore>()((set) => ({
    state: 'connecting',
    setState: (state) => {
      set(() => ({ state }));
    },
  }));

export function useSamplingSessionSignalR(opts: {
  onInvalidateSession: (sessionId: string) => void;
}) {
  const { onInvalidateSession: onInvalidateSessionArg } = opts;

  // we have to pull most things into refs here because we only want to set up the connection once

  const onInvalidateSessionRef = useRef<(sessionId: string) => void>();
  useEffect(() => {
    onInvalidateSessionRef.current = onInvalidateSessionArg;
  }, [onInvalidateSessionArg]);

  const setConnectedInStore = useSamplingSessionSignalRStore((s) => s.setState);

  const setStateRef = useRef<(state: SamplingSessionSignalRState) => void>();
  useEffect(() => {
    setStateRef.current = setConnectedInStore;
  }, [setConnectedInStore]);

  useEffect(() => {
    if (!signalRHubConnectionBuilder) {
      console.error(
        'failed to start sampling session SignalR connection because hub connection builder has not been configured',
      );
      setStateRef.current?.('disconnected');
      return;
    }
    const conn = signalRHubConnectionBuilder.build();

    conn.on('InvalidateSession', (sesssionId: string) => {
      onInvalidateSessionRef.current?.(sesssionId);
    });

    conn.onreconnected(() => {
      setStateRef.current?.('connected');
    });

    conn.onreconnecting(() => {
      setStateRef.current?.('reconnecting');
    });

    console.debug('starting sampling session SignalR connection');
    setStateRef.current?.('connecting');
    conn
      .start()
      .then(() => {
        setStateRef.current?.('connected');
      })
      .catch((e) => {
        console.error('failed to start sampling session SignalR connection', e);
        setStateRef.current?.('disconnected');
      });

    return () => {
      setStateRef.current?.('disconnected');
      conn
        .stop()
        .then(() => {
          console.debug('closed sampling session SignalR connection');
        })
        .catch((e) => {
          console.warn(
            'stopping sampling session SignalR connection failed',
            e,
          );
        });
    };
  }, []);
}
