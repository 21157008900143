import { Pagination, Table, Text, useMantineTheme } from '@mantine/core';
import { IconAlertTriangle, IconCheck } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { P, match } from 'ts-pattern';
import { CommodityIdName } from '../Commodity/CommodityName.tsx';
import { useFacilityContext } from '../Facility/FacilityContext.tsx';
import { LinkText } from '../Link.tsx';
import { TableEmptyBasicContent } from '../TableEmptyBasicContent.tsx';
import NetWeight from '../Weights/NetWeight.tsx';
import { MaterialClassSetSampleAnalysesDTO } from '../rest-client/index.ts';
import { Router } from '../router.ts';
import { PCTFORMAT } from '../util/percentages.ts';

export function MaterialClassSetSampleAnalysesTable(props: {
  materialClassSetSampleAnalyses: MaterialClassSetSampleAnalysesDTO;
}) {
  const { materialClassSetSampleAnalyses } = props;
  const { colors, spacing } = useMantineTheme();
  const [activePage, setPage] = useState(1);

  const { timeZoneId: tz } = useFacilityContext();

  const { materialClassSet, analyses } = materialClassSetSampleAnalyses;

  const pageSize = 10;
  const totalPages = Math.ceil(analyses.length / pageSize);
  const paginatedAnalyses = analyses.slice(
    (activePage - 1) * pageSize,
    activePage * pageSize,
  );

  return (
    <Table captionSide='bottom'>
      <caption
        style={{
          captionSide: 'bottom',
          textAlign: 'center',
          padding: spacing.md,
        }}
      >
        {analyses.length === 0 ? (
          <TableEmptyBasicContent>No Sample Analyses</TableEmptyBasicContent>
        ) : (
          <Pagination
            value={activePage}
            onChange={setPage}
            total={totalPages}
          />
        )}
      </caption>
      <thead>
        <tr>
          <th style={{ textAlign: 'right' }}>Sample Id</th>
          {/* TODO: Commodity column / intermediate product name? */}
          <th style={{ textAlign: 'right' }}>Analyzed At</th>
          <th>Complete</th>
          <th>Commodity</th>
          <th style={{ textAlign: 'right' }}>Sample Weight</th>
          {materialClassSet.materialClasses.map((materialClass) => (
            <th
              key={materialClass.id}
              style={{
                borderLeftColor: colors.gray[4],
                borderLeftStyle: 'solid',
                borderLeftWidth: '.5px',
                textAlign: 'right',
              }}
            >
              {materialClass.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {paginatedAnalyses.map(
          ({ sampleAnalysis: analysis, materialClassValues, commodityId }) => {
            return (
              <tr key={analysis.id}>
                <td style={{ textAlign: 'right' }}>
                  <LinkText
                    to={Router.SampleDetail({
                      sampleId: analysis.sampleId,
                    })}
                  >
                    {analysis.sampleExternalId ??
                      `${analysis.sampleNumericId}`.padStart(6, '0')}
                  </LinkText>
                </td>
                <td style={{ textAlign: 'right' }}>
                  {dayjs.utc(analysis.timestamp).tz(tz).format('l')}
                </td>

                <td style={{ textAlign: 'center' }}>
                  {analysis.isComplete ? (
                    <IconCheck color={colors.green[6]} />
                  ) : (
                    <IconAlertTriangle color={colors.yellow[7]} />
                  )}
                </td>
                <td>
                  {commodityId !== null ? (
                    <CommodityIdName commodityId={commodityId} />
                  ) : (
                    <Text c='dimmed'>None</Text>
                  )}
                </td>
                <td style={{ textAlign: 'right' }}>
                  {analysis.measuredTotalNetWeight ? (
                    <NetWeight weight={analysis.measuredTotalNetWeight} />
                  ) : analysis.isComplete ? (
                    <NetWeight weight={analysis.accumulatedTotalNetWeight} />
                  ) : (
                    <Text c='dimmed'>?</Text>
                  )}
                </td>
                {materialClassValues?.map(
                  ({ expectedProportion, mostPrevalent }, idx) => (
                    <td
                      key={idx}
                      style={{
                        textAlign: 'right',
                        borderLeftColor: colors.gray[4],
                        borderLeftStyle: 'solid',
                        borderLeftWidth: '.5px',
                      }}
                    >
                      {match(expectedProportion)
                        .with(P.number.lte(0), () => <Text c='dimmed'>-</Text>)
                        .with(P.number, (proportion) => (
                          <Text fw={mostPrevalent ? 700 : undefined}>
                            {PCTFORMAT.format(proportion)}
                          </Text>
                        ))
                        .exhaustive()}
                    </td>
                  ),
                ) ??
                  materialClassSet.materialClasses.map((mc) => (
                    <td
                      key={mc.id}
                      style={{
                        textAlign: 'right',
                        borderLeftColor: colors.gray[4],
                        borderLeftStyle: 'solid',
                        borderLeftWidth: '.5px',
                      }}
                    >
                      <Text c='dimmed'>?</Text>
                    </td>
                  ))}
              </tr>
            );
          },
        )}
      </tbody>
    </Table>
  );
}
