import { Flex, Paper, Skeleton, Stack, Title } from '@mantine/core';
import { ReactFlowProvider } from 'reactflow';
import { AppPage } from '../App/AppPage';
import { AllFeedFlowGroupsTable } from '../FeedFlowGroup/FeedFlowGroupTable';
import { SortSystemName } from '../SortSystem/SortSystemName';
import { useProcess } from '../api/process';
import { LabeledValue } from '../common';
import { ProcessId } from '../rest-client';
import { Router } from '../router';
import { ProcessTopologyDiagram } from './ProcessTopologyDiagram';

export function ProcessDetail({ processId }: { processId: ProcessId }) {
  const processQuery = useProcess(processId);

  if (processQuery.isLoadingError) {
    throw processQuery.error;
  }

  if (processQuery.isLoading) {
    return (
      <Paper p='md' shadow='md'>
        <Skeleton visible h={600} />
      </Paper>
    );
  }
  const process = processQuery.data;

  const breadcrumbs = [{ routeName: Router.ProcessList(), title: 'Processes' }];

  return (
    <AppPage breadcrumbs={breadcrumbs} title={process.name}>
      <AppPage.Section>
        <Stack>
          <Title order={2}>Process Details</Title>
          <Flex align='flex-start' gap='md'>
            <LabeledValue
              label={
                process.sortSystems.length === 1
                  ? 'Sort System'
                  : 'Sort Systems'
              }
            >
              <Stack>
                {process.sortSystems.map((ss) => (
                  <SortSystemName sortSystem={ss} key={ss.id} />
                ))}
              </Stack>
            </LabeledValue>
            <LabeledValue label='Process Diagram'>
              <Paper withBorder p='xs' w={500}>
                <ReactFlowProvider>
                  <Skeleton
                    visible={processQuery.isLoading}
                    height={250}
                    width='100%'
                  >
                    <ProcessTopologyDiagram process={process} showSystemLinks />
                  </Skeleton>
                </ReactFlowProvider>
              </Paper>
            </LabeledValue>
          </Flex>
        </Stack>
      </AppPage.Section>
      <AppPage.Section>
        <Title order={2}>Process Runs</Title>
        <AllFeedFlowGroupsTable
          processId={processId}
          pageSize={5}
          defaultOutputContainersFiltered
        />
      </AppPage.Section>
    </AppPage>
  );
}
