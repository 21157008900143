import { useCallback } from 'react';
import { useOnSelectionChange } from 'reactflow';
import { match } from 'ts-pattern';
import { ElkFlowEdge, ElkFlowNode, ElkReactFlow } from '../lib/reactFlowElk';
import {
  MaterialNodeDTO,
  RecoveryGoalNodeDTO,
  RecoveryTreeDiagramMaterialNode,
  RecoveryTreeDiagramRecoveryNode,
  RecoveryTreeNodeDTO,
} from './RecoveryTreeGraphNodes';

export enum RecoveryTreeNodeVariant {
  basic = 'basic',
  commoditySpread = 'commodity-spread',
  defaultCommodityAssignment = 'default-commodity-assignment',
}

export type RecoveryNodeData = {
  type: 'recovery';
  isRoot: boolean;
  recoveryGoalNode: RecoveryGoalNodeDTO;
  variant: RecoveryTreeNodeVariant;
};

export type MaterialNodeData = {
  type: 'material';
  isRoot: boolean;
  isLeaf: boolean;
  materialNode: MaterialNodeDTO;
  variant: RecoveryTreeNodeVariant;
};

type RecoveryTreeNodeData = RecoveryNodeData | MaterialNodeData;

type InputMaterialEdgeData = {
  type: 'input-material';
};

type RecoveryOutputEdgeData = {
  type: 'recovery-output';
  isNegative: boolean;
};

type RecoveryTreeEdgeData = InputMaterialEdgeData | RecoveryOutputEdgeData;

export type RecoveryTreeNode = ElkFlowNode<RecoveryTreeNodeData>;
export type RecoveryTreeEdge = ElkFlowEdge<RecoveryTreeEdgeData>;

type RecoveryTreeStructure = {
  nodes: RecoveryTreeNode[];
  edges: RecoveryTreeEdge[];
};

function getDescendentMaterialNodeStructure(
  materialNodeDto: MaterialNodeDTO,
  parentRecoveryGoalId: string | null,
  variant: RecoveryTreeNodeVariant,
): RecoveryTreeStructure {
  const materialNode: ElkFlowNode<MaterialNodeData> = {
    id: materialNodeDto.index.toString(),
    position: { x: 0, y: 0 },
    data: {
      type: 'material',
      materialNode: materialNodeDto,
      isRoot: parentRecoveryGoalId === null,
      isLeaf: materialNodeDto.consumingRecoveryGoalNode === null,
      variant: variant,
    },
    type: 'material',
  };

  const incomingRecoveryOutputEdge: ElkFlowEdge<RecoveryOutputEdgeData>[] =
    parentRecoveryGoalId !== null
      ? [
          {
            id: `${materialNode.id}-${parentRecoveryGoalId}`,
            source: parentRecoveryGoalId,
            target: materialNode.id,
            data: {
              isNegative: materialNodeDto.isNegative!,
              type: 'recovery-output',
            },
          },
        ]
      : [];

  const descendentStructure: RecoveryTreeStructure =
    materialNodeDto.consumingRecoveryGoalNode !== null
      ? getDescendentRecoveryGoalNodeStructure(
          materialNodeDto.consumingRecoveryGoalNode,
          materialNode.id,
          variant,
        )
      : {
          nodes: [],
          edges: [],
        };

  return {
    nodes: [materialNode, ...descendentStructure.nodes],
    edges: [...incomingRecoveryOutputEdge, ...descendentStructure.edges],
  };
}

function getDescendentRecoveryGoalNodeStructure(
  recoveryGoalNodeDto: RecoveryGoalNodeDTO,
  parentMaterialNodeId: string | null,
  variant: RecoveryTreeNodeVariant,
): RecoveryTreeStructure {
  const recoveryNode: ElkFlowNode<RecoveryNodeData> = {
    id: recoveryGoalNodeDto.index.toString(),
    position: { x: 0, y: 0 },
    data: {
      type: 'recovery',
      recoveryGoalNode: recoveryGoalNodeDto,
      isRoot: parentMaterialNodeId === null,
      variant: variant,
    },
    type: 'recovery',
    selectable: variant !== RecoveryTreeNodeVariant.defaultCommodityAssignment,
  };

  const incomingEdge: ElkFlowEdge<InputMaterialEdgeData>[] =
    parentMaterialNodeId !== null
      ? [
          {
            id: `${parentMaterialNodeId}-${recoveryGoalNodeDto.index}`,
            source: parentMaterialNodeId,
            target: recoveryNode.id,
            data: {
              type: 'input-material',
            },
          },
        ]
      : [];

  const negativeDescendentStructure = getDescendentMaterialNodeStructure(
    recoveryGoalNodeDto.negativeMaterialNode,
    recoveryNode.id,
    variant,
  );

  const positiveDescendentStructure = getDescendentMaterialNodeStructure(
    recoveryGoalNodeDto.positiveMaterialNode,
    recoveryNode.id,
    variant,
  );

  return {
    nodes: [
      recoveryNode,
      ...negativeDescendentStructure.nodes,
      ...positiveDescendentStructure.nodes,
    ],
    edges: [
      ...incomingEdge,
      ...negativeDescendentStructure.edges,
      ...positiveDescendentStructure.edges,
    ],
  };
}

const recoveryTreeNodeTypeComponentMap = {
  recovery: RecoveryTreeDiagramRecoveryNode,
  material: RecoveryTreeDiagramMaterialNode,
};

const layoutArgs = {
  layoutOptions: {
    'elk.algorithm': 'mrtree',
    'elk.spacing.nodeNode': '50',
    'elk.layered.spacing.nodeNodeBetweenLayers': '30',
  },
};

type RecoveryTreeDiagramProps = {
  rootNode: RecoveryTreeNodeDTO;
  onNodeSelect?: (node: RecoveryTreeNode | null) => void;
  onEdgeSelect?: (edge: RecoveryTreeEdge | null) => void;
  nodeVariant: RecoveryTreeNodeVariant;
};
export function RecoveryTreeDiagram(props: RecoveryTreeDiagramProps) {
  const { rootNode, onNodeSelect, onEdgeSelect, nodeVariant } = props;
  const { nodes: allNodes, edges: allEdges } = match(rootNode)
    .with({ type: 'material' }, (materialNodeDto) =>
      getDescendentMaterialNodeStructure(materialNodeDto, null, nodeVariant),
    )
    .with({ type: 'recovery' }, (recoveryNodeDto) =>
      getDescendentRecoveryGoalNodeStructure(
        recoveryNodeDto,
        null,
        nodeVariant,
      ),
    )
    .exhaustive();

  const onChange = useCallback<
    ({
      nodes,
      edges,
    }: {
      nodes: RecoveryTreeNode[];
      edges: RecoveryTreeEdge[];
    }) => void
  >(
    ({ nodes, edges }) => {
      if (nodes.length > 0) {
        const selectedNode = nodes[0];
        onNodeSelect?.(selectedNode);
      } else {
        onNodeSelect?.(null);
      }
      if (edges.length > 0) {
        const selectedEdge = edges[0];
        onEdgeSelect?.(selectedEdge);
      } else {
        onEdgeSelect?.(null);
      }
    },
    [onEdgeSelect, onNodeSelect],
  );

  useOnSelectionChange({
    onChange,
  });

  return (
    <ElkReactFlow
      id={`recovery-tree-${rootNode.index}`}
      style={{ height: '100%', width: '100%', minHeight: 250 }}
      nodes={allNodes}
      edges={allEdges}
      layoutArgs={layoutArgs}
      nodeTypes={recoveryTreeNodeTypeComponentMap}
      elementsSelectable
      nodesConnectable={false}
      edgesFocusable={false}
    />
  );
}
