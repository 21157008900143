import { Loader, Select, SelectProps } from '@mantine/core';
import { Dayjs } from 'dayjs';
import { useTruckLoads } from '../api/truckLoad';

export interface TruckSelectSpecificProps {
  timestamp: Dayjs | undefined;
  value: string | null;
  onChange: (truckLoadId: string | null) => void;
}

export type TruckLoadSelectProps = Omit<
  SelectProps,
  keyof TruckSelectSpecificProps | 'data' | 'rightSection'
> &
  TruckSelectSpecificProps;

export function TruckLoadSelect(props: TruckLoadSelectProps) {
  const {
    value,
    onChange,
    disabled = false,
    searchable = true,
    nothingFound = 'No truck loads found',
    placeholder = 'Select truck load',
    ...selectProps
  } = props;

  // TODO(2335): Show material data at given timestamp (in props)

  const truckLoadsQuery = useTruckLoads();

  if (truckLoadsQuery.isLoadingError) {
    throw truckLoadsQuery.error;
  }

  return (
    <Select
      data={
        truckLoadsQuery.data?.map((truckLoad) => ({
          value: truckLoad.id,
          label: truckLoad.name,
        })) ?? []
      }
      value={value}
      onChange={onChange}
      searchable={searchable}
      nothingFound={nothingFound}
      disabled={truckLoadsQuery.isLoading || disabled}
      rightSection={
        truckLoadsQuery.isFetching ? <Loader size='xs' /> : undefined
      }
      placeholder={truckLoadsQuery.data ? placeholder : 'Loading...'}
      {...selectProps}
    />
  );
}
