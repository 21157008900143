import {
  ActionIcon,
  Group,
  Modal,
  ScrollArea,
  SegmentedControl,
  Slider,
  Stack,
  Switch,
  Text,
} from '@mantine/core';
import { useDebouncedState, useDisclosure } from '@mantine/hooks';
import { IconMaximize } from '@tabler/icons-react';
import * as d3 from 'd3';
import dayjs from 'dayjs';
import { Hsluv } from 'hsluv';
import { useCallback, useMemo, useState } from 'react';
import { match } from 'ts-pattern';
import { useFacilityContext } from '../Facility/FacilityContext';
import { SEGMENTATION_ENABLED } from '../Sample/flags';
import NetWeight from '../Weights/NetWeight';
import { LabeledValue } from '../common';
import {
  SamplingSuiteCaptureDTO,
  SamplingSuiteCaptureSegmentationMaskDTO,
} from '../rest-client';
import { useHighResCaptureObjectUrl } from '../samplingSuiteCaptureImageCache';
import { ParticleSizeDistributionChart } from './ParticleSizeDistributionChart';
import cssClasses from './SamplingSuiteCapture.module.css';
import { SvgRunLengthEncodedMask } from './SvgRunLengthEncodedMask';

const PSD_MODES = {
  minFeret: 'Min Diameter',
  maxFeret: 'Max Diameter',
} as const;
type PsdMode = keyof typeof PSD_MODES;

export function SamplingSuiteCaptureThumbnail(props: {
  capture: SamplingSuiteCaptureDTO;
}) {
  const { capture } = props;
  const [opened, { open, close }] = useDisclosure(false);
  const { timeZoneId: tz } = useFacilityContext();

  const [showMasks, setShowMasks] = useState(false);
  const [maskOpacity, setMaskOpacity] = useDebouncedState<number>(35, 100);

  const [psdMode, setPsdMode] = useState<PsdMode>('maxFeret');
  const psdAccessor = useMemo(
    () =>
      match(psdMode)
        .with(
          'maxFeret',
          () => (m: SamplingSuiteCaptureSegmentationMaskDTO) =>
            m.maxFeretDiameterInches,
        )
        .with(
          'minFeret',
          () => (m: SamplingSuiteCaptureSegmentationMaskDTO) =>
            m.minFeretDiameterInches,
        )
        .exhaustive(),

    [psdMode],
  );

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        size='calc(100vw - 3rem)'
        scrollAreaComponent={ScrollArea.Autosize}
        closeButtonProps={{ size: 'lg' }}
      >
        <Group align='stretch' noWrap w='100%'>
          <FullResSamplingSuiteCaptureImage
            style={{
              maxHeight: 'calc(100vh - 20vh)',
            }}
            capture={capture}
            showSegmentation={showMasks}
            onClick={() => open()}
            maskOpacity={maskOpacity / 100}
            zoomable
          />
          <Stack className={cssClasses.imgMeta}>
            <LabeledValue label='VALI-Sample Instance'>
              {capture.suiteName}
            </LabeledValue>

            <LabeledValue label='Taken At'>
              {dayjs.utc(capture.captureInstant).tz(tz).format('LLLL')}
            </LabeledValue>

            <LabeledValue label='Weight'>
              <NetWeight
                weight={capture.netWeight}
                sourceIconMode='icon-tooltip'
              />
            </LabeledValue>

            {SEGMENTATION_ENABLED ? (
              <>
                <LabeledValue label='Segmentation Status'>
                  {match(capture.segmentation)
                    .with({ status: 'pending' }, () => 'Pending...')
                    .with({ status: 'failed' }, () => (
                      <Text color='red' weight='bold'>
                        Failed
                      </Text>
                    ))
                    .with({ status: 'complete' }, () => (
                      <Stack w='100%' spacing='xs'>
                        <Text color='green' weight='bold'>
                          Complete
                        </Text>
                        <Group>
                          <Switch
                            label='Show Particle Masks'
                            checked={showMasks}
                            color='indigo'
                            onChange={(e) =>
                              setShowMasks(e.currentTarget.checked)
                            }
                          />
                          <div>
                            <Text size='sm'>Mask Opacity</Text>
                            <Slider
                              disabled={!showMasks}
                              color='indigo'
                              defaultValue={35}
                              onChange={setMaskOpacity}
                              miw={150}
                              maw={200}
                            />
                          </div>
                        </Group>
                      </Stack>
                    ))
                    .exhaustive()}
                </LabeledValue>

                {capture.segmentation.status === 'complete' && (
                  <LabeledValue label='Material Size Distribution'>
                    <SegmentedControl
                      value={psdMode}
                      onChange={(m) => setPsdMode(m as PsdMode)}
                      data={Object.entries(PSD_MODES).map(([mode, name]) => ({
                        value: mode,
                        label: name,
                      }))}
                    />
                    <ParticleSizeDistributionChart
                      h={350}
                      w='100%'
                      sizes={capture.segmentation.masks.map(psdAccessor)}
                      binWidthInches={0.25}
                    />
                  </LabeledValue>
                )}
              </>
            ) : null}
          </Stack>
        </Group>
      </Modal>
      <Group noWrap spacing={0} align='stretch' position='center' w='100%'>
        <div>
          <ActionIcon
            variant='outline'
            h='100%'
            radius='md'
            onClick={() => open()}
            className={cssClasses.maximizeButton}
          >
            <IconMaximize size='1.5rem' />
          </ActionIcon>
        </div>
        <FullResSamplingSuiteCaptureImage
          style={{ maxHeight: '150px', minHeight: '20px', cursor: 'pointer' }}
          capture={capture}
          showSegmentation={false}
          onClick={() => open()}
        />
      </Group>
    </>
  );
}

export type FullResSamplingSuiteCaptureImageProps = {
  capture: SamplingSuiteCaptureDTO;
  showSegmentation: boolean;
  maskOpacity?: number;
  zoomable?: boolean;
} & Omit<React.SVGProps<SVGSVGElement>, 'viewBox'>;

export function FullResSamplingSuiteCaptureImage(
  props: FullResSamplingSuiteCaptureImageProps,
) {
  const {
    capture,
    showSegmentation,
    maskOpacity = 0.25,
    zoomable = false,
    ...svgProps
  } = props;

  const effectiveMaskOpacity = showSegmentation ? maskOpacity : 0;

  const imageObjectUrl = useHighResCaptureObjectUrl(capture.captureId);

  const rawHeightPx = 3672;
  const rawWidthPx = 5496;

  const [zoomTransform, setZoomTransform] = useState<d3.ZoomTransform | null>(
    null,
  );

  const setupZoom = useCallback((svg: SVGElement | null) => {
    if (svg) {
      const svgElement = d3.select(svg);
      const zoom = d3
        .zoom<SVGElement, unknown>()
        .scaleExtent([1, 10])
        .translateExtent([
          [0, 0],
          [rawWidthPx, rawHeightPx],
        ])
        .on('zoom', ({ transform }: d3.D3ZoomEvent<SVGElement, unknown>) =>
          setZoomTransform(transform),
        );
      svgElement.call(zoom);
    } else {
      // TODO(2334): Cleanup?
    }
  }, []);

  const completeSegmentation =
    capture.segmentation.status === 'complete'
      ? capture.segmentation
      : undefined;

  // TODO(2421): - Refactor to use `filter: hue-rotate()` in CSS
  const masks = completeSegmentation?.masks;
  const colors = masks?.map((_, i) => {
    const normalColor = new Hsluv();
    normalColor.hsluv_h = 360 * (i / masks.length);
    normalColor.hsluv_s = 80;
    normalColor.hsluv_l = 60;
    normalColor.hsluvToHex();

    const hoverColor = new Hsluv();
    hoverColor.hsluv_h = normalColor.hsluv_h;
    hoverColor.hsluv_s = normalColor.hsluv_s;
    hoverColor.hsluv_l = 80;
    hoverColor.hsluvToHex();

    return [normalColor.hex, hoverColor.hex] as const;
  });

  // TODO(2334): Shuffle colors / memo colors

  const maskElements = masks?.map((m, i) => {
    if (colors === undefined) throw new Error();
    const [normalColor, hoverColor] = colors[i];
    return (
      <g key={m.id}>
        <SvgRunLengthEncodedMask
          mask={m.runLengthEncodedMask}
          color={normalColor}
          hoverColor={hoverColor}
          opacity={effectiveMaskOpacity}
          imageWidth={3672 * (completeSegmentation?.scalingFactor ?? 0)}
        />
        {/* TODO(2334): Include lines for min/max feret diameter on hover */}
        {/* TODO(2334): Lighter on hover */}
      </g>
    );
  });

  return (
    <svg
      ref={setupZoom}
      viewBox={`0 0 ${rawWidthPx} ${rawHeightPx}`}
      cursor={zoomable ? 'grab' : undefined}
      {...svgProps}
    >
      {imageObjectUrl ? (
        <g
          transform={
            zoomTransform && zoomable
              ? `translate(${zoomTransform.x}, ${zoomTransform.y}) scale(${zoomTransform.k})`
              : undefined
          }
        >
          <image
            href={imageObjectUrl}
            width={rawWidthPx}
            height={rawHeightPx}
          />

          {completeSegmentation ? (
            <g
              transform={`scale(${1 / completeSegmentation.scalingFactor},${
                1 / completeSegmentation.scalingFactor
              }) scale(1, -1) rotate(-90)`}
            >
              {maskElements}
            </g>
          ) : null}
        </g>
      ) : (
        <g>
          <rect
            width={rawWidthPx}
            height={rawHeightPx}
            style={{ fill: 'url(#fill)' }}
          />
        </g>
      )}

      <defs>
        <linearGradient id='fill'>
          <stop offset='0.599964' stopColor='#f3f3f3' stopOpacity='1'>
            <animate
              attributeName='offset'
              values='-2; -2; 1'
              keyTimes='0; 0.25; 1'
              dur='2s'
              repeatCount='indefinite'
            ></animate>
          </stop>
          <stop offset='1.59996' stopColor='#ecebeb' stopOpacity='1'>
            <animate
              attributeName='offset'
              values='-1; -1; 2'
              keyTimes='0; 0.25; 1'
              dur='2s'
              repeatCount='indefinite'
            ></animate>
          </stop>
          <stop offset='2.59996' stopColor='#f3f3f3' stopOpacity='1'>
            <animate
              attributeName='offset'
              values='0; 0; 3'
              keyTimes='0; 0.25; 1'
              dur='2s'
              repeatCount='indefinite'
            ></animate>
          </stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
