import {
  Button,
  Flex,
  NumberInput,
  Paper,
  Skeleton,
  Stack,
} from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';
import { useState } from 'react';
import { ReactFlowProvider } from 'reactflow';
import { usePatchRecoveryStrategySimulation } from '../api/recoveryStrategySimulation';
import { CancelIcon, SaveIcon } from '../Icons';
import {
  RecoveryTreeDiagram,
  RecoveryTreeNodeVariant,
} from '../RecoveryTree/RecoveryTreeGraph';
import {
  CommoditySpreadMaterialNodeDTO,
  SimulationRecoveryPathPriceAssignmentItemPatchDTO,
  WeightUnit,
} from '../rest-client';
import { useRecoveryStrategySimulationCtx } from './RecoveryStrategySimulationContext';

export type RecoveryStrategySimulationTreeProps = {
  rootMaterialNode: CommoditySpreadMaterialNodeDTO;
  simulationId: string;
  editing: boolean;
  setEditing: (value: boolean) => void;
};

export function RecoveryStrategySimulationTree(
  props: RecoveryStrategySimulationTreeProps,
) {
  const { rootMaterialNode, simulationId, editing, setEditing } = props;
  const { feedTotal, setFeedTotal } = useRecoveryStrategySimulationCtx();
  const [simulationPriceAssignments, setSimulationPriceAssignments] = useState<
    SimulationRecoveryPathPriceAssignmentItemPatchDTO[]
  >([]);
  const [feedstockMass, setFeedstockMass] = useState<number | undefined>(
    undefined,
  );
  const [feedstockMassUsdPerUnit, setFeedstockMassUsdPerUnit] = useState<
    number | undefined
  >(undefined);

  const patchRecoverySimulationMutation = usePatchRecoveryStrategySimulation();

  const mutate = () => {
    patchRecoverySimulationMutation.mutate(
      {
        simulationId: simulationId,
        patch: {
          feedstockParameters: {
            feedstockMass: feedstockMass,
            feedstockMassUsdPerUnit: feedstockMassUsdPerUnit,
            feedstockMassUnit: WeightUnit.POUND,
          },
          simulationRecoveryPathPriceAssignments: simulationPriceAssignments,
        },
      },
      {
        onSuccess() {
          setEditing(false);
          setFeedTotal(feedstockMass ?? feedTotal);
          patchRecoverySimulationMutation.reset();
        },
      },
    );
  };

  if (patchRecoverySimulationMutation.isError) {
    return (
      <Button
        color='red'
        onClick={() => patchRecoverySimulationMutation.reset()}
      >
        <IconRefresh /> Error - Reset
      </Button>
    );
  }

  return (
    <Stack>
      {editing && (
        <Stack>
          <Flex justify={'start'} align='center' w='50%' gap='lg'>
            <Button
              leftIcon={<CancelIcon />}
              style={{ cursor: 'pointer' }}
              variant='outline'
              color='red'
              onClick={() => {
                setEditing(false);
                setFeedstockMass(undefined);
                setFeedstockMassUsdPerUnit(undefined);
                setSimulationPriceAssignments([]);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ cursor: 'pointer' }}
              onClick={() => mutate()}
              leftIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Flex>
          <Flex justify={'start'} align={'center'} gap='lg'>
            <NumberInput
              precision={3}
              placeholder={rootMaterialNode.mass.toLocaleString()}
              onChange={(e) =>
                setFeedstockMass(e === '' || e < 0 ? undefined : e)
              }
              label='Feedstock Mass'
              size='xs'
            />
            <NumberInput
              precision={3}
              placeholder={rootMaterialNode.valuePerWeightUnit?.toLocaleString()}
              onChange={(e) =>
                setFeedstockMassUsdPerUnit(e === '' ? undefined : e)
              }
              label='Feedstock Unit Value'
              size='xs'
            />
          </Flex>
        </Stack>
      )}
      <Paper withBorder p='xs' w='100%' h={500}>
        <ReactFlowProvider>
          <Skeleton
            visible={patchRecoverySimulationMutation.isLoading}
            height='100%'
            width='100%'
          >
            <RecoveryTreeDiagram
              rootNode={{ ...rootMaterialNode, type: 'material' }}
              nodeVariant={RecoveryTreeNodeVariant.commoditySpread}
            />
          </Skeleton>
        </ReactFlowProvider>
      </Paper>
    </Stack>
  );
}

// type EditableRecoveryGoalTreeProps = {
//   editing: boolean;
//   setPriceAssignments: React.Dispatch<
//     React.SetStateAction<SimulationRecoveryPathPriceAssignmentItemPatchDTO[]>
//   >;
//   rootMaterialNode: CommoditySpreadMaterialNodeDTO;
//   spreadMode: boolean;
// };

// interface RecoveryGoalSimulationSpreadWidgetProps {
//   recoveryGoalNode: CommoditySpreadRecoveryGoalNodeDTO;
//   spreadMode: boolean;
// }

// interface MaterialNodeSpreadWidgetProps {
//   materialNode: CommoditySpreadMaterialNodeDTO;
//   parentRecoveryGoalId: RecoveryGoalId | null;
//   editing: boolean;
//   setPriceAssignments: React.Dispatch<
//     React.SetStateAction<SimulationRecoveryPathPriceAssignmentItemPatchDTO[]>
//   >;
// }

// interface SpreadInfoProps {
//   info: RecoveryGoalTreeSpreadInformationDTO;
//   positiveChildInfo: RecoveryGoalTreeChildInformationDTO;
//   negativeChildInfo: RecoveryGoalTreeChildInformationDTO;
// }

// function SpreadInfoWidget(props: SpreadInfoProps) {
//   const { info, positiveChildInfo, negativeChildInfo } = props;

//   const getInfoMessage = (
//     info: RecoveryGoalTreeSpreadInformationDTO,
//     pos: RecoveryGoalTreeChildInformationDTO,
//     neg: RecoveryGoalTreeChildInformationDTO,
//   ) => {
//     if (info.totalGlobalSpread && !info.totalLocalSpread) {
//       const getSubmessage = (
//         pos: RecoveryGoalTreeChildInformationDTO,
//         neg: RecoveryGoalTreeChildInformationDTO,
//       ) => {
//         if (pos.pricePerUnit === undefined && neg.pricePerUnit === undefined) {
//           return 'both child nodes';
//         } else if (pos.pricePerUnit !== undefined) {
//           return 'the negative child node';
//         } else if (pos.pricePerUnit === undefined) {
//           return 'the positive child node';
//         } else {
//           return '';
//         }
//       };
//       const submessage = getSubmessage(pos, neg);

//       return `Cannot calculate local spread. Add a price to ${submessage}.`;
//     }
//     if (!info.totalGlobalSpread && info.totalLocalSpread) {
//       const problemInChildrenMessage =
//         'Ensure that prices are propogated throughout the tree.';
//       const problemInSelfMessage =
//         'There is no price information of the input at the current level.';
//       return `Cannot calculate global spread. ${info.unitPriceAtLevel === undefined ? problemInSelfMessage : problemInChildrenMessage}`;
//     } else if (!info.totalGlobalSpread && !info.totalLocalSpread) {
//       return 'Cannot calculate global or local spread. Assign prices throughout the tree to see spread values.';
//     } else {
//       return 'Global spread is the spread across the entire tree. Local spread is the spread across direct children.';
//     }
//   };

//   const infoMessage = getInfoMessage(
//     info,
//     positiveChildInfo,
//     negativeChildInfo,
//   );
//   return (
//     <Flex justify='center' align='center'>
//       <Tooltip label={infoMessage}>
//         <IconInfoCircle color='gray' />
//       </Tooltip>
//     </Flex>
//   );
// }
