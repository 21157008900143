import { match } from 'ts-pattern';
import { ProcessTopologyEdge } from '../../Process/ProcessTopologyDiagram.tsx';
import Temporal from '../../Temporal/temporal.ts';
import { ProcessDTO } from '../../rest-client';
import { FeedstockEdgeMetrics } from './FeedstockEdgeMetrics';
import { FlowEdgeMetrics } from './FlowEdgeMetrics';
import { OutputPortEdgeMetrics } from './OutputPortEdgeMetrics';

export function ProcessEdgeMetrics({
  edge,
  process,
  startTime,
  endTime,
}: {
  edge: ProcessTopologyEdge;
  process: ProcessDTO;
  startTime: Temporal.Instant;
  endTime: Temporal.Instant;
}) {
  return match(edge.data)
    .with({ type: 'feedstock' }, () => (
      <FeedstockEdgeMetrics
        process={process}
        startTime={startTime}
        endTime={endTime}
      />
    ))
    .with({ type: 'flow' }, ({ flow }) => (
      <FlowEdgeMetrics flow={flow} startTime={startTime} endTime={endTime} />
    ))
    .with({ type: 'output' }, ({ outputPort }) => (
      <OutputPortEdgeMetrics
        outputPort={outputPort}
        startTime={startTime}
        endTime={endTime}
      />
    ))
    .with(undefined, () => {
      // this should never happen because the process edges should not be undefined, but edges.d.ts says says otherwise
      throw new Error();
    })
    .exhaustive();
}
