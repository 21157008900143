import { Group } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { FormActionButton } from '../../Form/FormActionButton';
import { MutationErrorAlert } from '../../Form/MutationErrorAlert';
import { useFormNavBlocker } from '../../Form/useFormNavBlocker';
import ScaleDisplayDivisionsInput from '../../Weights/ScaleDisplayDivisionsInput';
import { usePatchManualSampleAnalysis } from '../../api/manualSampleAnalysis';
import {
  ManualSampleAnalysisDTO,
  ManualSampleAnalysisPatchDTO,
} from '../../rest-client';
import {
  ManualSampleAnalysisFormValues,
  useManualSampleAnalysisForm,
} from './ManualSampleAnalysisForm';
import { ManualSampleAnalysisMaterialClassTable } from './ManualSampleAnalysisMaterialClassTable';

export interface ManualSampleAnalysisEditFormProps {
  analysis: ManualSampleAnalysisDTO;
  onClose?: () => void;
}

export function EditManualSampleAnalysisForm(
  props: ManualSampleAnalysisEditFormProps,
) {
  const { analysis, onClose } = props;
  const patchMutation = usePatchManualSampleAnalysis();

  const measurementMap = new Map(
    Object.entries(analysis.materialClassMeasurements),
  );

  const initialValues: ManualSampleAnalysisFormValues = {
    specifiedTotalScaleDisplayDivisions:
      analysis.specifiedTotalScaleDisplayDivisions,
    materialClasses: analysis.materialClassSet.materialClasses.map((m) => ({
      materialClassId: m.id,
      netWeightScaleDisplayDivisions:
        measurementMap.get(m.id)?.weight.ticks ?? null,
    })),
  };

  const analysisForm = useFormNavBlocker(
    useManualSampleAnalysisForm(analysis.materialClassSet, initialValues),
  );

  if (patchMutation.isError) {
    return (
      <MutationErrorAlert
        errorTitle='Error Editing Manual Sample Analysis'
        entityName='Sample Analysis'
        mutation={patchMutation}
        formVariant='edit'
      />
    );
  }
  return (
    <form
      style={{ width: '100%' }}
      onSubmit={analysisForm.onSubmit((values) => {
        const patch: ManualSampleAnalysisPatchDTO = {
          ...(analysisForm.isDirty('specifiedTotalScaleDisplayDivisions') && {
            scaleDisplayDivisions: values.specifiedTotalScaleDisplayDivisions,
          }),
          materialClassMeasurements: values.materialClasses
            .filter((mcm) => mcm.netWeightScaleDisplayDivisions !== null)
            .map(({ materialClassId, netWeightScaleDisplayDivisions }) => {
              if (netWeightScaleDisplayDivisions === null) throw new Error();
              return {
                materialClassId,
                scaleDisplayDivisions: netWeightScaleDisplayDivisions,
                particleCount: null,
              };
            }),
        };

        patchMutation.mutate(
          { id: analysis.id, patch },
          {
            onError() {
              showNotification({
                title: 'Error saving changes to analysis',
                message:
                  'An error occurred saving the changes to this analysis.',
                color: 'red',
                icon: <IconX />,
              });
            },
            onSuccess() {
              showNotification({
                title: 'Analysis Saved',
                message: 'The analysis has been saved',
                color: 'green',
                icon: <IconCheck />,
              });
              onClose?.();
              patchMutation.reset();
            },
          },
        );
      })}
    >
      <ScaleDisplayDivisionsInput
        label='Total Sample Weight'
        maw='20ch'
        mb='sm'
        placeholder='Total Weight'
        hideControls
        scaleId={analysis.scale.id}
        {...analysisForm.getInputProps('specifiedTotalScaleDisplayDivisions')}
      />

      <ManualSampleAnalysisMaterialClassTable
        form={analysisForm}
        materialClassSet={analysis.materialClassSet}
        scaleId={analysis.scale.id}
      />
      <Group mt='sm' position='right'>
        <FormActionButton
          action='cancel'
          onClick={onClose}
          loading={patchMutation.isLoading}
        />
        <FormActionButton
          type='submit'
          action='saveEdits'
          loading={patchMutation.isLoading}
        >
          Save Sample Analysis
        </FormActionButton>
      </Group>
    </form>
  );
}
