import { useDisclosure } from '@mantine/hooks';
import { FormActionButton } from '../Form/FormActionButton';
import { EditScaleReadingDrawerForm } from '../ScaleReading/EditScaleReadingDrawerForm';
import { ScaleReadingId } from '../rest-client';

export interface EditScaleReadingButtonProps {
  scaleReadingId: ScaleReadingId;
}

export function EditScaleReadingButton(props: EditScaleReadingButtonProps) {
  const { scaleReadingId } = props;

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <>
      <EditScaleReadingDrawerForm
        opened={drawerOpened}
        onClose={closeDrawer}
        scaleReadingId={scaleReadingId}
      />

      <FormActionButton
        action='edit'
        size='sm'
        compact
        variant='outline'
        onClick={openDrawer}
      />
    </>
  );
}
