import { Flex, Tooltip } from '@mantine/core';
import { IconBrain, IconCalculator, IconScale } from '@tabler/icons-react';
import { P, match } from 'ts-pattern';
import { NetWeightDTO, WeightUnit } from '../rest-client';
import { WithUnit } from '../util/WithUnit';
import WeightUnitExpectedTotals from './WeightUnitExpectedTotals';
import { getWeightUnitAbbreviation } from './units';

export type NetWeightSourceIconMode = 'none' | 'icon-only' | 'icon-tooltip';

export interface NetWeightProps {
  weight: NetWeightDTO;
  sourceIconMode?: NetWeightSourceIconMode;
}
export default function NetWeight(props: NetWeightProps) {
  const { weight, sourceIconMode: netWeightSourceIconMode = 'none' } = props;

  return (
    <Flex justify='flex-end' align='center' gap={2}>
      <WeightUnitExpectedTotals
        expectedUnitTotals={weight.unitExpectedTotals}
        totalIsNegative={weight.totalIsNegative}
      />
      {(netWeightSourceIconMode === 'icon-only' ||
        netWeightSourceIconMode === 'icon-tooltip') && (
        <NetWeightKindIcon
          weight={weight}
          onHover={netWeightSourceIconMode === 'icon-tooltip'}
        />
      )}
    </Flex>
  );
}

export function NetWeightZero(props: { unit: WeightUnit }) {
  const { unit } = props;
  return <WithUnit unitSuffix={getWeightUnitAbbreviation(unit)}>{0}</WithUnit>;
}

export interface NetWeightKindIconProps {
  weight: NetWeightDTO;
  onHover: boolean;
}

function NetWeightKindIcon(props: NetWeightKindIconProps) {
  const { weight, onHover = true } = props;

  // const [opened, { open, close }] = useDisclosure(false);

  if (weight.kind === 'zero') {
    return undefined;
  }

  const iconProps = {
    size: 15,
    color: 'gray',
  };

  const icon = match(weight)
    .with({ kind: 'tared' }, () => <IconScale {...iconProps} />)
    .with({ kind: P.union('diff', 'sum') }, () => (
      <IconCalculator {...iconProps} />
    ))
    .with({ kind: 'canonical' }, ({ weightSource }) =>
      match(weightSource.kind)
        .with('red-wave-normal-estimate', () => <IconBrain {...iconProps} />)
        .with('multiplied', 'negated', () => <IconCalculator {...iconProps} />)
        .with(
          'manual-sample-analysis-material-class-measurement',
          'manual-sample-analysis-total',
          'container-scale-reading-association',
          'internal-sink-container-transfer-net-weight',
          'internally-sourced-material',
          'material-container-override-tare',
          'material-container-type-default-tare',
          'purchased-material',
          'truck-load-scale-reading-association',
          'sampling-suite-capture-gross',
          'sampling-suite-capture-tare',
          () => <IconScale {...iconProps} />,
        )
        .exhaustive(),
    )
    .exhaustive();

  // const modal = (
  //   <Modal
  //     opened={opened}
  //     onClose={close}
  //     title='Mass Inference Explanation'
  //     size='100%'
  //   >
  //     <Group noWrap align='flex-start'>
  //       {weight.signedWeightSources.map(
  //         ({ positive, weightSource, expectedPounds }, i) => (
  //           <div
  //             key={i}
  //             style={{
  //               display: 'inline-flex',
  //               flexDirection: 'column',
  //             }}
  //           >
  //             <Text>
  //               {positive ? (expectedPounds <= 0 ? '' : '+') : '-'}
  //               {expectedPounds.toFixed(1)}
  //             </Text>
  //             <Text></Text>
  //             {weightSource.kind}
  //           </div>
  //         ),
  //       )}
  //     </Group>
  //   </Modal>
  // );

  // TODO(1349): polish modal and enable via action icon
  // const actionIcon = (
  //   <Fragment>
  //     <ActionIcon onClick={() => open()}>{icon}</ActionIcon>
  //     {modal}
  //   </Fragment>
  // );

  if (onHover) {
    const label = match(weight)
      .with({ kind: 'tared' }, () => 'tared scale recorded weight')
      .with({ kind: 'diff' }, () => 'subtraction computed weight')
      .with({ kind: 'sum' }, () => 'addition computed weight')
      .with({ kind: 'canonical' }, ({ weightSource }) =>
        match(weightSource.kind)
          .with(
            'red-wave-normal-estimate',
            () => 'predicted weight from redwave data',
          )
          .with('multiplied', () => 'multiplication computed weight')
          .with('negated', () => 'subtraction computed weight')
          .with(
            'manual-sample-analysis-material-class-measurement',
            () => 'manual sample analysis material class weight',
          )
          .with(
            'manual-sample-analysis-total',
            () => 'manual sample analysis total weight',
          )
          .with(
            'container-scale-reading-association',
            () => 'container scale recorded weight',
          )
          .with(
            'internal-sink-container-transfer-net-weight',
            () => 'material export recorded weight',
          )
          .with(
            'internally-sourced-material',
            () => 'sourced material recorded weight',
          )
          .with(
            'material-container-override-tare',
            () => 'tared scale recorded weight',
          )
          .with(
            'material-container-type-default-tare',
            () => 'tared scale recorded weight',
          )
          .with(
            'purchased-material',
            () => 'purchased material recorded weight',
          )
          .with(
            'truck-load-scale-reading-association',
            () => 'truck load scale recorded weight',
          )
          .with(
            'sampling-suite-capture-gross',
            () => 'VALI-Sample recorded gross weight',
          )
          .with(
            'sampling-suite-capture-tare',
            () => 'VALI-Sample recored tare weight',
          )
          .exhaustive(),
      )
      .exhaustive();
    // return actionIcon;
    return <Tooltip label={label}>{icon}</Tooltip>;
  } else {
    // return actionIcon;
    return icon;
  }
}
