/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompleteSamplingSuiteCaptureSegmentationDTO } from '../models/CompleteSamplingSuiteCaptureSegmentationDTO';
import type { FailedSamplingSuiteCaptureSegmentationDTO } from '../models/FailedSamplingSuiteCaptureSegmentationDTO';
import type { PendingSamplingSuiteCaptureSegmentationDTO } from '../models/PendingSamplingSuiteCaptureSegmentationDTO';
import type { SamplingSessionCloseDTO } from '../models/SamplingSessionCloseDTO';
import type { SamplingSuiteCaptureDTO } from '../models/SamplingSuiteCaptureDTO';
import type { SamplingSuiteCapturePatchDTO } from '../models/SamplingSuiteCapturePatchDTO';
import type { SamplingSuiteCreateSessionDTO } from '../models/SamplingSuiteCreateSessionDTO';
import type { SamplingSuiteDTO } from '../models/SamplingSuiteDTO';
import type { SamplingSuitePatchSessionDTO } from '../models/SamplingSuitePatchSessionDTO';
import type { SamplingSuiteSessionDTO } from '../models/SamplingSuiteSessionDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SamplingSuiteService {
    /**
     * @returns SamplingSuiteDTO Success
     * @throws ApiError
     */
    public static getSamplingSuites(): CancelablePromise<Array<SamplingSuiteDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sampling-suites',
        });
    }
    /**
     * @param suiteId
     * @param unlinked
     * @param archived
     * @returns SamplingSuiteCaptureDTO Success
     * @throws ApiError
     */
    public static getSamplingSuiteCaptures(
        suiteId?: string,
        unlinked?: boolean,
        archived?: boolean,
    ): CancelablePromise<Array<SamplingSuiteCaptureDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sampling-suites/captures',
            query: {
                'suiteId': suiteId,
                'unlinked': unlinked,
                'archived': archived,
            },
        });
    }
    /**
     * @param suiteGuid
     * @param formData
     * @returns any Success
     * @throws ApiError
     */
    public static uploadCapturePreview(
        suiteGuid: string,
        formData?: {
            Json?: string;
            File?: Blob;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sampling-suites/{suiteGuid}/captures/preview',
            path: {
                'suiteGuid': suiteGuid,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param suiteGuid
     * @returns any Success
     * @throws ApiError
     */
    public static uploadRawImage(
        suiteGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sampling-suites/{suiteGuid}/captures/raw',
            path: {
                'suiteGuid': suiteGuid,
            },
        });
    }
    /**
     * @param captureGuid
     * @returns any Success
     * @throws ApiError
     */
    public static getCapturePreview(
        captureGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sampling-suites/captures/{captureGuid}/preview',
            path: {
                'captureGuid': captureGuid,
            },
        });
    }
    /**
     * @param captureGuid
     * @returns any Success
     * @throws ApiError
     */
    public static getCaptureJpg(
        captureGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sampling-suites/captures/{captureGuid}/jpg',
            path: {
                'captureGuid': captureGuid,
            },
        });
    }
    /**
     * @param captureGuid
     * @returns any Success
     * @throws ApiError
     */
    public static getCapturePng(
        captureGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sampling-suites/captures/{captureGuid}/image',
            path: {
                'captureGuid': captureGuid,
            },
        });
    }
    /**
     * @param captureGuid
     * @returns SamplingSuiteCaptureDTO Success
     * @throws ApiError
     */
    public static getCapture(
        captureGuid: string,
    ): CancelablePromise<SamplingSuiteCaptureDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sampling-suites/captures/{captureGuid}',
            path: {
                'captureGuid': captureGuid,
            },
        });
    }
    /**
     * @param captureGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchCapture(
        captureGuid: string,
        requestBody: SamplingSuiteCapturePatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/sampling-suites/captures/{captureGuid}',
            path: {
                'captureGuid': captureGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param captureGuid
     * @returns any Success
     * @throws ApiError
     */
    public static getCaptureSegmentation(
        captureGuid: string,
    ): CancelablePromise<(PendingSamplingSuiteCaptureSegmentationDTO | FailedSamplingSuiteCaptureSegmentationDTO | CompleteSamplingSuiteCaptureSegmentationDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sampling-suites/captures/{captureGuid}/segmentation',
            path: {
                'captureGuid': captureGuid,
            },
        });
    }
    /**
     * @param samplingSuiteSampleAnalysisId
     * @param samplingSuiteId
     * @param owned
     * @param open
     * @returns SamplingSuiteSessionDTO Success
     * @throws ApiError
     */
    public static getSessions(
        samplingSuiteSampleAnalysisId?: string,
        samplingSuiteId?: string,
        owned?: boolean,
        open?: boolean,
    ): CancelablePromise<Array<SamplingSuiteSessionDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sampling-suites/sessions',
            query: {
                'samplingSuiteSampleAnalysisId': samplingSuiteSampleAnalysisId,
                'samplingSuiteId': samplingSuiteId,
                'owned': owned,
                'open': open,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createSession(
        requestBody?: SamplingSuiteCreateSessionDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sampling-suites/sessions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param sessionGuid
     * @returns SamplingSuiteSessionDTO Success
     * @throws ApiError
     */
    public static getSession(
        sessionGuid: string,
    ): CancelablePromise<SamplingSuiteSessionDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sampling-suites/sessions/{sessionGuid}',
            path: {
                'sessionGuid': sessionGuid,
            },
        });
    }
    /**
     * @param sessionGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static updateSession(
        sessionGuid: string,
        requestBody?: SamplingSuitePatchSessionDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/sampling-suites/sessions/{sessionGuid}',
            path: {
                'sessionGuid': sessionGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param sessionGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static closeSession(
        sessionGuid: string,
        requestBody: SamplingSessionCloseDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/sampling-suites/sessions/{sessionGuid}',
            path: {
                'sessionGuid': sessionGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param samplingSuiteGuid
     * @returns any Success
     * @throws ApiError
     */
    public static samplingSuiteStatusHeartbeat(
        samplingSuiteGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sampling-suites/{samplingSuiteGuid}/heartbeat',
            path: {
                'samplingSuiteGuid': samplingSuiteGuid,
            },
        });
    }
}
