import { Alert, Flex } from '@mantine/core';
import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import { useToggle } from '@mantine/hooks';
import { IconAlertTriangle } from '@tabler/icons-react';
import dayjs, { Dayjs } from 'dayjs';
import { z } from 'zod';
import ContainerSelect, {
  ContainerSelectVariant,
} from '../Container/ContainerSelect';
import { useFacilityContext } from '../Facility/FacilityContext';
import ContainerFullnessSwitch from '../Input/ContainerFullnessSwitch';
import { InvalidVisibilityToggle } from '../Input/InvalidVisibilityToggle';
import { ProcessSelect } from '../Process/ProcessSelect';
import { RequiredDayjsDateTimePicker } from '../Time/DayjsDateTimePicker';
import { MaterialContainerId, ProcessId } from '../rest-client';

const ProcessBufferTransferFormSchema = z
  .object({
    timestamp: z.instanceof(dayjs as unknown as typeof Dayjs, {
      message: 'Transfer time is required',
    }),
    sourceContainerId: z.string().uuid().nullable(),
    sourceEmptied: z.boolean(),
    processId: z.string().uuid().nullable(),
  })
  .refine(({ sourceContainerId }) => sourceContainerId !== null, {
    path: ['sourceContainerId'],
    message: 'Source container is required',
  })
  .refine(({ processId }) => processId !== null, {
    path: ['processId'],
    message: 'Process is required',
  });

export type ProcessBufferTransferFormValues = z.infer<
  typeof ProcessBufferTransferFormSchema
>;

export type ProcessBufferTransferFormProps = {
  sourceContainerId?: MaterialContainerId;
  processId?: ProcessId;
};

export function useProcessBufferTransferForm(
  props: ProcessBufferTransferFormProps,
) {
  const { sourceContainerId, processId } = props;
  return useForm<ProcessBufferTransferFormValues>({
    initialValues: {
      timestamp: dayjs.utc(),
      sourceContainerId: sourceContainerId ?? null,
      sourceEmptied: true,
      processId: processId ?? null,
    },
    validate: zodResolver(ProcessBufferTransferFormSchema),
  });
}

export function ProcessBufferTransferFormFields(props: {
  form: UseFormReturnType<ProcessBufferTransferFormValues>;
  containerSelectVariant: ContainerSelectVariant;
}) {
  const { form, containerSelectVariant = 'material' } = props;

  const facility = useFacilityContext();
  const [showEmptySources, toggleShowEmptySources] = useToggle();

  const sourceContainerSelectDisabled =
    form.values.sourceContainerId !== null &&
    !form.isDirty('sourceContainerId');
  const processSelectDisabled =
    form.values.processId !== null && !form.isDirty('processId');

  return (
    <>
      <RequiredDayjsDateTimePicker
        label='Transfer Time'
        description='When the material was considered transferred.'
        tz={facility.timeZoneId}
        withAsterisk
        {...form.getInputProps('timestamp')}
      />
      <ContainerSelect
        label='Source Container'
        description='Container material is being transferred out of.'
        variant={containerSelectVariant}
        timestamp={form.values.timestamp}
        facilityId={facility.id}
        hideEmpty={!showEmptySources}
        hideFull={false}
        withAsterisk
        clearable
        {...form.getInputProps('sourceContainerId')}
      />
      <Flex gap='md'>
        <ContainerFullnessSwitch
          inputWrapperProps={{
            label: 'Source Container Emptied',
            description: 'Was the container completely emptied?',
          }}
          variant='source'
          orientation='horizontal'
          size='lg'
          {...form.getInputProps('sourceEmptied', { type: 'checkbox' })}
        />

        {!sourceContainerSelectDisabled && (
          <InvalidVisibilityToggle
            labelVariant='top'
            label='Show Empty Containers'
            value={showEmptySources}
            onToggle={toggleShowEmptySources}
            actionIconProps={{
              style: { alignSelf: 'flex-end' },
            }}
            inputWrapperProps={{
              description:
                'Include empty containers in source container select?',
            }}
          />
        )}
      </Flex>

      <ProcessSelect
        label='Process'
        withAsterisk
        disabled={processSelectDisabled}
        {...form.getInputProps('processId')}
      />

      {showEmptySources ? (
        <Alert color='orange' icon={<IconAlertTriangle />} title=''>
          Creating a feedstock transfer from an empty source contaiener will
          result in an ledger error. You can still create the feedstock
          transfer, but the error will need to be corrected later.
        </Alert>
      ) : undefined}
    </>
  );
}
