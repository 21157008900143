/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SamplingSuiteSampleAnalysisCreationDTO } from '../models/SamplingSuiteSampleAnalysisCreationDTO';
import type { SamplingSuiteSampleAnalysisDTO } from '../models/SamplingSuiteSampleAnalysisDTO';
import type { SamplingSuiteSampleAnalysisPatchDTO } from '../models/SamplingSuiteSampleAnalysisPatchDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SamplingSuiteSampleAnalysisService {
    /**
     * @param sampleId
     * @returns SamplingSuiteSampleAnalysisDTO Success
     * @throws ApiError
     */
    public static getSamplingSuiteSampleAnalyses(
        sampleId?: string,
    ): CancelablePromise<Array<SamplingSuiteSampleAnalysisDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sample-analyses/sampling-suite',
            query: {
                'sampleId': sampleId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createSamplingSuiteSampleAnalysis(
        requestBody: SamplingSuiteSampleAnalysisCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sample-analyses/sampling-suite',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param sampleAnalysisGuid
     * @returns SamplingSuiteSampleAnalysisDTO Success
     * @throws ApiError
     */
    public static getSamplingSuiteSampleAnalysis(
        sampleAnalysisGuid: string,
    ): CancelablePromise<SamplingSuiteSampleAnalysisDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sample-analyses/sampling-suite/{sampleAnalysisGuid}',
            path: {
                'sampleAnalysisGuid': sampleAnalysisGuid,
            },
        });
    }
    /**
     * @param sampleAnalysisGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchSamplingSuiteSampleAnalysis(
        sampleAnalysisGuid: string,
        requestBody: SamplingSuiteSampleAnalysisPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/sample-analyses/sampling-suite/{sampleAnalysisGuid}',
            path: {
                'sampleAnalysisGuid': sampleAnalysisGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param sampleAnalysisGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteSamplingSuiteSampleAnalysis(
        sampleAnalysisGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/sample-analyses/sampling-suite/{sampleAnalysisGuid}',
            path: {
                'sampleAnalysisGuid': sampleAnalysisGuid,
            },
        });
    }
}
