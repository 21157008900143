import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import { useCallback } from 'react';
import {
  SamplingSessionCloseDTO,
  SamplingSuiteCreateSessionDTO,
  SamplingSuitePatchSessionDTO,
  SamplingSuiteService,
  SamplingSuiteSessionDTO,
} from '../rest-client';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { useSamplingSessionSignalR } from './samplingSessionSignalR';

async function fetchSamplingSuiteSessions(
  ctx: QueryFunctionContexts['samplingSession']['list'],
) {
  const [{ samplingSuiteSampleAnalysisId, samplingSuiteId, isOwned, isOpen }] =
    ctx.queryKey;
  return await SamplingSuiteService.getSessions(
    samplingSuiteSampleAnalysisId,
    samplingSuiteId,
    isOwned,
    isOpen,
  );
}

export function useSamplingSuiteSessions(args: {
  samplingSuiteSampleAnalysisId?: string;
  samplingSuiteId?: string;
  isOwned?: boolean;
  isOpen?: boolean;
}) {
  return useQuery({
    queryKey: queryKeys.samplingSession.list(args),
    queryFn: fetchSamplingSuiteSessions,
  });
}

// invalidate session details when new captures are taken
function useSignalRSessionInvalidator(sessionId: string) {
  const invalidator = useQueryKeyInvalidator();

  const onInvalidateSession = useCallback(
    (invalidatedSessionId: string) => {
      if (invalidatedSessionId === sessionId) {
        invalidator.invalidateKey(queryKeys.samplingSession.details());
      }
    },
    [invalidator, sessionId],
  );
  useSamplingSessionSignalR({ onInvalidateSession });
}

async function fetchSamplingSuiteSession(
  ctx: QueryFunctionContexts['samplingSession']['detail'],
) {
  const [{ sessionId }] = ctx.queryKey;

  return await SamplingSuiteService.getSession(sessionId);
}

export function useSamplingSuiteSession(sessionId: string) {
  useSignalRSessionInvalidator(sessionId);

  return useQuery({
    queryKey: queryKeys.samplingSession.detail(sessionId),
    queryFn: fetchSamplingSuiteSession,
    refetchInterval: 15000,
  });
}

async function createSamplingSuiteSession(
  session: SamplingSuiteCreateSessionDTO,
) {
  await SamplingSuiteService.createSession(session);
}

export function useCreateSamplingSuiteSession() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createSamplingSuiteSession,
    onSettled() {
      invalidator.invalidateKey(queryKeys.samplingSession.all);
    },
  });
}

async function patchSamplingSuiteSession(params: {
  sessionId: string;
  patch: SamplingSuitePatchSessionDTO;
}) {
  await SamplingSuiteService.updateSession(params.sessionId, params.patch);
}

export function usePatchSamplingSuiteSession() {
  const invalidator = useQueryKeyInvalidator();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: patchSamplingSuiteSession,
    onMutate: async ({ sessionId, patch }) => {
      // optimisitically update the session detail (not the list though!)

      // cancel any outgoing queries to the session so they don't race with our optimistic update
      const queryKey = queryKeys.samplingSession.detail(sessionId);
      await queryClient.cancelQueries({
        queryKey,
      });

      // snapshot the previous value
      const previousState: undefined | SamplingSuiteSessionDTO =
        queryClient.getQueryData(queryKey);
      if (previousState === undefined) {
        // we don't have a current set of data to optimistically update, so just bail early
        // this shouldn't really happen in practice
        return { previousState: undefined };
      }

      queryClient.setQueryData(
        queryKey,
        (old: SamplingSuiteSessionDTO | undefined) => {
          if (old === undefined) return undefined; // this should never get hit anyway

          // apply the patch, returning a new object - don't mutate the query data in place
          // this logic should match the patch implementation on the backend. If it doesn't the user will see flashes of the desync'd state
          return produce(old, (draft) => {
            if (patch.activeMaterialClassId !== undefined) {
              draft.activeMaterialClassId = patch.activeMaterialClassId;
            }
            if (patch.autoProceed !== undefined) {
              draft.autoProceed = patch.autoProceed;
            }
            if (patch.emptinessChanges !== undefined) {
              for (const {
                materialClassId,
                isEmpty,
              } of patch.emptinessChanges) {
                const state = draft.materialClassStates.find(
                  (s) => s.materialClassId === materialClassId,
                );
                if (!state) continue;
                state.isEmpty = isEmpty;

                if (
                  isEmpty &&
                  materialClassId === draft.activeMaterialClassId
                ) {
                  const stateSequence = draft.materialClassStates.map(
                    (s) => s.materialClassId,
                  );
                  const activeStateIndex = stateSequence.indexOf(
                    draft.activeMaterialClassId,
                  );
                  const nextIdx = Math.min(
                    activeStateIndex + 1,
                    stateSequence.length - 1,
                  );
                  draft.activeMaterialClassId = stateSequence[nextIdx];
                }
              }
            }
            if (patch.autoAssign !== undefined) {
              draft.autoAssign = patch.autoAssign;
            }
          });
        },
      );

      return { previousState };
    },
    onError(_error, { sessionId }, context) {
      // if the mutation fails, use the context from onMutate to roll back to the previous state
      const queryKey = queryKeys.samplingSession.detail(sessionId);
      queryClient.setQueryData(queryKey, context?.previousState);
    },
    onSettled(_data, _error, { sessionId }) {
      // always refetch after error or success
      invalidator.invalidateKeys(
        queryKeys.samplingSession.lists(),
        queryKeys.samplingSession.detail(sessionId),
      );
    },
  });
}

async function closeSamplingSuiteSession(args: {
  sessionId: string;
  close: SamplingSessionCloseDTO;
}) {
  await SamplingSuiteService.closeSession(args.sessionId, args.close);
}

export function useCloseSamplingSuiteSession() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: closeSamplingSuiteSession,
    onSettled() {
      invalidator.invalidateKey(queryKeys.samplingSession.all);
    },
  });
}
