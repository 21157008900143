import { useState } from 'react';
import { match } from 'ts-pattern';
import { FeedFlowGroupIdName } from '../../../../FeedFlowGroup/FeedFlowGroupName';
import { plainDateToStartOfDayInstant } from '../../../../Temporal/dateRangeAndOffset';
import Temporal from '../../../../Temporal/temporal';
import { useGetCommodityPurities } from '../../../../api/commodity';
import { FeedFlowGroupPurityPointDTO } from '../../../../rest-client';
import { ProducedCommodityPuritiesDTO } from '../../../../rest-client/models/ProducedCommodityPuritiesDTO';
import { formatPercentage } from '../../../../util/percentages';
import { useFacilityContext } from '../../../FacilityContext';
import { useInsightsSelectedDateRange } from '../../DateRange/InsightsDateRange';
import {
  MetricDetailPageTemplate,
  MetricDetailTableColumn,
} from '../MetricDetailPageTemplate';

export function getSeriesData(commodityPurity: ProducedCommodityPuritiesDTO) {
  const { commodity, feedFlowGroupPurityPoints } = commodityPurity;
  const commodityName = commodity.name.toLowerCase();
  return [
    {
      name: commodityName,
      entries: feedFlowGroupPurityPoints.map((ffgPurityPoint) => ({
        timestamp: ffgPurityPoint.feedFlowGroupStart,
        name: commodityName,
        value: (ffgPurityPoint.purity * 100).toFixed(1),
      })),
    },
  ];
}

export function MaterialPurityCommoditySubMetricPage({
  commodityId,
}: {
  commodityId: string;
}) {
  const { timeZoneId: tz, id: facilityId } = useFacilityContext();
  const [nowZonedDateTime] = useState(Temporal.Now.zonedDateTimeISO(tz));
  const thirtyDaysAgo = nowZonedDateTime.subtract({ days: 30 });
  const { dateRange, setDateRange } = useInsightsSelectedDateRange({
    startDate: thirtyDaysAgo.toPlainDate(),
    endDate: nowZonedDateTime.toPlainDate(),
    name: 'Last 30 Days',
    id: 'd30',
  });

  const { startDate, endDate } = dateRange;
  const rangeInstants = {
    startInstant: plainDateToStartOfDayInstant(startDate, tz),
    // TODO end of day, probably
    endInstant: plainDateToStartOfDayInstant(endDate, tz),
  };
  const { startInstant, endInstant } = rangeInstants;
  const query = useGetCommodityPurities({
    after: startInstant,
    before: endInstant,
    facilityId,
  });

  const { commodityName, seriesData, tableData } = match(query)
    .with({ status: 'loading' }, () => ({
      loading: true,
      error: null,
      commodityName: '',
      seriesData: [],
      tableData: [],
    }))
    .with({ status: 'error' }, (query) => ({
      loading: false,
      error: query.error as Error,
      commodityName: 'Unknown',
      seriesData: [],
      tableData: [],
    }))
    .with({ status: 'success' }, (query) => {
      const metricData = query.data.find((purities) => {
        return purities.commodity.id === commodityId;
      });
      if (!metricData) {
        return {
          loading: false,
          error: new ReferenceError('Could not find facet data.'),
          commodityName: 'Unknown',
          seriesData: [],
          tableData: [],
        };
      }
      return {
        loading: false,
        error: null,
        commodityName: metricData.commodity.name,
        seriesData: getSeriesData(metricData),
        tableData: metricData.feedFlowGroupPurityPoints,
      };
    })
    .exhaustive();

  interface RenderColumnArgs {
    purities: FeedFlowGroupPurityPointDTO;
    tz: string;
  }

  type CommoditySubMetricMassThroughputColumn = MetricDetailTableColumn & {
    renderTableValue: ({
      purities,
      tz,
    }: Required<RenderColumnArgs>) => JSX.Element;
  };

  const columns: CommoditySubMetricMassThroughputColumn[] = [
    {
      title: 'Process Run',
      numeric: false,
      renderTableValue: ({ purities }: Required<RenderColumnArgs>) => (
        <td key='ffg'>
          <FeedFlowGroupIdName
            feedFlowGroupId={purities.feedFlowGroupId.value}
          />
        </td>
      ),
    },
    {
      title: 'Purity',
      numeric: true,
      renderTableValue: ({ purities }: RenderColumnArgs) => (
        <td key='pruity' style={{ textAlign: 'right' }}>
          {formatPercentage(purities.purity)}
        </td>
      ),
    },
  ];

  const renderTableRow = (purities: FeedFlowGroupPurityPointDTO) => {
    return (
      <tr key={purities.feedFlowGroupId.value}>
        {columns.map((col) => {
          return col.renderTableValue({ purities, tz });
        })}
      </tr>
    );
  };

  return (
    <MetricDetailPageTemplate<FeedFlowGroupPurityPointDTO>
      dateRange={dateRange}
      setDateRange={setDateRange}
      title={`${commodityName} Material Purity`}
      tableTitle={`${commodityName} Purity by Process Run`}
      graphTitle={`${commodityName} Purity`}
      tableColumns={columns}
      tableData={tableData}
      seriesData={seriesData}
      renderTableRow={renderTableRow}
      query={query}
      yAxisLabel={'Purity %'}
    />
  );
}
