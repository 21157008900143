import { Group, MantineColor, Text } from '@mantine/core';
import { ReactNode } from 'react';
import { ScaleIcon } from '../Icons';
import { useScale } from '../api/scale';
import { ScaleDTO, ScaleId } from '../rest-client';

export type ScaleNameProps = {
  scale: ScaleDTO;
  withIcon?: boolean;
  color?: 'dimmed' | MantineColor;
};

export function ScaleName(props: ScaleNameProps) {
  const { scale, withIcon, color } = props;

  const IconWrapper = ({ children }: { children: ReactNode }) => {
    if (withIcon) {
      return (
        <Group spacing='xs'>
          <ScaleIcon />
          {children}
        </Group>
      );
    } else {
      return <>{children}</>;
    }
  };

  return (
    <IconWrapper>
      <Text color={color}>{scale.name}</Text>
    </IconWrapper>
  );
}

export type ScaleIdNameProps = Omit<ScaleNameProps, 'scale'> & {
  scaleId: ScaleId;
};

export function ScaleIdName(props: ScaleIdNameProps) {
  const { scaleId, ...scaleNameProps } = props;

  const { data: scale, isLoadingError, isLoading } = useScale(scaleId);
  // TODO(420): Set up page for scales and link this to that properly

  if (isLoadingError) {
    return <Text color='red'>Error getting scale name</Text>;
  }
  if (isLoading) {
    return <Text color='dimmed'>Loading...</Text>;
  }
  return <ScaleName scale={scale} {...scaleNameProps} />;
}
