import { Card, Group, Progress, Stack, Text } from '@mantine/core';
import { BinaryConfusionMatrixDTO } from '../rest-client';
import { PCTFORMAT } from '../util/percentages';
import cssClasses from './BinaryConfusionMatrixStats.module.css';

export function BinaryConfusionMatrixStats(props: {
  matrix: BinaryConfusionMatrixDTO;
}) {
  const { matrix } = props;

  const progressProps = {
    radius: 'xs',
    size: 'xl',
  };

  const stackProps = {
    spacing: 0,
  };

  return (
    <>
      <Card shadow='xs' className={cssClasses.statsCard}>
        <Stack {...stackProps}>
          <Text>
            <Text span color='blue'>
              Prevalence
            </Text>
          </Text>
          <Progress
            {...progressProps}
            sections={[
              {
                value: matrix.prevalence * 100,
                color: 'blue',
              },
            ]}
          />
          <Text color='blue' weight={600}>
            {PCTFORMAT.format(matrix.prevalence)}
          </Text>
        </Stack>
      </Card>

      <Card shadow='xs' className={cssClasses.statsCard}>
        <Stack {...stackProps}>
          <Group position='apart'>
            <Text color='teal'>Purity</Text>
            <Text color='red'>(Impurity)</Text>
          </Group>
          <Progress
            {...progressProps}
            sections={[
              {
                value: matrix.positivePredictiveValue * 100,
                color: 'teal',
              },
              {
                value: matrix.falseDiscoveryRate * 100,
                color: 'red',
              },
            ]}
          />
          <Group position='apart' fw={600}>
            <Text color='teal'>
              {PCTFORMAT.format(matrix.positivePredictiveValue)}
            </Text>
            <Text color='red'>
              {PCTFORMAT.format(matrix.falseDiscoveryRate)}
            </Text>
          </Group>
        </Stack>
      </Card>

      <Card shadow='xs' className={cssClasses.statsCard}>
        <Stack {...stackProps}>
          <Group position='apart'>
            <Text color='teal'>Hit Rate</Text>
            <Text color='red'>(Miss Rate)</Text>
          </Group>
          <Progress
            {...progressProps}
            sections={[
              {
                value: matrix.truePositiveRate * 100,
                color: 'teal',
              },
              {
                value: matrix.falseNegativeRate * 100,
                color: 'red',
              },
            ]}
          />
          <Group position='apart' fw={600}>
            <Text color='teal'>
              {PCTFORMAT.format(matrix.truePositiveRate)}
            </Text>
            <Text color='red'>
              {PCTFORMAT.format(matrix.falseNegativeRate)}
            </Text>
          </Group>
        </Stack>
      </Card>

      <Card shadow='xs' className={cssClasses.statsCard}>
        <Stack {...stackProps}>
          <Text>
            <Text span color='teal'>
              Selectivity
            </Text>
          </Text>
          <Progress
            {...progressProps}
            sections={[
              {
                value: matrix.trueNegativeRate * 100,
                color: 'teal',
              },
              {
                value: 100 - matrix.trueNegativeRate * 100,
                color: 'red',
              },
            ]}
          />
          <Group position='apart' fw={600}>
            <Text color='teal'>
              {PCTFORMAT.format(matrix.trueNegativeRate)}
            </Text>
            <Text color='red'>
              {PCTFORMAT.format(1 - matrix.trueNegativeRate)}
            </Text>
          </Group>
        </Stack>
      </Card>
    </>
  );
}
