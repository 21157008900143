import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import { useCreateInternalMaterialSource } from '../api/internalMaterialSource';
import { InternalMaterialSourceCreationDTO } from '../rest-client';
import {
  InternalMaterialSourceFormFields,
  InternalMaterialSourceFormValues,
  useInternalMaterialSourceForm,
} from './InternalMaterialSourceForm';

export type AddInternalMaterialSourceDrawerFormProps = {
  onSuccess?: (
    internalMaterialSource: InternalMaterialSourceCreationDTO,
  ) => void;
} & DrawerFormDrawerProps;

export function AddInternalMaterialSourceDrawerForm(
  props: AddInternalMaterialSourceDrawerFormProps,
) {
  const { onSuccess, ...drawerFormDrawerProps } = props;

  const createMutation = useCreateInternalMaterialSource();
  const form = useInternalMaterialSourceForm();
  const [id, setId] = useState(uuidv4);

  const formValuesToDto = ({
    name,
    facilityId,
    commodityId,
  }: InternalMaterialSourceFormValues) => {
    if (facilityId === null) {
      throw new Error('Facility cannot be null');
    }
    if (commodityId === null) {
      throw new Error('Commodity cannot be null');
    }

    const internalMaterialSource: InternalMaterialSourceCreationDTO = {
      id,
      name,
      facilityId,
      commodityId,
    };

    setId(uuidv4());
    return internalMaterialSource;
  };

  return (
    <DrawerForm
      entityName='Upstream Material Source'
      form={form}
      formVariant='create'
      mutation={createMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      {...drawerFormDrawerProps}
    >
      <InternalMaterialSourceFormFields form={form} />
    </DrawerForm>
  );
}
