import createClient, { ClientOptions, Middleware } from 'openapi-fetch';
import { GetMsalAccessToken } from '../Microsoft/msalUtil';
import type { paths } from '../openapi';

type ClientType = ReturnType<typeof createClient<paths>>;

export let client: ClientType;

export function setupClient(options: ClientOptions) {
  client = createClient<paths>(options);
  client.use(msalMiddleware);
}

const msalMiddleware: Middleware = {
  async onRequest(req) {
    const accessToken = await GetMsalAccessToken();
    req.headers.set('Authorization', `Bearer ${accessToken}`);
    return req;
  },
};
