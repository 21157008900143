import { useQuery } from '@tanstack/react-query';
import { SampleAnalysisService } from '../rest-client';
import { queryKeys } from './queryKeys';
import { QueryFunctionContexts } from './queryKeyTypeUtils';

async function fetchSampleAnalyses(
  ctx: QueryFunctionContexts['sampleAnalysis']['list'],
) {
  const [{ sampleId }] = ctx.queryKey;

  return await SampleAnalysisService.getSampleAnalyses(sampleId ?? undefined);
}

export function useSampleAnalyses(args: { sampleId?: string }) {
  return useQuery({
    queryKey: queryKeys.sampleAnalysis.list(args),
    queryFn: fetchSampleAnalyses,
  });
}

async function getSampleAnalysesByMaterialClassSet() {
  return await SampleAnalysisService.getSampleAnalysesByMaterialClassSet();
}

export function useSampleAnalysesByMaterialClassSet() {
  return useQuery({
    queryKey: queryKeys.materialClassSetsSampleAnalyses.list(),
    queryFn: getSampleAnalysesByMaterialClassSet,
  });
}
