import { Group, Text } from '@mantine/core';
import { ReactNode } from 'react';
import { RecoveryGoalIcon } from '../Icons';
import { LinkText } from '../Link';
import { RecoveryGoalDTO } from '../rest-client';
import { Router } from '../router';

export interface RecoveryGoalNameProps {
  recoveryGoal: RecoveryGoalDTO;
  noLink?: boolean;
  withIcon?: boolean;
}

export function RecoveryGoalName(props: RecoveryGoalNameProps) {
  const { recoveryGoal, noLink, withIcon } = props;
  const IconWrapper = ({ children }: { children: ReactNode }) => {
    if (withIcon) {
      return (
        <Group spacing='xs'>
          <RecoveryGoalIcon />
          {children}
        </Group>
      );
    } else {
      return <>{children}</>;
    }
  };

  if (noLink) {
    return (
      <IconWrapper>
        <Text>{recoveryGoal.name}</Text>
      </IconWrapper>
    );
  }
  return (
    <IconWrapper>
      <LinkText
        to={Router.RecoveryGoalDetail({ recoveryGoalId: recoveryGoal.id })}
      >
        {recoveryGoal.name}
      </LinkText>
    </IconWrapper>
  );
}
