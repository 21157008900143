import { Select, SelectProps } from '@mantine/core';
import { useEffect } from 'react';
import { useScales } from '../api/scale';
import { ScaleDTO } from '../rest-client';

type ControlledScaleSelectProps =
  | {
      type: 'id';
      value: string | null;
      onChange: (scaleId: string | null) => void;
    }
  | {
      type: 'object';
      value: ScaleDTO | null;
      onChange: (scale: ScaleDTO | null) => void;
    };

type ScaleSelectSpecificProps = {
  facilityId: string | undefined;
  scaleTypeId?: string;
  containerTypeId?: string;
  truckLoadCapable?: boolean;
  sampleCapable?: boolean;
  leaveEmptyOnLoad?: boolean;
} & ControlledScaleSelectProps;

export type ScaleSelectProps = Omit<
  SelectProps,
  keyof ScaleSelectSpecificProps | 'data'
> &
  ScaleSelectSpecificProps;

export function ScaleSelect(props: ScaleSelectProps) {
  const {
    facilityId,
    scaleTypeId,
    containerTypeId,
    truckLoadCapable,
    sampleCapable,
    leaveEmptyOnLoad = false,
    type,
    value,
    onChange,
    disabled,
    placeholder = 'Select scale',
    searchable = true,
    ...rest
  } = props;

  const scalesQuery = useScales({
    facilityId,
    scaleTypeId,
    containerTypeId,
    truckLoadCapable,
    sampleCapable,
  });

  // Populate with first scale on load unless explicitly disabled
  useEffect(() => {
    if (
      !leaveEmptyOnLoad &&
      value === null &&
      scalesQuery.data &&
      scalesQuery.data.length > 0
    ) {
      if (type === 'id') {
        onChange(scalesQuery.data[0].id);
      } else {
        onChange(scalesQuery.data[0]);
      }
    }
  }, [leaveEmptyOnLoad, value, scalesQuery.data, onChange, type]);

  const selectedId = type === 'id' ? value : value?.id;
  const onSelectionChange =
    type === 'id'
      ? onChange
      : (id: string) =>
          onChange(scalesQuery.data?.find((s) => s.id === id) ?? null);

  return (
    <Select
      value={selectedId}
      onChange={onSelectionChange}
      disabled={scalesQuery.isLoading ? true : disabled}
      placeholder={scalesQuery.isLoading ? 'Loading scales...' : placeholder}
      searchable={searchable}
      data={
        scalesQuery.data?.map((scale) => ({
          label: scale.name,
          value: scale.id,
        })) ?? []
      }
      {...rest}
    />
  );
}
