import { Group, Stack, SystemProp } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { UseMutationResult } from '@tanstack/react-query';
import { CSSProperties, ReactNode, useMemo } from 'react';
import { match } from 'ts-pattern';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppPage } from '../App/AppPage';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DrawerForm } from './DrawerForm';
import { FormActionButton } from './FormActionButton';
import { MutationErrorAlert } from './MutationErrorAlert';

export type FormWrapperVariant = 'create' | 'edit';

export type FormWrapperProps<TDTO, TFormValues> = {
  children: ReactNode;
  /**
   * The {@link UseFormReturnType} to be controlled in the drawer.
   */
  form: UseFormReturnType<TFormValues>;
  /**
   * Logic to perform on form submission
   * @param values - the form values, of type {@link TFormValues}
   * @returns the patch or creation {@link TDTO} for the form
   */
  formValuesToDto: (values: TFormValues) => TDTO;
  /**
   * The {@link UseMutationResult} for the form {@link mutation}. Called in the form's obSubmit.
   */
  mutation: UseMutationResult<void, unknown, TDTO, unknown>;
  /**
   * Any additional behavior to perform on the {@link mutation} successful completion.
   * @param dto - the valid patch or creation {@link TDTO} for the form
   * @returns
   */
  onSuccess?: (dto: TDTO) => void;
  onCancel: () => void;
  formResetOnCancel?: boolean;
  /**
   * Controls the form submit button presentation and message language.
   */
  formVariant: FormWrapperVariant;
  /**
   * Entity name to use for user-facing text: drawer title, messages, notifications.
   */
  entityName: string;
  maxWidth?: SystemProp<CSSProperties['maxWidth']>;
};

/**
 * This component needs to be wrapped in a container, e.g., {@link DrawerForm} or {@link AppPage.Section}.
 *
 * @param TDTO - the creation or patch DTO type.
 * @param TFormValues - the form values type, specified in the {@link UseFormReturnType}.
 * @param {ReactNode} children - form fields fragmant to be stacked in the drawer content.
 * @returns Form component containing full form create or edit functionality.
 */
export function FormWrapper<TDTO, TFormValues>(
  props: FormWrapperProps<TDTO, TFormValues>,
) {
  const {
    children,
    entityName,
    maxWidth,
    // FormProps
    form,
    formValuesToDto,
    mutation,
    onSuccess,
    onCancel,
    formResetOnCancel = true,
    formVariant: drawerFormVariant,
  } = props;

  type NotificationTitleMessage = {
    title: string;
    message: string;
  };
  const errorTitleMessage: NotificationTitleMessage = match(drawerFormVariant)
    .with('create', () => ({
      title: `Error Creating ${entityName}`,
      message: `An error occurred creating the ${entityName.toLowerCase()}.`,
    }))
    .with('edit', () => ({
      title: `Error Updating ${entityName}`,
      message: `An error occurred making edits to the ${entityName.toLowerCase()}.`,
    }))
    .exhaustive();
  const successTitleMessage: NotificationTitleMessage = match(drawerFormVariant)
    .with('create', () => ({
      title: `${entityName} Created`,
      message: `The new ${entityName.toLowerCase()} was sucessfully created.`,
    }))
    .with('edit', () => ({
      title: `${entityName} Updated`,
      message: `The edits to the ${entityName.toLowerCase()} were sucessfully saved.`,
    }))
    .exhaustive();

  const formOnSubmit = useMemo(
    () =>
      form.onSubmit((values: TFormValues) => {
        const dto = formValuesToDto(values);
        mutation.mutate(dto, {
          onError() {
            showNotification({
              ...errorTitleMessage,
              color: 'red',
              icon: <IconX />,
            });
          },
          onSuccess(_, variables) {
            showNotification({
              ...successTitleMessage,
              color: 'green',
              icon: <IconCheck />,
            });
            onCancel();
            mutation.reset();
            form.reset();
            onSuccess?.(variables);
          },
        });
      }),
    [
      form,
      formValuesToDto,
      mutation,
      errorTitleMessage,
      successTitleMessage,
      onCancel,
      onSuccess,
    ],
  );

  return (
    <form onSubmit={formOnSubmit}>
      <Stack maw={maxWidth}>{children}</Stack>
      <Stack spacing='md' mt='2rem' mx={0} mb={0} p={0}>
        <Group position='right'>
          <FormActionButton
            action='cancel'
            onClick={() => {
              onCancel();
              if (formResetOnCancel) {
                form.reset();
              }
            }}
            loading={mutation.isLoading}
          />
          <FormActionButton
            type='submit'
            action={match(drawerFormVariant)
              .with('create', () => 'saveCreation' as const)
              .with('edit', () => 'saveEdits' as const)
              .exhaustive()}
            disabled={!mutation.isIdle && !mutation.isLoading}
            loading={mutation.isLoading}
          />
        </Group>
        {mutation.isError && (
          <MutationErrorAlert
            errorTitle={errorTitleMessage.title}
            entityName={entityName}
            mutation={mutation}
            formVariant={drawerFormVariant}
          />
        )}
      </Stack>
    </form>
  );
}
