import { useMutation, useQuery } from '@tanstack/react-query';
import {
  SamplingSuiteSampleAnalysisCreationDTO,
  SamplingSuiteSampleAnalysisPatchDTO,
  SamplingSuiteSampleAnalysisService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchSamplingSuiteSampleAnalysis(
  ctx: QueryFunctionContexts['sampleAnalysis']['samplingSuite']['detail'],
) {
  const [{ samplingSuiteSampleAnalysisId }] = ctx.queryKey;
  return await SamplingSuiteSampleAnalysisService.getSamplingSuiteSampleAnalysis(
    samplingSuiteSampleAnalysisId,
  );
}

export function useSamplingSuiteSampleAnalysis(analysisId: string) {
  return useQuery({
    queryKey: queryKeys.sampleAnalysis.samplingSuite.detail(analysisId),
    queryFn: fetchSamplingSuiteSampleAnalysis,
  });
}

async function createSamplingSuiteSampleAnalysis(
  dto: SamplingSuiteSampleAnalysisCreationDTO,
) {
  await SamplingSuiteSampleAnalysisService.createSamplingSuiteSampleAnalysis(
    dto,
  );
}

export function useCreateSamplingSuiteSampleAnalysis() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createSamplingSuiteSampleAnalysis,
    onSettled() {
      invalidator.invalidateMaterialState();
      invalidator.invalidateKeys(
        queryKeys.sampleAnalysis.lists(),
        queryKeys.materialClassSet.list(), // can cause reordering
      );
    },
  });
}

async function patchSamplingSuiteSampleAnalysis(args: {
  analysisId: string;
  patch: SamplingSuiteSampleAnalysisPatchDTO;
}) {
  await SamplingSuiteSampleAnalysisService.patchSamplingSuiteSampleAnalysis(
    args.analysisId,
    args.patch,
  );
}

export function usePatchSamplingSuiteSampleAnalysis() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: patchSamplingSuiteSampleAnalysis,
    onSettled(_data, _error, { analysisId }) {
      invalidator.invalidateMaterialState();
      invalidator.invalidateKeys(
        queryKeys.sampleAnalysis.samplingSuite.detail(analysisId),
        queryKeys.sampleAnalysis.lists(),
        queryKeys.samplingSuiteCapture.lists(),
      );
    },
  });
}

async function deleteSamplingSuiteSampleAnalysis(analysisId: string) {
  await SamplingSuiteSampleAnalysisService.deleteSamplingSuiteSampleAnalysis(
    analysisId,
  );
}

export function useDeleteSamplingSuiteSampleAnalysis() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteSamplingSuiteSampleAnalysis,
    onSettled() {
      invalidator.invalidateMaterialState();
      invalidator.invalidateKeys(
        queryKeys.sampleAnalysis.lists(),
        queryKeys.sampleAnalysis.samplingSuite.all(),
      );
    },
  });
}
