import { useState } from 'react';
import { match } from 'ts-pattern';
import { LinkText } from '../../../../Link';
import { plainDateToStartOfDayInstant } from '../../../../Temporal/dateRangeAndOffset';
import Temporal from '../../../../Temporal/temporal';
import { useRecoveryGoalClassificationPerformance } from '../../../../api/recoveryGoal';
import {
  RecoveryGoalClassificationPerformancesDTO,
  RecoveryGoalFeedFlowGroupClassificationPerformancePointDTO,
} from '../../../../rest-client';
import { Router } from '../../../../router';
import { useFacilityContext } from '../../../FacilityContext';
import { useInsightsSelectedDateRange } from '../../DateRange/InsightsDateRange';
import { MetricDetailPageTemplate } from '../MetricDetailPageTemplate';

// TODO abstract
const formatPercentage = (val: number) => `${(val * 100).toFixed(1)}%`;

interface RenderColumnArgs {
  classificationPoint: RecoveryGoalFeedFlowGroupClassificationPerformancePointDTO;
  tz: string;
}

const COLUMNS = [
  {
    title: 'Process Run',
    numeric: false,
    renderTableValue: ({ classificationPoint, tz }: RenderColumnArgs) => (
      <td>
        <LinkText
          to={Router.FeedFlowGroupDetail({
            feedFlowGroupId: classificationPoint.feedFlowGroupId.value,
          })}
        >
          {Temporal.Instant.from(classificationPoint.feedFlowGroupStart)
            .toZonedDateTimeISO(tz)
            .toLocaleString(undefined, {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })}
        </LinkText>
      </td>
    ),
  },
  {
    renderTableValue: ({ classificationPoint }: RenderColumnArgs) => (
      <td>{formatPercentage(classificationPoint.loss)}</td>
    ),
    title: 'Loss',
    numeric: false,
  },
];

export function getSeriesData(
  classificationPerf: RecoveryGoalClassificationPerformancesDTO,
) {
  const recoveryGoalName = classificationPerf.recoveryGoal.name.toLowerCase();
  return [
    {
      name: recoveryGoalName,
      entries: classificationPerf.feedFlowGroupClassificationPerformances.map(
        (feedFlowGroupClassification) => ({
          timestamp: feedFlowGroupClassification.feedFlowGroupStart,
          name: recoveryGoalName,
          value: (feedFlowGroupClassification.loss * 100).toFixed(1),
        }),
      ),
    },
  ];
}

export function MaterialLossRecoveryGoalSubMetricPage({
  recoveryGoalId,
}: {
  recoveryGoalId: string;
}) {
  const { id: facilityId, timeZoneId: tz } = useFacilityContext();
  const [nowZonedDateTime] = useState(Temporal.Now.zonedDateTimeISO(tz));
  const thirtyDaysAgo = nowZonedDateTime.subtract({ days: 30 });
  const { dateRange, setDateRange } = useInsightsSelectedDateRange({
    startDate: thirtyDaysAgo.toPlainDate(),
    endDate: nowZonedDateTime.toPlainDate(),
    name: 'Last 30 Days',
    id: 'd30',
  });

  const { startDate, endDate } = dateRange;
  const rangeInstants = {
    startInstant: plainDateToStartOfDayInstant(startDate, tz),
    // TODO end of day, probably
    endInstant: plainDateToStartOfDayInstant(endDate, tz),
  };
  const { startInstant, endInstant } = rangeInstants;
  const query = useRecoveryGoalClassificationPerformance({
    after: startInstant,
    before: endInstant,
    facilityId,
  });

  const { recoveryGoalName, seriesData, tableData } = match(query)
    .with({ status: 'loading' }, () => ({
      loading: true,
      error: null,
      recoveryGoalName: 'Loading...',
      seriesData: [],
      tableData: [],
    }))
    .with({ status: 'error' }, (query) => ({
      loading: false,
      error: query.error as Error,
      recoveryGoalName: 'Unknown',
      seriesData: [],
      tableData: [],
    }))
    .with({ status: 'success' }, (query) => {
      const metricData = query.data.find((classificationPerf) => {
        return classificationPerf.recoveryGoal.id === recoveryGoalId;
      });
      if (!metricData) {
        return {
          loading: false,
          error: new ReferenceError('Could not find metric data.'),
          recoveryGoalName: 'Unknown',
          seriesData: [],
          tableData: [],
        };
      }

      return {
        loading: false,
        error: null,
        recoveryGoalName: metricData.recoveryGoal.name,
        seriesData: getSeriesData(metricData),
        tableData: metricData.feedFlowGroupClassificationPerformances,
      };
    })
    .exhaustive();

  const renderTableRow = (
    classificationPoint: RecoveryGoalFeedFlowGroupClassificationPerformancePointDTO,
  ) => {
    return (
      <tr key={classificationPoint.feedFlowGroupId.value}>
        {COLUMNS.map((col) => {
          return col.renderTableValue({ classificationPoint, tz });
        })}
      </tr>
    );
  };

  return (
    <MetricDetailPageTemplate<RecoveryGoalFeedFlowGroupClassificationPerformancePointDTO>
      dateRange={dateRange}
      setDateRange={setDateRange}
      title={`Material Loss for ${recoveryGoalName}`}
      graphTitle={`${recoveryGoalName} Loss`}
      tableTitle={`${recoveryGoalName} Loss by Process Run`}
      tableColumns={COLUMNS}
      renderTableRow={renderTableRow}
      seriesData={seriesData}
      tableData={tableData}
      query={query}
      yAxisLabel={'Loss %'}
    />
  );
}
