import { useMutation, useQuery } from '@tanstack/react-query';
import {
  MaterialClassSetCreationDTO,
  MaterialClassSetPatchDTO,
  MaterialClassSetService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchMaterialClassSets() {
  return await MaterialClassSetService.getMaterialClassSets();
}

export function useMaterialClassSets() {
  return useQuery({
    queryKey: queryKeys.materialClassSet.all,
    queryFn: fetchMaterialClassSets,
  });
}

async function fetchMaterialClassSet(
  ctx: QueryFunctionContexts['materialClassSet']['detail'],
) {
  const [{ materialClassSetId }] = ctx.queryKey;
  return await MaterialClassSetService.getMaterialClassSetById(
    materialClassSetId,
  );
}

export function useMaterialClassSet(id: string) {
  return useQuery({
    queryKey: queryKeys.materialClassSet.detail(id),
    queryFn: fetchMaterialClassSet,
  });
}

async function createMaterialClassSet(
  materialClassSet: MaterialClassSetCreationDTO,
) {
  await MaterialClassSetService.createMaterialClassSet(materialClassSet);
}

export function useCreateMaterialClassSet() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createMaterialClassSet,
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.materialClassSet.list(),
        queryKeys.materialClassSetsSampleAnalyses.list(),
      );
    },
  });
}

async function patchMaterialClassSet(args: {
  id: string;
  patch: MaterialClassSetPatchDTO;
}) {
  await MaterialClassSetService.patchMaterialClassSet(args.id, args.patch);
}

export function usePatchMaterialClassSet() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: patchMaterialClassSet,
    onSettled(_data, _error, { id }) {
      invalidator.invalidateKeys(
        queryKeys.materialClassSet.list(),
        queryKeys.materialClassSet.detail(id),
        queryKeys.materialClassSetsSampleAnalyses.list(),
      );
    },
  });
}

async function deleteMaterialClassSet(id: string) {
  await MaterialClassSetService.deleteMaterialClassSet(id);
}

export function useDeleteMaterialClassSet() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteMaterialClassSet,
    onSettled(data, error, id) {
      invalidator.invalidateKeys(
        queryKeys.materialClassSet.list(),
        queryKeys.materialClassSet.detail(id),
        queryKeys.materialClassSetsSampleAnalyses.list(),
      );
    },
  });
}
