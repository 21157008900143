import { ActionIcon, Group, Stack, Table, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { AppPage } from '../../App/AppPage.tsx';
import { DeleteIcon, EditIcon } from '../../Icons.tsx';
import {
  useCommoditySpotPrices,
  useDeleteCommoditySpotPrice,
} from '../../api/commoditySpotPrice.ts';
import { CommodityId, CommoditySpotPriceDTO } from '../../rest-client/index.ts';
import { Price } from '../../util/Money.tsx';
import AddCommoditySpotPriceButton from './AddCommoditySpotPriceButton.tsx';
import { EditCommoditySpotPriceDrawerForm } from './EditCommoditySpotPriceDrawerForm.tsx';

export function CommoditySpotPrices(props: { commodityId: CommodityId }) {
  const { commodityId }: { commodityId: CommodityId } = props;

  const {
    data: commoditySpotPrices,
    isLoading,
    error,
  } = useCommoditySpotPrices(commodityId);

  if (commoditySpotPrices) {
    return (
      <AppPage.Section>
        <Stack mt={10}>
          <Group>
            <Title order={2}>Commodity Spot Prices</Title>
            <AddCommoditySpotPriceButton
              addCommoditySpotPriceDrawerFormProps={{ commodityId }}
            />
          </Group>
          <Group>
            <Table striped highlightOnHover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th style={{ textAlign: 'right' }}>Price</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {commoditySpotPrices.map((commoditySpotPrice) => (
                  <CommoditySpotPriceRow
                    key={commoditySpotPrice.id}
                    commoditySpotPrice={commoditySpotPrice}
                    commodityId={commodityId}
                  />
                ))}
              </tbody>
            </Table>
          </Group>
        </Stack>
      </AppPage.Section>
    );
  }

  if (isLoading) {
    return <p>Loading ...</p>;
  }

  throw error;
}

type CommoditySpotPriceRowProps = {
  commodityId: CommodityId;
  commoditySpotPrice: CommoditySpotPriceDTO;
};

function CommoditySpotPriceRow(props: CommoditySpotPriceRowProps) {
  const { commodityId, commoditySpotPrice } = props;

  const [editDrawerOpened, { open: openEditDrawer, close: closeEditDrawer }] =
    useDisclosure(false);

  const deleteMutation = useDeleteCommoditySpotPrice(commodityId);

  return (
    <tr>
      <td>{dayjs(commoditySpotPrice.pricedAt).format('L')}</td>
      <td style={{ textAlign: 'right' }}>
        <Price
          usdPerWeightUnit={commoditySpotPrice.usdPerUnitOfWeight}
          weightUnit={commoditySpotPrice.weightUnit}
        />
      </td>
      <td>
        <ActionIcon
          gradient={{ from: 'teal', to: 'teal', deg: 90 }}
          variant='gradient'
          size='xl'
          onClick={openEditDrawer}
        >
          <EditIcon />
        </ActionIcon>
        <EditCommoditySpotPriceDrawerForm
          opened={editDrawerOpened}
          onClose={closeEditDrawer}
          commodityId={commodityId}
          commoditySpotPrice={commoditySpotPrice}
        />
      </td>
      <td>
        <ActionIcon
          gradient={{ from: 'red', to: 'red', deg: 90 }}
          variant='gradient'
          size='xl'
          onClick={() => {
            deleteMutation.mutate(commoditySpotPrice.id, {
              onError() {
                showNotification({
                  title: 'Error Deleting Price',
                  message: `An error occured deleting the price.`,
                  color: 'red',
                  icon: <IconX />,
                });
                deleteMutation.reset();
              },
              onSuccess() {
                showNotification({
                  title: 'Price Deleted',
                  message: `Price was successfully deleted.`,
                  color: 'green',
                  icon: <IconCheck />,
                });
              },
            });
          }}
        >
          <DeleteIcon />
        </ActionIcon>
      </td>
    </tr>
  );
}
