import {
  createStyles,
  Paper,
  Text,
  useMantineColorScheme,
} from '@mantine/core';
import { ReactNode } from 'react';
import { Handle, NodeProps, Position, useOnSelectionChange } from 'reactflow';
import { match } from 'ts-pattern';
import {
  ElkFlowEdge,
  ElkFlowNode,
  elkOpts,
  ElkReactFlow,
} from '../lib/reactFlowElk';
import {
  FlowDTO,
  ProcessDTO,
  ProcessOutputPortDTO,
  SortSystemDTO,
} from '../rest-client';
import { SortSystemName } from '../SortSystem/SortSystemName';

export interface FeedstockNodeData {
  type: 'feedstock';
}
export interface SystemNodeData {
  type: 'system';
  sortSystem: SortSystemDTO;
  showLink: boolean;
}

export interface OutputPortNodeData {
  type: 'output';
  outputPort: ProcessOutputPortDTO;
}

export type ProcessTopologyNodeData =
  | FeedstockNodeData
  | SystemNodeData
  | OutputPortNodeData;

export interface FeedstockEdgeData {
  type: 'feedstock';
}
export interface FlowEdgeData {
  type: 'flow';
  flow: FlowDTO;
}
export interface OutputPortEdgeData {
  type: 'output';
  outputPort: ProcessOutputPortDTO;
}
export type ProcessTopologyEdgeData =
  | FeedstockEdgeData
  | FlowEdgeData
  | OutputPortEdgeData;

export type ProcessTopologyNode = ElkFlowNode<ProcessTopologyNodeData>;
export type ProcessTopologyEdge = ElkFlowEdge<ProcessTopologyEdgeData>;

interface NodeStyleParams {
  selected: boolean;
  zIndex: number;
}
const useNodeStyles = createStyles<
  'feedstock' | 'system' | 'output',
  NodeStyleParams
>((theme, _params) => {
  const { zIndex, selected } = _params;
  const isDark = theme.colorScheme === 'dark';

  const selectedBorderColor = theme.colors.teal[isDark ? 5 : 6];

  const background = isDark
    ? theme.colors.gray[selected ? 0 : 1]
    : theme.colors.dark[selected ? 8 : 6];
  const commonStyles = {
    background,
    zIndex,
    border: selected ? `3px solid ${selectedBorderColor}` : undefined,
  };

  return {
    feedstock: {
      ...commonStyles,
    },
    system: {
      ...commonStyles,
    },
    output: {
      ...commonStyles,
    },
  };
});

function FeedstockNode(props: NodeProps<FeedstockNodeData>) {
  const { classes } = useNodeStyles({
    zIndex: props.zIndex,
    selected: props.selected,
  });
  const { colorScheme } = useMantineColorScheme();
  const dark = colorScheme === 'dark';

  return (
    <Paper className={classes.feedstock} p='sm' shadow='md'>
      <Text size='lg' color={dark ? 'dark' : 'white'}>
        Feedstock
      </Text>
      <Handle type='source' position={Position.Right} />
    </Paper>
  );
}

function OutputPortNode(props: NodeProps<OutputPortNodeData>) {
  const {
    data: { outputPort },
  } = props;

  const { colorScheme } = useMantineColorScheme();
  const dark = colorScheme === 'dark';
  const { classes } = useNodeStyles({
    zIndex: props.zIndex,
    selected: props.selected,
  });

  return (
    <Paper className={classes.output} p='sm' shadow='md'>
      <Handle type='target' position={Position.Left} />
      <Text size='lg' color={dark ? 'dark' : 'white'}>
        {outputPort.name}
      </Text>
    </Paper>
  );
}

function SortSystemNode(props: NodeProps<SystemNodeData>) {
  const {
    data: { sortSystem, showLink },
  } = props;

  const { classes } = useNodeStyles({
    zIndex: props.zIndex,
    selected: props.selected,
  });

  const { colorScheme } = useMantineColorScheme();

  const box = (children: ReactNode) => (
    <Paper className={classes.system} p='sm' shadow='md'>
      {children}
    </Paper>
  );

  const outputHandles = match(sortSystem)
    .with({ kind: 'Chutec' }, (chutecSystem) => (
      <>
        <Handle
          id={chutecSystem.ejectPortId}
          type='source'
          position={Position.Right}
        />
        <Handle
          id={chutecSystem.rejectPortId}
          type='source'
          position={Position.Right}
        />
      </>
    ))
    .with({ kind: 'RedWave' }, (redwaveSystem) => (
      <>
        <Handle
          id={redwaveSystem.ejectPortId}
          type='source'
          position={Position.Right}
        />
        <Handle
          id={redwaveSystem.rejectPortId}
          type='source'
          position={Position.Right}
        />
      </>
    ))
    .exhaustive();

  return box(
    <>
      <Handle id='input' type='target' position={Position.Left} />
      <Text
        size='lg'
        c={showLink ? undefined : colorScheme === 'dark' ? 'dark' : 'white'}
      >
        <SortSystemName sortSystem={sortSystem} noLink={!showLink} />
      </Text>
      {outputHandles}
    </>,
  );
}

function DiagramNode({
  data,
  ...otherProps
}: NodeProps<ProcessTopologyNodeData>) {
  return match(data)
    .with({ type: 'feedstock' }, (feedstockData) => (
      <FeedstockNode data={feedstockData} {...otherProps} />
    ))
    .with({ type: 'output' }, (outputPortdata) => (
      <OutputPortNode data={outputPortdata} {...otherProps} />
    ))
    .with({ type: 'system' }, (systemData) => (
      <SortSystemNode data={systemData} {...otherProps} />
    ))
    .exhaustive();
}

function diagramNodes(
  process: ProcessDTO,
  showSystemLinks: boolean,
): ProcessTopologyNode[] {
  const feedstockNode: ProcessTopologyNode = {
    id: 'feedstock',
    position: { x: 0, y: 0 },
    data: { type: 'feedstock' },
    type: 'process',
  };

  const inputSystem = process.sortSystems.find(
    (sortSystem) => sortSystem.id === process.inputSystemId,
  );
  if (inputSystem === undefined) {
    throw new Error('input system missing');
  }

  const initialSystemNode: ProcessTopologyNode = {
    id: process.inputSystemId,
    position: { x: 0, y: 0 },
    data: {
      type: 'system',
      sortSystem: inputSystem,
      showLink: showSystemLinks,
    },
    type: 'process',
  };

  const downstreamSystems = process.sortSystems.filter(
    (sortSystem) => sortSystem.id !== process.inputSystemId,
  );
  const downstreamSystemNodes: ProcessTopologyNode[] = downstreamSystems.map(
    (downstreamSystem) => ({
      id: downstreamSystem.id,
      position: { x: 0, y: 0 },
      data: {
        type: 'system',
        sortSystem: downstreamSystem,
        showLink: showSystemLinks,
      },
      type: 'process',
    }),
  );

  const systemNodes = [initialSystemNode, ...downstreamSystemNodes];

  const outputPortNodes: ProcessTopologyNode[] = process.outputs.map(
    (outputPort) => ({
      id: outputPort.outputPortId,
      position: { x: 0, y: 0 },
      data: {
        type: 'output',
        outputPort: outputPort,
        [elkOpts]: {
          layoutOptions: {
            'layered.layering.layerConstraint': 'LAST',
          },
        },
      },
      type: 'process',
    }),
  );

  return [feedstockNode, ...systemNodes, ...outputPortNodes];
}

function diagramEdges(process: ProcessDTO): ProcessTopologyEdge[] {
  const feedstockToInputSystemEdge: ElkFlowEdge<FeedstockEdgeData> = {
    id: 'feedstock-input',
    data: {
      type: 'feedstock',
    },
    source: 'feedstock',
    target: process.inputSystemId,
    animated: true,
  };

  const flowEdges: ElkFlowEdge<FlowEdgeData>[] = process.flows.map((flow) => ({
    id: `${flow.sourceSystemId}/${flow.sourceOutputPortId}-${flow.destinationSortSystemId}`,
    data: {
      type: 'flow',
      flow,
    },
    source: flow.sourceSystemId,
    sourceHandle: flow.sourceOutputPortId,
    target: flow.destinationSortSystemId,
    targetHandle: 'input',
    animated: true,
    // TODO(2293): Add labels
    // interactionWidth: 10
  }));

  const outputPortEdges: ElkFlowEdge<OutputPortEdgeData>[] =
    process.outputs.map((output) => ({
      id: `${output.outputPortId}-output`,
      data: {
        type: 'output',
        outputPort: output,
      },
      source: output.sortSystemId,
      sourceHandle: output.outputPortId,
      target: output.outputPortId,
      targetHandle: 'input',
      animated: true,
    }));

  return [feedstockToInputSystemEdge, ...flowEdges, ...outputPortEdges];
}

const layoutArgs = {
  layoutOptions: {
    'elk.algorithm': 'layered',
    'elk.direction': 'RIGHT',
    'elk.spacing.nodeNode': '50',
    'elk.layered.spacing.nodeNodeBetweenLayers': '25',
  },
};

const nodeTypes = {
  process: DiagramNode,
};

interface ProcessTopologyDiagramProps {
  process: ProcessDTO;
  showSystemLinks?: boolean;
  onNodeSelect?: (node: ProcessTopologyNode | null) => void;
  onEdgeSelect?: (edge: ProcessTopologyEdge | null) => void;
}
export function ProcessTopologyDiagram(props: ProcessTopologyDiagramProps) {
  const {
    process,
    showSystemLinks = false,
    onNodeSelect,
    onEdgeSelect,
  } = props;
  const nodes = diagramNodes(process, showSystemLinks);
  const edges = diagramEdges(process);

  useOnSelectionChange({
    onChange: ({ nodes, edges }) => {
      if (nodes.length > 0) {
        const selectedNode = nodes[0] as ProcessTopologyNode;
        onNodeSelect?.(selectedNode);
      } else {
        onNodeSelect?.(null);
      }
      if (edges.length > 0) {
        const selectedEdge = edges[0] as ProcessTopologyEdge;
        onEdgeSelect?.(selectedEdge);
      } else {
        onEdgeSelect?.(null);
      }
    },
  });

  return (
    <ElkReactFlow
      id={`process-diagram-${process.id}`}
      style={{ height: '100%', width: '100%', minHeight: 250 }}
      nodes={nodes}
      edges={edges}
      layoutArgs={layoutArgs}
      nodeTypes={nodeTypes}
      elementsSelectable
      nodesConnectable={false}
    />
  );
}
