import {
  Alert,
  Button,
  Flex,
  Group,
  Loader,
  Stack,
  Tabs,
  Text,
  Title,
} from '@mantine/core';
import { useState } from 'react';
import { P, match } from 'ts-pattern';
import { v4 as uuidv4 } from 'uuid';
import { AppPage } from '../App/AppPage';
import { RecoveryStrategyIcon } from '../Icons';
import AddRecoveryGoalButton from '../RecoveryGoal/AddRecoveryGoalButton';
import { RecoveryGoalTable } from '../RecoveryGoal/RecoveryGoalTable';
import { AddRecoveryStrategySimulationButton } from '../RecoveryStrategySimulation/AddRecoveryStrategySimulationButton';
import { RecoveryStrategySimulationTable } from '../RecoveryStrategySimulation/RecoveryStrategySimulationTable';
import {
  useCreateRecoveryStrategy,
  useRecoveryStrategies,
} from '../api/recoveryStrategy';
import { LabeledValue } from '../common';
import { RecoveryStrategyDTO } from '../rest-client';
import { Router } from '../router';
import {
  RecoveryStrategyBasicTree,
  RecoveryStrategySequenceDetail,
} from './RecoveryStrategyDetailPage';

export default function RecoveryStrategiesPage() {
  const createMutation = useCreateRecoveryStrategy();
  const recoveryStrategiesQuery = useRecoveryStrategies();

  if (recoveryStrategiesQuery.isError) {
    throw recoveryStrategiesQuery.error;
  }

  return (
    <AppPage
      title='Recovery Strategies'
      titleRight={
        <Button
          leftIcon={<RecoveryStrategyIcon />}
          onClick={() =>
            createMutation.mutate(
              {
                id: uuidv4(),
                name: '',
              },
              {
                onSuccess(data, { id }) {
                  Router.push('RecoveryStrategyDetail', {
                    recoveryStrategyId: id,
                  });
                },
              },
            )
          }
          loading={createMutation.isLoading}
        >
          Add Recovery Strategy
        </Button>
      }
    >
      <AppPage.Section>
        {match(recoveryStrategiesQuery)
          .with({ isLoading: true }, () => <Loader size='xl' variant='dots' />)
          .with(
            {
              data: P.when(
                (recoveryStrategies) => recoveryStrategies.length === 0,
              ),
            },
            () => (
              <Alert title='No Strategies' color='blue'>
                <Text>No recovery strategies have been defined yet.</Text>
              </Alert>
            ),
          )
          .otherwise(({ data: recoveryStrategies }) => (
            <RecoveryStrategiesTabs recoveryStrategies={recoveryStrategies} />
          ))}
      </AppPage.Section>
      <AppPage.Section>
        <Stack>
          <Group>
            <Title order={2}>Recovery Goals</Title>
            <AddRecoveryGoalButton addRecoveryGoalDrawerFormProps={{}} />
          </Group>
          <RecoveryGoalTable />
        </Stack>
      </AppPage.Section>
    </AppPage>
  );
}

function RecoveryStrategiesTabs(props: {
  recoveryStrategies: RecoveryStrategyDTO[];
}) {
  const { recoveryStrategies } = props;
  const [activeRecoveryGoalId, setActiveRecoveryGoalId] = useState<
    string | null
  >(recoveryStrategies[0].id);

  return (
    <Tabs
      value={activeRecoveryGoalId}
      onTabChange={setActiveRecoveryGoalId}
      variant='outline'
    >
      <Tabs.List>
        {recoveryStrategies.map((rs) => (
          <Tabs.Tab key={rs.id} value={rs.id}>
            <Text size={16} c={rs.name === '' ? 'red' : undefined}>
              {rs.name == '' ? 'unnamed' : rs.name}
            </Text>
          </Tabs.Tab>
        ))}
      </Tabs.List>
      {recoveryStrategies.map((rs) => (
        <Tabs.Panel key={rs.id} value={rs.id}>
          <Flex align='flex-start' pt={10} gap='lg'>
            <Stack>
              <Title order={3}>Recovery Strategy Details</Title>
              <RecoveryStrategyDetails recoveryStrategy={rs} />
              <Stack spacing='xs'>
                <Title order={3}>Recovery Strategy Behavior Tree</Title>
                <RecoveryStrategyBasicTree recoveryStrategy={rs} />
              </Stack>
            </Stack>
            <Stack>
              <Group>
                <Title order={3}>Recovery Strategy Simulations</Title>
                <AddRecoveryStrategySimulationButton
                  recoveryStrategyId={rs.id}
                />
              </Group>
              <RecoveryStrategySimulationTable recoveryStrategyId={rs.id} />
            </Stack>
          </Flex>
        </Tabs.Panel>
      ))}
    </Tabs>
  );
}

function RecoveryStrategyDetails(props: {
  recoveryStrategy: RecoveryStrategyDTO;
}) {
  const { recoveryStrategy } = props;
  const [editing, setEditing] = useState(false);

  return (
    <Flex align='flex-start' gap='lg'>
      <LabeledValue label='Name'>
        <Text color={recoveryStrategy.name === '' ? 'red' : undefined}>
          {recoveryStrategy.name === '' ? 'unnamed' : recoveryStrategy.name}
        </Text>
      </LabeledValue>
      <LabeledValue label='Recovery Sequence'>
        <RecoveryStrategySequenceDetail
          recoveryStrategy={recoveryStrategy}
          editing={editing}
          setEditing={setEditing}
        />
      </LabeledValue>
    </Flex>
  );
}
