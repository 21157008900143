import { Table, Text } from '@mantine/core';
import QuantizedWeight from '../../Weights/QuantizedWeight';
import { ManualSampleAnalysisDTO } from '../../rest-client';

export function ManualSampleAnalysisTable(props: {
  analysis: ManualSampleAnalysisDTO;
}) {
  const { analysis } = props;
  const measurementMap = new Map(
    Object.entries(analysis.materialClassMeasurements),
  );

  const totalTicks = Object.values(analysis.materialClassMeasurements).reduce(
    (total, m) => total + m.weight.ticks,
    0,
  );

  const measurements = analysis.materialClassSet.materialClasses.map(
    (materialClass) => ({
      materialClass,
      measurement: measurementMap.get(materialClass.id),
    }),
  );

  return (
    <Table withColumnBorders withBorder w='max-content'>
      <thead>
        <tr>
          <th>Material Class</th>
          {measurements.map(({ measurement, materialClass: { id, name } }) => (
            <th key={id}>
              <Text
                weight={measurement ? 'normal' : 'bold'}
                color={measurement ? undefined : 'orange'}
              >
                {name}
              </Text>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Weight</th>
          {measurements.map(({ measurement, materialClass }) => {
            return (
              <td key={materialClass.id} style={{ textAlign: 'right' }}>
                {measurement?.weight ? (
                  <QuantizedWeight weight={measurement.weight} />
                ) : (
                  <Text weight='bold' color='orange'>
                    ?
                  </Text>
                )}
              </td>
            );
          })}
        </tr>
        <tr>
          <th>% Weight</th>
          {measurements.map(({ materialClass, measurement }) => (
            <td key={materialClass.id} style={{ textAlign: 'right' }}>
              {measurement
                ? ((measurement.weight.ticks / totalTicks) * 100).toFixed(1)
                : '?'}
              %
            </td>
          ))}
        </tr>
      </tbody>
    </Table>
  );
}
