import { Table, useMantineTheme } from '@mantine/core';
import dayjs from 'dayjs';
import { match } from 'ts-pattern';
import { ContainerIdName } from '../Container/ContainerIdName.tsx';
import { useFacilityContext } from '../Facility/FacilityContext.tsx';
import { LinkText } from '../Link.tsx';
import { TableEmptyBasicContent } from '../TableEmptyBasicContent.tsx';
import { UnanalyzedSampleDTO } from '../rest-client/index.ts';
import { Router } from '../router.ts';

export function UnanalyzedSamplesTable(props: {
  unanalyzedSamples: UnanalyzedSampleDTO[];
}) {
  const { unanalyzedSamples } = props;
  const facility = useFacilityContext();
  const { spacing } = useMantineTheme();

  const showExternalIdColumn = unanalyzedSamples.some((s) => !!s.externalId);

  return (
    <Table>
      <caption
        style={{
          captionSide: 'bottom',
          textAlign: 'center',
          padding: spacing.md,
        }}
      >
        {unanalyzedSamples.length === 0 && (
          <TableEmptyBasicContent>
            All samples have been analyzed
          </TableEmptyBasicContent>
        )}
      </caption>
      <thead>
        <tr>
          <th style={{ textAlign: 'right' }}>Sample Id</th>
          {showExternalIdColumn ? (
            <th style={{ textAlign: 'right' }}>Sample External Id</th>
          ) : null}
          <th style={{ textAlign: 'right' }}>Sample Taken Time</th>
          <th>Source</th>
        </tr>
      </thead>
      <tbody>
        {unanalyzedSamples.map((sample) => (
          <tr key={sample.sampleId}>
            <td style={{ textAlign: 'right' }}>
              <LinkText
                to={Router.SampleDetail({
                  sampleId: sample.sampleId,
                })}
              >
                {`${sample.numericId}`.padStart(6, '0')}
              </LinkText>
            </td>
            {showExternalIdColumn ? (
              <td style={{ textAlign: 'right' }}>{sample.externalId}</td>
            ) : null}
            <td style={{ textAlign: 'right' }}>
              {match(sample.link)
                .with({ type: 'container' }, (containerLink) =>
                  dayjs
                    .utc(containerLink.sampleTakenTimestamp)
                    .tz(facility.timeZoneId)
                    .format('LLL'),
                )
                .with({ type: 'notional' }, (notionalLink) =>
                  dayjs
                    .utc(notionalLink.effectiveTimestamp)
                    .tz(facility.timeZoneId)
                    .format('LLL'),
                )
                .exhaustive()}
            </td>
            <td>
              {match(sample.link)
                .with({ type: 'container' }, (containerLink) => (
                  <ContainerIdName
                    containerId={containerLink.containerId}
                    time={dayjs.utc(containerLink.sampleTakenTimestamp)}
                  />
                ))
                .with({ type: 'notional' }, () => (
                  <>{/* TODO: Notional link name */}</>
                ))
                .exhaustive()}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
