import { ReactNode, createContext, useContext } from 'react';
import {
  CommoditySpreadMaterialNodeDTO,
  CommoditySpreadRecoveryGoalNodeDTO,
  RecoveryStrategySimulationDTO,
} from '../rest-client';

export interface RecoveryStrategySimulationContext {
  simulation: RecoveryStrategySimulationDTO;
  selectedMaterialClassId: string | null;
  setSelectedMaterialClassId: (id: string | null) => void;
  selectedRecoveryGoalNode: CommoditySpreadRecoveryGoalNodeDTO | null;
  setSelectedRecoveryGoaNode: (
    recoveryGoalNode: CommoditySpreadRecoveryGoalNodeDTO | null,
  ) => void;
  selectedMaterialNode: CommoditySpreadMaterialNodeDTO | null;
  setSelectedMaterialNode: (
    materialNode: CommoditySpreadMaterialNodeDTO | null,
  ) => void;
  feedTotal: number | null;
  setFeedTotal: (total: number | null) => void;
}

const RecoveryStrategySimulationContext = createContext<
  RecoveryStrategySimulationContext | undefined
>(undefined);

export function useRecoveryStrategySimulationCtx(): RecoveryStrategySimulationContext {
  const ctx = useContext(RecoveryStrategySimulationContext);
  if (ctx === undefined) {
    throw new Error(
      'component must be within a recovery strategy simulation context',
    );
  }

  return ctx;
}

export function RecoveryStrategySimulationCtxProvider(
  props: {
    children: ReactNode;
  } & RecoveryStrategySimulationContext,
) {
  const { children, ...value } = props;
  return (
    <RecoveryStrategySimulationContext.Provider value={value}>
      {children}
    </RecoveryStrategySimulationContext.Provider>
  );
}
