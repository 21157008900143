import { match } from 'ts-pattern';
import { WeightUnit } from '../rest-client';

export function weightUnitShorthand(weightUnit: WeightUnit) {
  return match(weightUnit)
    .with(WeightUnit.GRAM, () => 'g')
    .with(WeightUnit.KILOGRAM, () => 'kg')
    .with(WeightUnit.LONG_TON, () => 'lt')
    .with(WeightUnit.POUND, () => 'lb')
    .with(WeightUnit.TONNE, () => 't')
    .with(WeightUnit.SHORT_TON, () => 'st')
    .exhaustive();
}
