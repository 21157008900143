import { ActionIcon, Group, Loader, Stack, Textarea } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { useFacilityContext } from '../Facility/FacilityContext';
import { MutationErrorAlert } from '../Form/MutationErrorAlert';
import { SaveIcon } from '../Icons';
import { RequiredDayjsDateTimePicker } from '../Time/DayjsDateTimePicker';
import {
  usePatchProductionInterval,
  useProductionInterval,
} from '../api/production';
import {
  ProcessPlannedProductionIntervalDTO,
  ProcessPlannedProductionIntervalId,
  ProcessPlannedProductionIntervalPatchDTO,
} from '../rest-client';
import cssClasses from './ProductionIntervalDetail.module.css';

export interface ProductionIntervalDetailProps {
  productionIntervalId: ProcessPlannedProductionIntervalId;
}

export function ProductionIntervalDetail(props: ProductionIntervalDetailProps) {
  const { productionIntervalId } = props;

  const {
    data: productionInterval,
    isLoadingError,
    error,
    isLoading,
  } = useProductionInterval(productionIntervalId);

  if (isLoading) {
    return <Loader />;
  }

  if (isLoadingError) {
    throw error;
  }

  return <EditProductionInterval productionInterval={productionInterval} />;
}

interface EditProductionIntervalProps {
  productionInterval: ProcessPlannedProductionIntervalDTO;
}

function EditProductionInterval(props: EditProductionIntervalProps) {
  const { productionInterval } = props;
  const facility = useFacilityContext();

  const patchMutation = usePatchProductionInterval(
    productionInterval.id,
    facility.id,
  );

  const startTimeDayjs = dayjs
    .utc(productionInterval.startTime)
    .tz(facility.timeZoneId);
  const [startTime, setStartTime] = useState<Dayjs>(startTimeDayjs);

  const endTimeDayjs = dayjs
    .utc(productionInterval.endTime)
    .tz(facility.timeZoneId);
  const [endTime, setEndTime] = useState<Dayjs>(endTimeDayjs);

  const [notes, setNotes] = useState<string | null>(productionInterval.notes);
  const [isDirty, setIsDirty] = useState(false);

  if (patchMutation.isError) {
    return (
      <MutationErrorAlert
        errorTitle='Error Editing Production Shift'
        entityName='Production Shift'
        mutation={patchMutation}
        formVariant='edit'
      />
    );
  }

  return (
    <div>
      <Group noWrap>
        <RequiredDayjsDateTimePicker
          value={startTime}
          onChange={(value) => {
            setStartTime(value);
            setIsDirty(true);
          }}
          disabled={patchMutation.isLoading}
          tz={facility.timeZoneId}
          label='Shift Start'
        />
        <RequiredDayjsDateTimePicker
          value={endTime}
          onChange={(value) => {
            setEndTime(value);
            setIsDirty(true);
          }}
          disabled={patchMutation.isLoading}
          tz={facility.timeZoneId}
          label='Shift End'
        />
        <Stack>
          <ActionIcon
            disabled={patchMutation.isLoading}
            color='green'
            variant='light'
            className={isDirty ? cssClasses.visible : cssClasses.hidden}
            onClick={() => {
              const patch: ProcessPlannedProductionIntervalPatchDTO = {
                startTime: startTime.utc().toISOString(),
                endTime: endTime.utc().toISOString(),
                notes,
              };
              patchMutation.mutate(patch, {
                onError() {
                  showNotification({
                    title: 'Error Saving Production Shift Updates',
                    message:
                      'An error occurred saving the production shift updates.',
                    color: 'red',
                    icon: <IconX />,
                  });
                },
                onSuccess() {
                  showNotification({
                    title: 'Production Shift Updates Saved',
                    message: 'Your production shift updates have been saved.',
                    color: 'green',
                    icon: <IconCheck />,
                  });
                  setIsDirty(false);
                },
              });
            }}
          >
            <SaveIcon />
          </ActionIcon>
          <ActionIcon
            disabled={patchMutation.isLoading}
            color='red'
            variant='light'
            className={isDirty ? cssClasses.visible : cssClasses.hidden}
            onClick={() => {
              setStartTime(startTimeDayjs);
              setEndTime(endTimeDayjs);
              setNotes(productionInterval.notes);
              setIsDirty(false);
            }}
          >
            <IconX />
          </ActionIcon>
        </Stack>
      </Group>

      <Textarea
        label='Notes'
        placeholder='Click to add notes'
        disabled={patchMutation.isLoading}
        value={notes ?? ''}
        onChange={(event) => {
          const newNotes = event.currentTarget.value;
          setNotes(newNotes === '' ? null : newNotes);
          setIsDirty(true);
        }}
        minRows={2}
        w='100%'
      />
    </div>
  );
}
