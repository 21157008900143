import dayjs from 'dayjs';
import { useFacilityContext } from '../Facility/FacilityContext';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import {
  useInternalSinkContainerTransfer,
  usePatchInternalMaterialSinkContainerTransfer,
} from '../api/internalSinkContainerTransfer';
import { useSetFormInitialValuesFromQuery } from '../lib/useSetFormInitialValuesFromQuery';
import {
  InternalSinkContainerTransferId,
  InternalSinkContainerTransferPatchDTO,
  WeightUnit,
} from '../rest-client';
import {
  InternalSinkContainerTransferFormFields,
  InternalSinkContainerTransferFormValues,
  useInternalSinkContainerTransferForm,
} from './InternalSinkContainerTransferForm';

export type EditInternalSinkContainerTransferDrawerFormProps = {
  sinkTransferId: InternalSinkContainerTransferId;
  onSuccess?: (sinkTransfer: InternalSinkContainerTransferPatchDTO) => void;
} & DrawerFormDrawerProps;

export function EditInternalSinkContainerTransferDrawerForm(
  props: EditInternalSinkContainerTransferDrawerFormProps,
) {
  const { sinkTransferId, onSuccess, ...drawerFormDrawerProps } = props;

  const facility = useFacilityContext();
  const {
    data: sinkTransfer,
    isLoadingError,
    error,
  } = useInternalSinkContainerTransfer(sinkTransferId);
  const patchMutation =
    usePatchInternalMaterialSinkContainerTransfer(sinkTransferId);
  const form = useInternalSinkContainerTransferForm({});

  useSetFormInitialValuesFromQuery(
    form,
    sinkTransfer && {
      name: sinkTransfer.name ?? '',
      timestamp: dayjs
        .utc(sinkTransfer.effectiveTimestamp)
        .tz(facility.timeZoneId),
      containerId: sinkTransfer.containerId,
      containerEmptied: sinkTransfer.sourceEmptied,
      internalMaterialSinkId: sinkTransfer.internalSinkId,
      ...(sinkTransfer.netWeightRemoved
        ? {
            netRemovedWeight: {
              enabled: true,
              unit: sinkTransfer.netWeightRemoved.unit,
              tickSize: Number(sinkTransfer.netWeightRemoved.tickSize),
              ticks: Number(sinkTransfer.netWeightRemoved.ticks),
            },
          }
        : {
            netRemovedWeight: {
              enabled: false,
              unit: WeightUnit.POUND,
              tickSize: 1,
              ticks: null,
            },
          }),
    },
  );

  if (isLoadingError) {
    throw error;
  }

  const formValuesToDto = ({
    name,
    timestamp,
    containerId,
    containerEmptied,
    internalMaterialSinkId,
    netRemovedWeight,
  }: InternalSinkContainerTransferFormValues) => {
    if (containerId === null || internalMaterialSinkId === null) {
      throw new Error(
        'Container and material export destination cannot be null',
      );
    }

    const sinkTransferPatch: InternalSinkContainerTransferPatchDTO = {
      ...(form.isDirty('name') && { name }),
      ...(form.isDirty('timestamp') && {
        transferredAt: timestamp.utc().toISOString(),
      }),
      ...(form.isDirty('containerId') && {
        containerId: containerId,
      }),
      ...(form.isDirty('containerEmptied') && {
        sourceEmptied: containerEmptied,
      }),
      ...(form.isDirty('internalMaterialSinkId') && {
        internalSinkId: internalMaterialSinkId,
      }),
      ...((form.isDirty('netRemovedWeight.enabled') ||
        form.isDirty('netRemovedWeight.unit') ||
        form.isDirty('netRemovedWeight.tickSize') ||
        form.isDirty('netRemovedWeight.ticks')) &&
        (netRemovedWeight.enabled &&
        netRemovedWeight.tickSize &&
        netRemovedWeight.unit &&
        netRemovedWeight.ticks
          ? {
              netWeightRemoved: {
                unit: netRemovedWeight.unit,
                tickSize: `${netRemovedWeight.tickSize}`,
                ticks: netRemovedWeight.ticks,
              },
            }
          : {
              netWeightRemoved: null,
            })),
    };

    return sinkTransferPatch;
  };

  return (
    <DrawerForm
      entityName='Material Export'
      form={form}
      formVariant='edit'
      mutation={patchMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      {...drawerFormDrawerProps}
    >
      <InternalSinkContainerTransferFormFields
        form={form}
        sourceContainerSelectVariant='name'
      />
    </DrawerForm>
  );
}
