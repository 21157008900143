import { create } from 'zustand';
import { FacilityDTO } from '../rest-client';

interface FacilityStore {
  facility: FacilityDTO | undefined;
  setFacility: (facility: FacilityDTO) => void;
}

export const useFacilityStore = create<FacilityStore>()((set) => ({
  facility: undefined,
  setFacility: (facility) => {
    set(() => ({ facility }));
  },
}));

export function useSetFacility() {
  return useFacilityStore((s) => s.setFacility);
}
