import { Loader, Select, SelectProps } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useInternalMaterialSources } from '../api/internalMaterialSource';
import { InternalMaterialSourceId } from '../rest-client';

export type InternalMaterialSourceSelectProps = Omit<
  SelectProps,
  'data' | 'value' | 'onChange'
> & {
  value: InternalMaterialSourceId | null;
  onChange: (internalMaterialSourceId: InternalMaterialSourceId | null) => void;
  firstSelectedByDefault: boolean;
};

export function InternalMaterialSourceSelect(
  props: InternalMaterialSourceSelectProps,
) {
  const {
    value,
    onChange,
    firstSelectedByDefault,
    label = 'Upstream Material Source',
    placeholder = 'select upstream material source',
    nothingFound = 'No upstream material sources found',
    clearable = true,
    ...selectProps
  } = props;

  const {
    data: internalMaterialSources,
    isLoading,
    isLoadingError,
    error,
  } = useInternalMaterialSources();
  const [initialized, setIsInitialized] = useState(!firstSelectedByDefault);

  if (isLoadingError) {
    throw error;
  }

  useEffect(() => {
    if (
      !initialized &&
      internalMaterialSources !== undefined &&
      value === null &&
      internalMaterialSources.length > 0
    ) {
      onChange(internalMaterialSources[0].id);
      setIsInitialized(true);
    }
  }, [initialized, internalMaterialSources, onChange, value]);

  const selectData =
    internalMaterialSources?.map((ims) => ({
      label: ims.name,
      value: ims.id,
    })) ?? [];

  return (
    <Select
      value={value}
      onChange={onChange}
      data={selectData}
      label={label}
      rightSection={isLoading ? <Loader size='xs' /> : undefined}
      placeholder={placeholder}
      nothingFound={nothingFound}
      clearable={clearable}
      {...selectProps}
    />
  );
}
