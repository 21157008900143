import { Alert, Button, Flex, Stack } from '@mantine/core';
import { UseMutationResult } from '@tanstack/react-query';
import { match } from 'ts-pattern';

export type MutationErrorAlert<TDTO> = {
  errorTitle: string;
  entityName: string;
  mutation: UseMutationResult<void, unknown, TDTO, unknown>;
  formVariant: 'create' | 'edit' | 'close';
};

export function MutationErrorAlert<TDTO>(props: MutationErrorAlert<TDTO>) {
  const { errorTitle, entityName, mutation, formVariant } = props;

  const alertText = match(formVariant)
    .with(
      'create',
      () =>
        `The ${entityName.toLocaleLowerCase()} you tried to create may or may not have been saved. You can reset the error and try submitting the creation again.`,
    )
    .with(
      'edit',
      () =>
        `The edits to the ${entityName.toLocaleLowerCase()} you tried to make may or may not have been saved. You can reset the error and try submitting the edits again.`,
    )
    .with(
      'close',
      () =>
        `The ${entityName.toLocaleLowerCase()}} could not be closed. You can reset the error and try again.`,
    )
    .exhaustive();

  return (
    <Alert color='red' title={errorTitle}>
      <Stack spacing='xs'>
        {alertText}
        <Flex justify='flex-end'>
          <Button
            color='red'
            variant='filled'
            onClick={() => {
              mutation.reset();
            }}
          >
            Reset Error
          </Button>
        </Flex>
      </Stack>
    </Alert>
  );
}
