import { useMutation, useQuery } from '@tanstack/react-query';
import {
  DefaultCommodityAssignmentCreationDTO,
  DefaultCommodityAssignmentService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchDefaultCommodityAssignmentRecoveryTree(
  ctx: QueryFunctionContexts['defaultCommodityAssignmentRecoveryTree']['detail'],
) {
  const [{ recoveryStrategyId, internalMaterialSourceId }] = ctx.queryKey;
  return await DefaultCommodityAssignmentService.getDefaultCommodityAssignmentRecoveryTree(
    recoveryStrategyId,
    internalMaterialSourceId,
  );
}

export function useDefaultCommodityAssignmentRecoveryTree({
  recoveryStrategyId,
  internalMaterialSourceId,
}: {
  recoveryStrategyId: string;
  internalMaterialSourceId: string;
}) {
  return useQuery({
    queryKey: queryKeys.defaultCommodityAssignmentRecoveryTree.detail(
      recoveryStrategyId,
      internalMaterialSourceId,
    ),
    queryFn: fetchDefaultCommodityAssignmentRecoveryTree,
  });
}

async function fetchDefaultCommodityAssignment(
  ctx: QueryFunctionContexts['defaultCommodityAssignment']['detail'],
) {
  const [{ id }] = ctx.queryKey;
  return await DefaultCommodityAssignmentService.getDefaultCommodityAssignment(
    id,
  );
}

export function useDefaultCommodityAssignment(id: string) {
  return useQuery({
    queryKey: queryKeys.defaultCommodityAssignment.detail(id),
    queryFn: fetchDefaultCommodityAssignment,
  });
}

async function fetchDefaultCommodityAssignments() {
  return await DefaultCommodityAssignmentService.getDefaultCommodityAssignments();
}

export function useDefaultCommodityAssignments() {
  return useQuery({
    queryKey: queryKeys.defaultCommodityAssignment.list(),
    queryFn: fetchDefaultCommodityAssignments,
  });
}

async function createDefaultCommodityAssignment(
  dto: DefaultCommodityAssignmentCreationDTO,
) {
  await DefaultCommodityAssignmentService.createDefaultCommodityAssignment(dto);
}

export function useCreateDefaultCommodityAssignment() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createDefaultCommodityAssignment,
    onSettled() {
      invalidator.invalidateMaterialState();
      invalidator.invalidateKeys(queryKeys.defaultCommodityAssignment.list());
    },
  });
}

async function deleteDefaultCommodityAssignment(id: string) {
  await DefaultCommodityAssignmentService.deleteDefaultCommodityAssignment(id);
}

export function useDeleteDefaultCommodityAssignment() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteDefaultCommodityAssignment,
    onSettled(_d, _e, id) {
      invalidator.invalidateMaterialState();
      invalidator.removeKey(queryKeys.defaultCommodityAssignment.detail(id));
      invalidator.invalidateKeys(queryKeys.defaultCommodityAssignment.list());
    },
  });
}

async function fetchUnassignedCommodityPaths() {
  return await DefaultCommodityAssignmentService.getUnassignedCommodityPaths();
}

export function useUnassignedCommodityPaths() {
  return useQuery({
    queryKey: queryKeys.unassignedCommodityPath.all,
    queryFn: fetchUnassignedCommodityPaths,
  });
}
