import { Loader, Select, SelectProps } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useMaterialClassSets } from '../api/materialClassSet';

type MaterialClassSelectProps = Omit<
  SelectProps,
  'data' | 'value' | 'onChange'
> & {
  value: string | null;
  onChange: (id: string | null) => void;
  firstSelectedByDefault: boolean;
};

// TODO(2323): using this component without a non-null value passed, results in bad setState() call warnings
export function MaterialClassSetSelect(props: MaterialClassSelectProps) {
  const {
    value,
    onChange,
    firstSelectedByDefault,
    label = 'Material Class Set',
    placeholder = 'Select material class set',
    nothingFound = 'No material class sets found',
    rightSection,
    ...rest
  } = props;
  const {
    data: materialClassSets,
    isLoading,
    isLoadingError,
    error,
  } = useMaterialClassSets();
  const [initialized, setIsInitialized] = useState(!firstSelectedByDefault);

  useEffect(() => {
    if (
      !initialized &&
      materialClassSets !== undefined &&
      value === null &&
      materialClassSets.length > 0
    ) {
      onChange(materialClassSets[0].id);
      setIsInitialized(true);
    }
  }, [initialized, materialClassSets, onChange, value]);

  if (isLoadingError) {
    throw error;
  }

  const selectData =
    materialClassSets?.map((mcs) => ({
      label: mcs.name,
      value: mcs.id,
    })) ?? [];

  return (
    <Select
      value={value}
      onChange={onChange}
      label={label}
      placeholder={isLoading ? 'Loading...' : placeholder}
      rightSection={isLoading ? <Loader size='xs' /> : rightSection}
      nothingFound={nothingFound}
      data={selectData}
      {...rest}
    />
  );
}
