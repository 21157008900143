import { useState } from 'react';
import { match } from 'ts-pattern';
import { LinkText } from '../../../Link';
import {
  DateRange,
  plainDateToStartOfDayInstant,
} from '../../../Temporal/dateRangeAndOffset';
import Temporal from '../../../Temporal/temporal';
import { useRecoveryGoalClassificationPerformance } from '../../../api/recoveryGoal';
import { RecoveryGoalClassificationPerformancesDTO } from '../../../rest-client';
import { Router } from '../../../router';
import { formatPercentage } from '../../../util/percentages';
import { useFacilityContext } from '../../FacilityContext';
import { useInsightsSelectedDateRange } from '../DateRange/InsightsDateRange';
import {
  MetricDetailPageTemplate,
  MetricDetailTableColumn,
} from './MetricDetailPageTemplate';

export function getSeriesData(
  queryData: RecoveryGoalClassificationPerformancesDTO[],
) {
  return queryData.map((recoveryGoalClassification) => {
    const recoveryGoalName = recoveryGoalClassification.recoveryGoal.name;
    return {
      name: recoveryGoalName,
      entries:
        recoveryGoalClassification.feedFlowGroupClassificationPerformances.map(
          (feedFlowGroupClassification) => ({
            timestamp: feedFlowGroupClassification.feedFlowGroupStart,
            name: recoveryGoalName,
            value: +(feedFlowGroupClassification.loss * 100).toFixed(1),
          }),
        ),
    };
  });
}

export function MaterialLossMetricPage() {
  const { id: facilityId, timeZoneId: tz } = useFacilityContext();
  const [nowZonedDateTime] = useState(Temporal.Now.zonedDateTimeISO(tz));
  const thirtyDaysAgo = nowZonedDateTime.subtract({ days: 30 });
  const { dateRange, setDateRange } = useInsightsSelectedDateRange({
    startDate: thirtyDaysAgo.toPlainDate(),
    endDate: nowZonedDateTime.toPlainDate(),
    name: 'Last 30 Days',
    id: 'd30',
  });

  const { startDate, endDate } = dateRange;
  const rangeInstants = {
    startInstant: plainDateToStartOfDayInstant(startDate, tz),
    // TODO end of day, probably
    endInstant: plainDateToStartOfDayInstant(endDate, tz),
  };
  const { startInstant, endInstant } = rangeInstants;
  const query = useRecoveryGoalClassificationPerformance({
    after: startInstant,
    before: endInstant,
    facilityId,
  });

  const { seriesData, tableData } = match(query)
    .with({ status: 'loading' }, () => ({
      loading: true,
      error: null,
      seriesData: [],
      tableData: [],
    }))
    .with({ status: 'error' }, (query) => ({
      loading: false,
      error: query.error as Error,
      seriesData: [],
      tableData: [],
    }))
    .with({ status: 'success' }, (query) => ({
      loading: false,
      error: null,
      seriesData: getSeriesData(query.data),
      tableData: query.data,
    }))
    .exhaustive();

  interface RenderColumnArgs {
    classificationPerf: RecoveryGoalClassificationPerformancesDTO;
    dateRange?: DateRange;
  }

  type CommodityLossThroughputColumn = MetricDetailTableColumn & {
    renderTableValue: ({
      classificationPerf,
      dateRange,
    }: Required<RenderColumnArgs>) => JSX.Element;
  };

  const columns: CommodityLossThroughputColumn[] = [
    {
      title: 'Recovery Goal',
      numeric: false,
      renderTableValue: ({
        classificationPerf,
      }: Required<RenderColumnArgs>) => (
        <td key='recovery-goal'>
          <LinkText
            to={Router.FacilityInsightsMetricFacetDetail({
              metricName: 'material-loss',
              facetName: classificationPerf.recoveryGoal.id,
            })}
          >
            {classificationPerf.recoveryGoal.name}
          </LinkText>
        </td>
      ),
    },
    {
      title: 'Average Loss',
      numeric: true,
      renderTableValue: ({ classificationPerf }: RenderColumnArgs) => (
        <td style={{ textAlign: 'right' }} key='avg'>
          {formatPercentage(classificationPerf.avgLoss)}
        </td>
      ),
    },
    {
      title: 'Minimum Loss',
      numeric: true,
      renderTableValue: ({ classificationPerf }: RenderColumnArgs) => (
        <td style={{ textAlign: 'right' }} key='min'>
          {formatPercentage(classificationPerf.minLoss)}
        </td>
      ),
    },
    {
      title: 'Maximum Loss',
      numeric: true,
      renderTableValue: ({ classificationPerf }: RenderColumnArgs) => (
        <td style={{ textAlign: 'right' }} key='max'>
          {formatPercentage(classificationPerf.maxLoss)}
        </td>
      ),
    },
  ];

  const renderTableRow = (
    classificationPerf: RecoveryGoalClassificationPerformancesDTO,
  ) => {
    return (
      <tr key={classificationPerf.recoveryGoal.id}>
        {columns.map((col) => {
          return col.renderTableValue({ classificationPerf, dateRange });
        })}
      </tr>
    );
  };

  return (
    <MetricDetailPageTemplate<RecoveryGoalClassificationPerformancesDTO>
      dateRange={dateRange}
      setDateRange={setDateRange}
      title='Material Loss'
      graphTitle='Material Loss Rates'
      tableTitle='Material Loss by Recovery Goal'
      tableColumns={columns}
      renderTableRow={renderTableRow}
      seriesData={seriesData}
      tableData={tableData}
      query={query}
      yAxisLabel='Loss %'
    />
  );
}
