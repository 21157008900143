import { Select } from '@mantine/core';
import { useEffect } from 'react';
import { useFacilities } from '../api/facilities';
import { LabeledValue } from '../common';
import { useFacilityStore } from './facilityStore';

export function GlobalFacilitySelector() {
  const selectedFacility = useFacilityStore((s) => s.facility);
  const setSelectedFacility = useFacilityStore((s) => s.setFacility);

  const facilitiesQuery = useFacilities();

  useEffect(() => {
    if (
      !selectedFacility &&
      facilitiesQuery.data &&
      facilitiesQuery.data.length > 0
    ) {
      setSelectedFacility(facilitiesQuery.data[0]);
    }
  }, [facilitiesQuery.data, selectedFacility, setSelectedFacility]);

  if (facilitiesQuery.data) {
    const facilities = facilitiesQuery.data;

    if (facilities.length === 0) {
      // This shouldn't happen in practice. Customers should have a provisioned facility or the app won't work at all.
      throw new Error('no facilities');
    }

    if (facilities.length === 1) {
      const [facility] = facilities;

      return <LabeledValue label='Facility'>{facility.name}</LabeledValue>;
    }

    return (
      <Select
        label='Facility'
        value={selectedFacility?.id ?? null}
        data={facilities.map((f) => ({ value: f.id, label: f.name }))}
        onChange={(facilityId) => {
          const facility = facilities.find((f) => f.id === facilityId);
          if (!facility) return;
          setSelectedFacility(facility);
        }}
      />
    );
  }

  if (!selectedFacility && facilitiesQuery.isError) {
    throw new Error('failed to load facilities');
  }

  return null;
}
