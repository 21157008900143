/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ManualSampleAnalysisDTO } from '../models/ManualSampleAnalysisDTO';
import type { SampleAnalysesByMaterialClassSetDTO } from '../models/SampleAnalysesByMaterialClassSetDTO';
import type { SamplingSuiteSampleAnalysisDTO } from '../models/SamplingSuiteSampleAnalysisDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SampleAnalysisService {
    /**
     * @param sampleId
     * @returns any Success
     * @throws ApiError
     */
    public static getSampleAnalyses(
        sampleId?: string,
    ): CancelablePromise<Array<(ManualSampleAnalysisDTO | SamplingSuiteSampleAnalysisDTO)>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sample-analyses',
            query: {
                'sampleId': sampleId,
            },
        });
    }
    /**
     * @returns SampleAnalysesByMaterialClassSetDTO Success
     * @throws ApiError
     */
    public static getSampleAnalysesByMaterialClassSet(): CancelablePromise<SampleAnalysesByMaterialClassSetDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sample-analyses/by-material-class-set',
        });
    }
}
