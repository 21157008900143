import { useScale } from '../api/scale';
import { ScaleId, WeightUnit } from '../rest-client';
import QuantizedWeightTicksInput, {
  QuantizedWeightTicksInputProps,
} from './QuantizedWeightTicksInput';

export type ScaleDisplayDivisionsInputProps = Omit<
  QuantizedWeightTicksInputProps,
  'tickSize' | 'unit'
> & {
  scaleId: ScaleId | null;
};

export default function ScaleDisplayDivisionsInput(
  props: ScaleDisplayDivisionsInputProps,
) {
  const { scaleId, disabled, placeholder = 'weight', ...rest } = props;

  const {
    data: scale,
    isLoading,
    isLoadingError,
    error,
  } = useScale(scaleId ?? undefined);

  if (isLoadingError) {
    throw error;
  }

  return (
    <QuantizedWeightTicksInput
      unit={scale?.unit ?? WeightUnit.POUND}
      tickSize={scale?.displayDivision ?? 1}
      disabled={isLoading || disabled}
      placeholder={isLoading ? 'Loading...' : placeholder}
      {...rest}
    />
  );
}
