import {
  ActionIcon,
  Alert,
  Button,
  Group,
  Select,
  Stack,
  Table,
  Text,
} from '@mantine/core';
import {
  IconChevronLeft,
  IconChevronRight,
  IconReload,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import { ReactNode, useCallback, useEffect, useRef } from 'react';
import { P, match } from 'ts-pattern';
import { z } from 'zod';
import { useFacilityContext } from '../Facility/FacilityContext';
import Temporal from '../Temporal/temporal.ts';
import { DayjsDateInput } from '../Time/DayjsDateTimePicker';
import {
  useInventoryLedgerCalendar,
  useInventoryLedgerEventHistorySegment,
} from '../api/transaction';
import { getLedgerEventKey } from '../util/transactionKey';
import {
  RouteParams,
  usePersistentRouteParams,
} from '../util/useRouteParams.ts';
import cssClasses from './InventoryLedger.module.css';
import { InventoryLedgerEmptyState } from './InventoryLedgerEmptyState';
import { InventoryLedgerErrorExplanationRow } from './InventoryLedgerErrorExplanationRow';
import { InventoryLedgerEventRow } from './InventoryLedgerEventRow';
import { InventoryLedgerSkeletonRow } from './InventoryLedgerSkeletonRow';
import { InventoryLedgerStatus } from './InventoryLedgerStatus.tsx';
import { InventoryLedgerStatusProvider } from './LedgerStatusContext';

const ROUTE_NAME = 'LedgerHistory';

const InventoryLedgerTimeRangeSchema = z.union([
  z.literal('week'),
  z.literal('day'),
]);
type InventoryLedgerTimeRange = z.infer<typeof InventoryLedgerTimeRangeSchema>;

const timeRangeSelectValues = [
  { value: 'week', label: 'Week' },
  { value: 'day', label: 'Day' },
] as const;
function TimeRangeSelect(props: {
  value: InventoryLedgerTimeRange;
  onChange: (range: InventoryLedgerTimeRange) => void;
}) {
  const { value, onChange } = props;
  return (
    <Select
      value={value}
      onChange={(tr) =>
        tr && onChange(InventoryLedgerTimeRangeSchema.parse(tr))
      }
      data={timeRangeSelectValues}
    />
  );
}

export function InventoryLedger() {
  const { timeZoneId: tz } = useFacilityContext();

  const [{ date, range, entryId }, updateRouteParamsRaw] =
    usePersistentRouteParams([ROUTE_NAME]);

  const updateRouteParams = useCallback(
    (
      routeParams: Omit<RouteParams['LedgerHistory'], 'date'> & {
        date?: Temporal.PlainDate;
      },
    ) => {
      const {
        entryId: entryIdParam,
        date: dateParam,
        ...otherParams
      } = routeParams;
      const date = dateParam ? dateParam.toString() : undefined;
      const entryId = !date || !entryIdParam ? undefined : entryIdParam;

      const params = {
        ...otherParams,
        date,
        entryId,
      };
      return updateRouteParamsRaw(params);
    },
    [updateRouteParamsRaw],
  );

  const todayPlainDate = Temporal.Now.instant()
    .toZonedDateTimeISO(tz)
    .toPlainDate();
  const selectedDay = date ? Temporal.PlainDate.from(date) : todayPlainDate;
  const initialTimeRangeParse = InventoryLedgerTimeRangeSchema.safeParse(range);

  const timeRange = initialTimeRangeParse.success
    ? initialTimeRangeParse.data
    : 'day';

  const { year, month, day } = selectedDay;

  const startOfSelectedDay = Temporal.ZonedDateTime.from({
    year,
    month,
    day,
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
    timeZone: tz,
  });
  const startOfNextDay = startOfSelectedDay.add({ days: 1 });
  const startOfThisWeek = startOfSelectedDay.subtract({
    days: selectedDay.dayOfWeek,
  });
  const endOfThisWeek = startOfThisWeek.add({ days: 7 });

  const { start, end } = match(timeRange)
    .with('day', () => ({
      start: startOfSelectedDay,
      end: startOfNextDay,
    }))
    .with('week', () => ({
      start: startOfThisWeek,
      end: endOfThisWeek,
    }))
    .exhaustive();

  const eventHistorySegmentQuery = useInventoryLedgerEventHistorySegment({
    startTime: dayjs.tz(
      start.toString({ offset: 'never', timeZoneName: 'never' }),
      tz,
    ),
    endTime: dayjs.tz(
      end.toString({ offset: 'never', timeZoneName: 'never' }),
      tz,
    ),
  });
  const ledgerCalendarQuery = useInventoryLedgerCalendar();

  const lastEvent: Temporal.ZonedDateTime | undefined = match(
    ledgerCalendarQuery,
  )
    .with(
      { data: { latestEventTime: P.string } },
      ({ data: { latestEventTime } }) =>
        Temporal.Instant.from(latestEventTime).toZonedDateTimeISO(tz),
    )
    .with({ status: 'loading' }, { status: 'error' }, () => undefined)
    .exhaustive();

  const tableRef = useRef<HTMLTableElement | null>(null);
  useEffect(() => {
    if (entryId && tableRef.current !== null) {
      tableRef.current.querySelector(`#entry-${entryId}`)?.scrollIntoView();
    }
  }, [entryId, tableRef, date, range, eventHistorySegmentQuery.data]);

  let eventRows: ReactNode[];
  if (eventHistorySegmentQuery.data) {
    eventRows = eventHistorySegmentQuery.data.map((txn) => (
      <InventoryLedgerEventRow key={getLedgerEventKey(txn)} event={txn} />
    ));
  } else if (eventHistorySegmentQuery.isLoading) {
    eventRows = [
      <InventoryLedgerSkeletonRow key={-6} />,
      <InventoryLedgerSkeletonRow key={-5} />,
      <InventoryLedgerSkeletonRow key={-4} />,
      <InventoryLedgerSkeletonRow key={-3} />,
      <InventoryLedgerSkeletonRow key={-2} />,
    ];
  } else {
    eventRows = [
      <InventoryLedgerErrorExplanationRow key={-1}>
        <Alert color='red' title='Error Loading Transaction History'>
          <Stack>
            <Text>
              An error occurred loading the inventory ledger for the selected
              period.
            </Text>
            <Button
              leftIcon={<IconReload />}
              onClick={() => void eventHistorySegmentQuery.refetch()}
            >
              Try Reloading
            </Button>
          </Stack>
        </Alert>
      </InventoryLedgerErrorExplanationRow>,
    ];
  }

  // TODO(1809): Indicate background fetch error subtly

  return (
    <InventoryLedgerStatusProvider>
      <Stack className={cssClasses.wrapper}>
        <Group spacing='xs'>
          <ActionIcon
            variant='default'
            onClick={() =>
              updateRouteParams({
                date: selectedDay.subtract({ [`${timeRange}s`]: 1 }),
              })
            }
          >
            <IconChevronLeft size={16} />
          </ActionIcon>
          <DayjsDateInput
            value={dayjs.tz(selectedDay.toString(), tz)}
            onChange={(d) =>
              updateRouteParams({
                date: d
                  ? Temporal.Instant.from(d.toISOString())
                      .toZonedDateTimeISO('UTC')
                      .toPlainDate()
                  : undefined,
              })
            }
            valueFormat='L'
            tz={tz}
          />
          <ActionIcon
            variant='default'
            onClick={() =>
              updateRouteParams({
                date: selectedDay.add({ [`${timeRange}s`]: 1 }),
              })
            }
          >
            <IconChevronRight size={16} />
          </ActionIcon>
          <TimeRangeSelect
            value={timeRange}
            onChange={(range) => updateRouteParams({ range })}
          />
          <Button
            onClick={() =>
              updateRouteParams({
                date: todayPlainDate,
              })
            }
            variant='default'
          >
            Today
          </Button>
        </Group>
        <InventoryLedgerStatus findErrorEntry={updateRouteParams} />
        <Table ref={tableRef}>
          <thead>
            <tr>
              {/* Status columns */}
              <th style={{ padding: 0 }}></th>
              <th>Date</th>
              <th>Event Type</th>
              <th>Source</th>
              <th>Destination</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {eventRows.length > 0 ? (
              eventRows
            ) : (
              <InventoryLedgerEmptyState
                start={start}
                end={end}
                lastEvent={lastEvent}
                findLedgerEntry={updateRouteParams}
              />
            )}
          </tbody>
        </Table>
      </Stack>
    </InventoryLedgerStatusProvider>
  );
}
