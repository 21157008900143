import { Group, Text } from '@mantine/core';
import { IconMinus, IconPlus } from '@tabler/icons-react';
import { BasicMaterialNodeDTO } from '../rest-client';

export function BasicMaterialNodeBody(materialNode: BasicMaterialNodeDTO) {
  return (
    <Group spacing='xs'>
      {materialNode.isNegative ? <IconMinus /> : <IconPlus />}
      <Text>Fraction</Text>
    </Group>
  );
}
