import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import {
  useInternalMaterialSource,
  usePatchInternalMaterialSource,
} from '../api/internalMaterialSource';
import { useSetFormInitialValuesFromQuery } from '../lib/useSetFormInitialValuesFromQuery';
import {
  InternalMaterialSourceId,
  InternalMaterialSourcePatchDTO,
} from '../rest-client';
import {
  InternalMaterialSourceFormFields,
  InternalMaterialSourceFormValues,
  useInternalMaterialSourceForm,
} from './InternalMaterialSourceForm';

export type EditInternalMaterialSourceDrawerFormProps = {
  internalMaterialSourceId: InternalMaterialSourceId;
  onSuccess?: (internalMaterialSource: InternalMaterialSourcePatchDTO) => void;
} & DrawerFormDrawerProps;

export function EditInternalMaterialSourceDrawerForm(
  props: EditInternalMaterialSourceDrawerFormProps,
) {
  const { internalMaterialSourceId, onSuccess, ...drawerFormDrawerProps } =
    props;

  const {
    data: internalMaterialSource,
    isLoadingError,
    error,
  } = useInternalMaterialSource(internalMaterialSourceId);
  const patchMutation = usePatchInternalMaterialSource(
    internalMaterialSourceId,
  );
  const form = useInternalMaterialSourceForm();

  useSetFormInitialValuesFromQuery(
    form,
    internalMaterialSource && {
      name: internalMaterialSource.name,
      facilityId: internalMaterialSource.facilityId,
      commodityId: internalMaterialSource.commodityId ?? null,
    },
  );

  if (isLoadingError) {
    throw error;
  }

  const formValuesToDto = ({
    name,
    commodityId,
  }: InternalMaterialSourceFormValues) => {
    if (commodityId === null) {
      throw new Error('Commodity cannot be null');
    }

    const internalMaterialSourcePatch: InternalMaterialSourcePatchDTO = {
      ...(form.isDirty('name') && { name }),
      ...(form.isDirty('commodityId') && { commodityId }),
    };

    return internalMaterialSourcePatch;
  };

  return (
    <DrawerForm
      entityName='Upstream Material Source'
      form={form}
      formVariant='edit'
      mutation={patchMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      {...drawerFormDrawerProps}
    >
      <InternalMaterialSourceFormFields form={form} />
    </DrawerForm>
  );
}
