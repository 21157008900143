import {
  Burger,
  Group,
  Header,
  MediaQuery,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useNavbarStore } from './AppNavbar';
import { GlobalFacilitySelector } from './GlobalFacilitySelector';

export function AppHeader() {
  const theme = useMantineTheme();

  const navbarOpened = useNavbarStore((s) => s.opened);
  const toggleOpen = useNavbarStore((s) => s.toggleOpen);

  return (
    <Header height={{ base: 50, md: 60 }} p='md' withBorder>
      <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <MediaQuery largerThan='sm' styles={{ display: 'none' }}>
          <Burger
            opened={navbarOpened}
            onClick={toggleOpen}
            size='sm'
            color={theme.colors.gray[6]}
            mr='xl'
          />
        </MediaQuery>

        <Group position='apart' w='100%'>
          <div>
            <Title color='teal'>VALIS</Title>
          </div>
          <div>
            <GlobalFacilitySelector />
          </div>
        </Group>
      </div>
    </Header>
  );
}
