import {
  ActionIcon,
  ActionIconProps,
  Alert,
  Box,
  Button,
  Center,
  Checkbox,
  CheckboxProps,
  Divider,
  Drawer,
  Flex,
  Group,
  Kbd,
  Loader,
  Modal,
  ModalProps,
  Pagination,
  Paper,
  Progress,
  Radio,
  Skeleton,
  Stack,
  Switch,
  Table,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import {
  IconAlertHexagon,
  IconArchive,
  IconArrowLeft,
  IconArrowRight,
  IconBroadcast,
  IconBroadcastOff,
  IconCamera,
  IconCheck,
  IconChecks,
  IconCloudDownload,
  IconCloudUpload,
  IconHelpCircle,
  IconMaximize,
  IconPlayerStop,
  IconRefresh,
  IconScale,
  IconSettings,
  IconX,
} from '@tabler/icons-react';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { match } from 'ts-pattern';
import { create } from 'zustand';
import { queryKeys, useQueryKeyInvalidator } from '../api/queryKeys';
import { useSamplingSessionSignalRStore } from '../api/samplingSessionSignalR';
import {
  usePatchSamplingSuiteCapture,
  useSamplingSuiteCaptures,
} from '../api/samplingSuite';
import {
  useCloseSamplingSuiteSession,
  usePatchSamplingSuiteSession,
  useSamplingSuiteSession,
} from '../api/samplingSuiteSession';
import { AppPage } from '../App/AppPage';
import { MutationErrorAlert } from '../Form/MutationErrorAlert';
import { LinkButton } from '../Link';
import {
  MaterialClassStateDTO,
  SamplingSessionEndReason,
  SamplingSuiteCaptureDTO,
  SamplingSuiteCapturePatchDTO,
  SamplingSuiteSessionDTO,
  WeightUnit,
} from '../rest-client';
import { Router } from '../router';
import { useLowResCaptureObjectUrl } from '../samplingSuiteCaptureImageCache';
import NetWeight, { NetWeightZero } from '../Weights/NetWeight';
import { CaptureLightbox } from './CaptureLightbox';
import classes from './SamplingSuiteSession.module.css';

function SessionSignalRConnectivityStatusIndicator() {
  const state = useSamplingSessionSignalRStore((s) => s.state);
  const invalidator = useQueryKeyInvalidator();

  return match(state)
    .with('connected', () => (
      <Title color='teal'>
        <Tooltip label='Live Connection Established'>
          <IconBroadcast size={'1em'} style={{ marginBottom: '-.2em' }} />
        </Tooltip>
      </Title>
    ))
    .with('connecting', () => (
      <Tooltip label='Connecting...'>
        <Loader color='blue' />
      </Tooltip>
    ))
    .with('reconnecting', () => (
      <Tooltip label='Reconnecting...'>
        <Loader color='yellow' />
      </Tooltip>
    ))
    .with('disconnected', () => (
      <Title color='red'>
        <Group noWrap>
          <Tooltip label='Failed to Establish Live Connection'>
            <IconBroadcastOff size={'1em'} style={{ marginBottom: '-.2em' }} />
          </Tooltip>
          <ActionIcon
            onClick={() =>
              invalidator.invalidateKey(queryKeys.samplingSession.all)
            }
          >
            <IconRefresh />
          </ActionIcon>
        </Group>
      </Title>
    ))
    .exhaustive();
}

export function SamplingSuiteSessionPage(props: { sessionId: string }) {
  const { sessionId } = props;

  return (
    <AppPage
      title='VALI-Sample Session'
      titleRight={
        // TODO: Show connectivity status
        <Group align='flex-end' w='100%'>
          <SessionSignalRConnectivityStatusIndicator />
          <SessionSettingsButton sessionId={sessionId} />
          <HelpButton />
          <CaptureArchiveDrawer />
        </Group>
      }
    >
      <SamplingSuiteSession sessionId={sessionId} />
    </AppPage>
  );
}

function SessionSettingsButton(props: { sessionId: string }) {
  const { sessionId } = props;
  const sessionQuery = useSamplingSuiteSession(sessionId);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {sessionQuery.data && (
        <SessionSettingsModal
          session={sessionQuery.data}
          onClose={() => setIsOpen(false)}
          opened={isOpen}
        />
      )}
      <Button
        variant='outline'
        leftIcon={<IconSettings />}
        color='gray'
        loading={sessionQuery.data === undefined}
        onClick={() => setIsOpen(true)}
      >
        Session Settings
      </Button>
    </>
  );
}

function SyncStatus() {
  const isMutating = useIsMutating();
  const isFetching = useIsFetching();

  return (
    <Group align='flex-end' ml='auto' className={classes.stickyTool}>
      <IconCloudUpload
        strokeWidth={2}
        className={`${classes.mutatingIcon} ${isMutating ? classes.activeCloudIcon : ''}`}
      />
      <IconCloudDownload
        strokeWidth={2}
        className={`${classes.fetchingIcon} ${isFetching ? classes.activeCloudIcon : ''}`}
      />
    </Group>
  );
}

function SessionSettingsModal(
  props: { session: SamplingSuiteSessionDTO } & ModalProps,
) {
  const { session, ...modalProps } = props;

  return (
    <Modal title='Session Settings' {...modalProps}>
      <Stack>
        <Divider />
        <SessionSettingsCluster session={session} />
        <Divider />
        <InstanceActionCluster />
      </Stack>
    </Modal>
  );
}

function HelpButton() {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Button
        color='blue'
        leftIcon={<IconHelpCircle />}
        onClick={() => setOpened(true)}
      >
        Info
      </Button>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title='VALI-Sample Session Info'
      >
        <Title order={4}>Keyboard Shortcuts</Title>
        <Table>
          <thead>
            <tr>
              <th>Key</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Kbd>s</Kbd>
              </td>
              <td>Skip selected material class (toggle)</td>
            </tr>
            <tr>
              <td>
                <Kbd>↓</Kbd>
              </td>
              <td>Select next material class</td>
            </tr>
            <tr>
              <td>
                <Kbd>↑</Kbd>
              </td>
              <td>Select previous material class</td>
            </tr>
          </tbody>
        </Table>
      </Modal>
    </>
  );
}

function ArchivedCapture(props: { capture: SamplingSuiteCaptureDTO }) {
  const { capture } = props;

  const patchMutation = usePatchSamplingSuiteCapture();

  return (
    <CapturePreview
      capture={capture}
      loading={!patchMutation.isIdle}
      leftButtonProps={{
        onClick: () => {
          patchMutation.mutate({
            capture,
            patch: {
              archived: false,
            },
          });
        },
        color: 'blue',
        label: 'restore',
      }}
      onMaximize={() => {
        // TODO: Open lightbox of archived captures
      }}
    />
  );
}

function CaptureArchiveDrawer() {
  const archivedCapturesQuery = useSamplingSuiteCaptures({
    unlinked: true,
    suiteId: null,
    archived: true,
  });
  const [opened, setOpened] = useState(false);

  if (archivedCapturesQuery.data) {
    const captures = archivedCapturesQuery.data;

    if (captures.length > 0) {
      return (
        <>
          <Button
            ml='auto'
            variant='outline'
            color='gray'
            leftIcon={<IconArchive />}
            onClick={() => setOpened(true)}
          >
            Restore Captures
          </Button>
          <Drawer
            title='Archived Captures'
            opened={opened}
            onClose={() => setOpened(false)}
            position='right'
          >
            <Stack align='center'>
              {captures.map((capture) => (
                <ArchivedCapture key={capture.captureId} capture={capture} />
              ))}
            </Stack>
          </Drawer>
        </>
      );
    } else {
      if (opened) {
        setOpened(false);
      }
    }
  }
}

type LightboxArea = 'assigned' | 'unassigned' | 'unlinked';

interface CaptureLightboxStore {
  opened: false | LightboxArea;
  captureId: string | undefined;
  select: (captureId: string | undefined) => void;
  open: (area: LightboxArea, captureId?: string) => void;
  close: () => void;
}

const useCaptureLightboxStore = create<CaptureLightboxStore>()((set) => ({
  opened: false,
  captureId: undefined,
  select: (captureId) => {
    set({ captureId });
  },
  open: (area, captureId) => {
    set(({ captureId: prevCaptureId }) => ({
      opened: area,
      captureId: captureId ?? prevCaptureId,
    }));
  },
  close: () => {
    set(() => ({
      opened: false,
    }));
  },
}));

function AssignedCapturesLightbox(props: { session: SamplingSuiteSessionDTO }) {
  const { session } = props;

  const opened = useCaptureLightboxStore((s) => s.opened);
  const select = useCaptureLightboxStore((s) => s.select);
  const onClose = useCaptureLightboxStore((s) => s.close);
  const captureId = useCaptureLightboxStore((s) => s.captureId);

  return (
    <CaptureLightbox
      captures={[
        ...session.materialClassStates.flatMap((s) => s.assignedCaptures),
      ]}
      selectedCaptureId={captureId}
      onCaptureSelect={select}
      titleFn={(capture) => {
        const state = session.materialClassStates.find((s) =>
          s.assignedCaptures.some((c) => c.captureId === capture.captureId),
        );
        if (state === undefined) return null;
        return state.materialClassName ?? 'Whole Sample';
      }}
      opened={opened === 'assigned'}
      onClose={onClose}
    />
  );
}

function UnassignedCapturesLightbox(props: {
  session: SamplingSuiteSessionDTO;
}) {
  const { session } = props;

  const opened = useCaptureLightboxStore((s) => s.opened);
  const select = useCaptureLightboxStore((s) => s.select);
  const onClose = useCaptureLightboxStore((s) => s.close);
  const captureId = useCaptureLightboxStore((s) => s.captureId);

  return (
    <CaptureLightbox
      captures={session.linkedCaptures}
      selectedCaptureId={captureId}
      onCaptureSelect={select}
      titleFn={() => 'Staged Capture'}
      opened={opened === 'unassigned'}
      onClose={onClose}
    />
  );
}

function UnlinkedCapturesLightbox(props: {
  unlinkedCaptures: SamplingSuiteCaptureDTO[];
}) {
  const { unlinkedCaptures } = props;

  const opened = useCaptureLightboxStore((s) => s.opened);
  const select = useCaptureLightboxStore((s) => s.select);
  const onClose = useCaptureLightboxStore((s) => s.close);
  const captureId = useCaptureLightboxStore((s) => s.captureId);

  return (
    <CaptureLightbox
      captures={unlinkedCaptures}
      selectedCaptureId={captureId}
      onCaptureSelect={select}
      titleFn={() => 'Unlinked Capture'}
      opened={opened === 'unlinked'}
      onClose={onClose}
    />
  );
}

function SamplingSuiteSession(props: { sessionId: string }) {
  const { sessionId } = props;
  const sessionQuery = useSamplingSuiteSession(sessionId);

  if (sessionQuery.data) {
    const session = sessionQuery.data;

    if (session.endDetails != null) {
      const returnToSampleButton = (
        <LinkButton
          replace
          to={Router.SampleDetail({ sampleId: session.sampleId })}
        >
          Return to the sample
        </LinkButton>
      );

      return match(session.endDetails.endReason)
        .with(SamplingSessionEndReason.ANALYSIS_COMPLETED, () => (
          <Alert
            color='teal'
            title='Session Complete'
            withCloseButton
            onClose={() =>
              Router.replace('SampleDetail', { sampleId: session.sampleId })
            }
          >
            This session was ended because the analysis was completed
            succesfully.
            {returnToSampleButton}
          </Alert>
        ))
        .with(SamplingSessionEndReason.CLOSED_WITHOUT_COMPLETING, () => (
          <Alert
            color='yellow'
            title='Session Closed'
            withCloseButton
            onClose={() =>
              Router.replace('SampleDetail', { sampleId: session.sampleId })
            }
          >
            This session was closed without completing the analysis.
            {/* TODO: Button to create a new session with state copied from previous session, if it is also the most recent */}
            {returnToSampleButton}
          </Alert>
        ))
        .with(SamplingSessionEndReason.FORCEFULLY_CLOSED, () => (
          <Alert
            color='orange'
            title='Session Closed by Another User'
            withCloseButton
            onClose={() =>
              Router.replace('SampleDetail', { sampleId: session.sampleId })
            }
          >
            This session was closed because another user started a session while
            this one was still open.
            {/* TODO: Button to create a new session with state copied from previous session, if it is also the most recent */}
            {returnToSampleButton}
          </Alert>
        ))
        .exhaustive();
    }

    return (
      <>
        <Group
          spacing='sm'
          align='flex-start'
          noWrap
          className={classes.topLevel}
        >
          <AssignedCaptureTable session={session} />

          <Paper className={classes.stickyTool} p='sm' withBorder>
            <Stack align='center'>
              <Title order={3}>Staged</Title>
              <LinkedCaptureTable session={session} />
            </Stack>
          </Paper>

          <UnlinkedCaptureSection session={session} />

          <SyncStatus />
        </Group>

        <AssignedCapturesLightbox session={session} />
        <UnassignedCapturesLightbox session={session} />
      </>
    );
  }

  if (sessionQuery.error) {
    throw sessionQuery.error;
  }

  return (
    <Center>
      <Stack align='center' justify='center'>
        <Loader variant='bars' size='xl' />
        <Text c='dimmed'>Loading VALI-Sample session...</Text>
      </Stack>
    </Center>
  );
}

function SessionSettingsCluster(props: { session: SamplingSuiteSessionDTO }) {
  const { session } = props;
  return (
    <Stack>
      <AutoAssignSwitch session={session} />
      <AutoProceedSwitch session={session} />
    </Stack>
  );
}

function AutoAssignSwitch(props: { session: SamplingSuiteSessionDTO }) {
  const { session } = props;

  const patchMutation = usePatchSamplingSuiteSession();

  return (
    <Switch
      checked={session.autoAssign}
      onChange={(e) => {
        if (!patchMutation.isIdle) return;
        patchMutation.mutate(
          {
            sessionId: session.id,
            patch: {
              autoAssign: e.currentTarget.checked,
            },
          },
          {
            onSettled() {
              patchMutation.reset();
            },
          },
        );
      }}
      label='Assign new captures to active row'
    />
  );
}

function AutoProceedSwitch(props: { session: SamplingSuiteSessionDTO }) {
  const { session } = props;

  const patchMutation = usePatchSamplingSuiteSession();

  return (
    <Switch
      label='Advance active material class after a capture is assigned'
      checked={session.autoProceed}
      onChange={(e) => {
        if (!patchMutation.isIdle) return;
        patchMutation.mutate(
          {
            sessionId: session.id,
            patch: {
              autoProceed: e.currentTarget.checked,
            },
          },
          {
            onSettled() {
              patchMutation.reset();
            },
          },
        );
      }}
    />
  );
}

function AssignedCaptureTable(props: { session: SamplingSuiteSessionDTO }) {
  const { session } = props;

  const patchMutation = usePatchSamplingSuiteSession();

  const stateSequence = session.materialClassStates.map(
    (s) => s.materialClassId,
  );
  const currIdx = stateSequence.indexOf(session.activeMaterialClassId);
  const nextIdx = Math.min(currIdx + 1, stateSequence.length - 1);
  const prevIdx = Math.max(currIdx - 1, 0);
  const nextMaterialClassId = stateSequence[nextIdx];
  const prevMaterialClassId = stateSequence[prevIdx];

  useHotkeys([
    [
      's',
      () => {
        if (!patchMutation.isIdle) return;
        const currentlyIsEmpty =
          session.materialClassStates.find(
            (s) => s.materialClassId === session.activeMaterialClassId,
          )?.isEmpty ?? false;

        return patchMutation.mutate(
          {
            sessionId: session.id,
            patch: {
              emptinessChanges: [
                {
                  isEmpty: !currentlyIsEmpty,
                  materialClassId: session.activeMaterialClassId,
                },
              ],
            },
          },
          {
            onSettled() {
              patchMutation.reset();
            },
          },
        );
      },
    ],
    [
      'ArrowUp',
      () => {
        if (!patchMutation.isIdle) return;
        if (prevIdx === currIdx) return;
        patchMutation.mutate(
          {
            sessionId: session.id,
            patch: {
              activeMaterialClassId: prevMaterialClassId,
            },
          },
          {
            onSettled() {
              patchMutation.reset();
            },
          },
        );
      },
    ],
    [
      'ArrowDown',
      () => {
        if (!patchMutation.isIdle) return;
        if (nextIdx === currIdx) return;
        patchMutation.mutate(
          {
            sessionId: session.id,
            patch: {
              activeMaterialClassId: nextMaterialClassId,
            },
          },
          {
            onSettled() {
              patchMutation.reset();
            },
          },
        );
      },
    ],
  ]);

  const materialClassRows = session.materialClassStates.map((state) => (
    <MaterialClassRow
      key={state.materialClassId}
      session={session}
      state={state}
      active={session.activeMaterialClassId === state.materialClassId}
    />
  ));

  const headerRow = (
    <div key='header' className={classes.tableHeader}>
      <Text size='lg' weight={600} px='xs' align='center'>
        Class
      </Text>
      <Text size='lg' weight={600} px='xs' align='center'>
        Empty
      </Text>
      <Text size='lg' weight={600} px='xs' align='center'>
        Captures
      </Text>
      <Text size='lg' weight={600} px='xs' align='right'>
        Weight
      </Text>
    </div>
  );

  const bottomRow = <BottomRow key='bottom' session={session} />;

  const rows = [headerRow, ...materialClassRows, bottomRow];

  return (
    <Box className={classes.table} sx={{ gridTemplateRows: `` }}>
      {rows}
    </Box>
  );
}

function EmptySelect(props: CheckboxProps & { hasCaptures: boolean }) {
  const { hasCaptures, ...checkboxProps } = props;
  const SkipCheckboxIcon: CheckboxProps['icon'] = ({
    className,
  }: {
    className: string;
  }) => <IconX className={className} />;

  const { checked } = checkboxProps;
  return (
    <Checkbox
      size='md'
      color={checked && hasCaptures ? 'red' : 'yellow'}
      icon={SkipCheckboxIcon}
      {...checkboxProps}
    />
  );
}

function MaterialClassRow(props: {
  session: SamplingSuiteSessionDTO;
  state: MaterialClassStateDTO;
  active: boolean;
}) {
  const { session, state, active } = props;

  const activeMutation = usePatchSamplingSuiteSession();

  const activate = () => {
    if (active) return;
    if (!activeMutation.isIdle) return;
    activeMutation.mutate(
      {
        sessionId: session.id,
        patch: {
          activeMaterialClassId: state.materialClassId,
        },
      },
      {
        onSettled() {
          activeMutation.reset();
        },
      },
    );
  };

  const emptyMutation = usePatchSamplingSuiteSession();

  const classNames = [classes.row];
  classNames.push(state.isEmpty ? classes.empty : classes.nonEmpty);
  classNames.push(active ? classes.activeRow : classes.inactiveRow);
  classNames.push(
    state.assignedCaptures.length > 0
      ? classes.hasCaptures
      : classes.noCaptures,
  );

  const rowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!rowRef.current) return;
    if (active) {
      rowRef.current.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  }, [active]);

  return (
    <div className={classNames.join(' ')}>
      <Flex
        sx={{ scrollMargin: '12rem' }}
        ref={rowRef}
        align='center'
        p='xs'
        onClick={activate}
        className={state.isEmpty ? undefined : classes.activator}
        gap='xs'
      >
        <Radio color='blue' readOnly size='md' checked={active} />
        <Text className={classes.rowName}>
          {state.materialClassName ?? 'Whole Sample'}
        </Text>
      </Flex>
      <Flex align='center' justify='center'>
        <EmptySelect
          hasCaptures={state.assignedCaptures.length > 0}
          checked={state.isEmpty}
          onChange={(e) => {
            if (!emptyMutation.isIdle) return;
            emptyMutation.mutate(
              {
                sessionId: session.id,
                patch: {
                  emptinessChanges: [
                    {
                      materialClassId: state.materialClassId,
                      isEmpty: e.currentTarget.checked,
                    },
                  ],
                },
              },
              {
                onSettled() {
                  emptyMutation.reset();
                },
              },
            );
          }}
        />
      </Flex>
      <Flex
        wrap='nowrap'
        align='center'
        sx={{ transition: 'height 200ms ease' }}
        gap='xs'
        p='xs'
      >
        {state.assignedCaptures.map((capture) => (
          <AssignedCapture
            key={capture.captureId}
            capture={capture}
            session={session}
          />
        ))}
        {state.assignedCaptures.length === 0 ? (
          state.isEmpty ? (
            <Text c='dimmed' align='center' w='100%' size='sm'>
              no material
            </Text>
          ) : (
            <CapturePreviewPlaceholder active={active} />
          )
        ) : null}
      </Flex>

      <Flex align='center' justify='flex-end' px='xs'>
        {state.totalNetWeight === null ? (
          <NetWeightZero unit={WeightUnit.GRAM} />
        ) : (
          <NetWeight weight={state.totalNetWeight} />
        )}
      </Flex>
    </div>
  );
}

function BottomRow(props: { session: SamplingSuiteSessionDTO }) {
  const { session } = props;

  const closeMutation = useCloseSamplingSuiteSession();

  const numStates = session.materialClassStates.length;
  const numReadyStates = session.materialClassStates.reduce(
    (t, s) =>
      t +
      ((s.isEmpty && s.assignedCaptures.length === 0) ||
      (!s.isEmpty && s.assignedCaptures.length > 0)
        ? 1
        : 0),
    0,
  );

  const allRowsReady = numStates === numReadyStates;

  const anyMaterialClassAssignments = session.materialClassStates.some(
    (s) => s.materialClassId !== null && !s.isEmpty,
  );

  const anyUnassignedCaptures = session.linkedCaptures.length > 0;

  const completable =
    allRowsReady && !anyUnassignedCaptures && anyMaterialClassAssignments;

  const [showNotCompleteableExplainer, setShowNotCompleteableExplainer] =
    useState(false);

  const notCompletableExplainer = completable ? null : (
    <Alert
      title='Analysis Cannot be Completed'
      color='orange'
      withCloseButton
      onClose={() => setShowNotCompleteableExplainer(false)}
    >
      {anyMaterialClassAssignments ? null : (
        <Text>At least one material class must have a capture.</Text>
      )}
      {allRowsReady ? null : (
        <Text>All rows must either be marked as empty, or have a capture.</Text>
      )}
      {anyUnassignedCaptures ? (
        <Text>All staged captures must be assigned or unlinked.</Text>
      ) : null}
    </Alert>
  );

  const completeButton = (
    <Button
      leftIcon={completable ? <IconChecks /> : <IconAlertHexagon />}
      variant={completable ? 'filled' : 'outline'}
      color={completable ? 'teal' : 'orange'}
      onClick={() => {
        if (completable) {
          closeMutation.mutate(
            {
              sessionId: session.id,
              close: {
                complete: true,
              },
            },
            {
              onSuccess() {
                Router.replace('SampleDetail', {
                  sampleId: session.sampleId,
                });
                showNotification({
                  title: 'Session Completed',
                  message: `Your VALI-Sample session was completed successfully.`,
                  color: 'green',
                  icon: <IconCheck />,
                });
              },
            },
          );
        } else {
          setShowNotCompleteableExplainer(true);
        }
      }}
    >
      Complete Analysis
    </Button>
  );

  let endButtons = (
    <Stack w='100%'>
      {showNotCompleteableExplainer ? notCompletableExplainer : null}
      <Group spacing='xs' position='apart' w='100%'>
        <Button
          leftIcon={<IconPlayerStop />}
          variant='outline'
          color='gray'
          loading={closeMutation.isLoading}
          onClick={() => {
            closeMutation.mutate(
              { sessionId: session.id, close: { complete: false } },
              {
                onSuccess() {
                  Router.replace('SampleDetail', {
                    sampleId: session.sampleId,
                  });
                  showNotification({
                    title: 'Session Closed',
                    message: `Your VALI-Sample session was closed successfully.`,
                    color: 'green',
                    icon: <IconCheck />,
                  });
                },
              },
            );
          }}
        >
          Close Session
        </Button>
        <div>{completeButton}</div>
      </Group>
    </Stack>
  );

  if (closeMutation.isError) {
    endButtons = (
      <MutationErrorAlert
        errorTitle={'Error closing session'}
        entityName={'session'}
        mutation={closeMutation}
        formVariant={'close'}
      />
    );
  }

  return (
    <div className={classes.bottomRow}>
      <Stack align='center'>
        <Stack spacing='xs' w='100%' align='center'>
          <Text size='sm'>
            {numReadyStates}/{numStates} rows ready
          </Text>
          <Progress
            color={
              anyMaterialClassAssignments
                ? allRowsReady
                  ? 'teal'
                  : 'blue'
                : 'orange'
            }
            value={(100 * numReadyStates) / numStates}
            w='100%'
          />
        </Stack>
        {endButtons}
      </Stack>
    </div>
  );
}

const previewImageHeight = 150;
const previewImageWidth = 224.517;

function CapturePreviewLoader() {
  return (
    <Skeleton
      width={`${previewImageWidth}px`}
      height={`${previewImageHeight}px`}
      animate
    />
  );
}

function CapturePreviewPlaceholder(props: { active: boolean }) {
  const { active } = props;
  return (
    <Paper
      className={classes.capturePreviewPlaceholder}
      w={`${previewImageWidth}px`}
      h={`${previewImageHeight}px`}
      withBorder
    >
      <Stack align='center' justify='center' h='100%'>
        {active ? (
          <Text>awaiting capture</Text>
        ) : (
          <Text c='dimmed' size='sm'>
            capture required
          </Text>
        )}
      </Stack>
    </Paper>
  );
}

interface CaptureButtonProps {
  className?: string;
  onClick: () => void;
  color?: ActionIconProps['color'];
  icon?: ReactNode;
  label?: string;
}

function CapturePreview(props: {
  capture: SamplingSuiteCaptureDTO;
  onMaximize: () => void;
  leftButtonProps?: CaptureButtonProps;
  rightButtonProps?: CaptureButtonProps;
  loading?: boolean;
  className?: string;
}) {
  const {
    capture,
    onMaximize,
    leftButtonProps,
    rightButtonProps,
    loading,
    className,
  } = props;

  const previewObjectUrl = useLowResCaptureObjectUrl(capture.captureId);

  return (
    <div className={`${classes.previewImageContainer} ${className ?? ''}`}>
      {previewObjectUrl ? (
        <img
          className={classes.previewImg}
          src={previewObjectUrl}
          height={previewImageHeight}
        />
      ) : (
        <CapturePreviewLoader />
      )}

      <Group
        className={classes.previewImageControls}
        noWrap
        align='stretch'
        spacing={0}
        w='100%'
      >
        {leftButtonProps && (
          <ActionIcon
            className={leftButtonProps.className}
            h='100%'
            radius={0}
            variant='filled'
            {...leftButtonProps}
            loading={loading}
            sx={{
              flexBasis: '30%',
              flexGrow: 0,
              flexShrink: 0,
            }}
          >
            <Stack align='center'>
              {leftButtonProps.icon || <IconArrowLeft />}

              {leftButtonProps.label && (
                <Text size='sm'>{leftButtonProps.label}</Text>
              )}
            </Stack>
          </ActionIcon>
        )}

        <ActionIcon
          h='100%'
          radius={0}
          variant='filled'
          loading={loading}
          sx={{ flexBasis: '100%', flexShrink: 1 }}
          onClick={onMaximize}
        >
          <Stack align='center'>
            <IconMaximize />
            <Text size='sm'>maximize</Text>
          </Stack>
        </ActionIcon>

        {rightButtonProps && (
          <ActionIcon
            className={rightButtonProps.className}
            h='100%'
            radius={0}
            variant='filled'
            {...rightButtonProps}
            loading={loading}
            sx={{
              flexBasis: '30%',
              flexGrow: 0,
              flexShrink: 0,
            }}
          >
            <Stack align='center'>
              {rightButtonProps.icon || <IconArrowRight />}
              {rightButtonProps.label && (
                <Text size='sm'>{rightButtonProps.label}</Text>
              )}
            </Stack>
          </ActionIcon>
        )}
      </Group>
    </div>
  );
}

function AssignedCapture(props: {
  capture: SamplingSuiteCaptureDTO;
  session: SamplingSuiteSessionDTO;
}) {
  const { capture, session } = props;

  const patchMutation = usePatchSamplingSuiteCapture();

  const openLightbox = useCaptureLightboxStore((s) => s.open);

  return (
    <CapturePreview
      capture={capture}
      loading={!patchMutation.isIdle}
      onMaximize={() => openLightbox('assigned', capture.captureId)}
      rightButtonProps={{
        onClick: () => {
          patchMutation.mutate({
            capture,
            patch: {
              sampleAnalysisLink: {
                samplingSuiteSampleAnalysisId:
                  session.samplingSuiteSampleAnalysisId,
                materialClassAssignment: null,
              },
            },
          });
        },
        color: 'orange',
        label: 'unassign',
      }}
    />
  );
}

function LinkedCaptureTable(props: { session: SamplingSuiteSessionDTO }) {
  const { session } = props;

  if (session.linkedCaptures.length === 0) {
    return (
      <Alert color='teal' icon={<IconCheck />}>
        No unassigned captures.
      </Alert>
    );
  }

  return (
    <Flex direction={'column'} gap='sm'>
      {session.linkedCaptures.map((c) => (
        <LinkedCaptureRow
          key={c.captureId}
          linkedCapture={c}
          session={session}
        />
      ))}
    </Flex>
  );
}

function LinkedCaptureRow(props: {
  session: SamplingSuiteSessionDTO;
  linkedCapture: SamplingSuiteCaptureDTO;
}) {
  const { session, linkedCapture } = props;

  const patchMutation = usePatchSamplingSuiteCapture();
  const openLightbox = useCaptureLightboxStore((s) => s.open);

  return (
    <CapturePreview
      className={classes.linkedCapturePreview}
      capture={linkedCapture}
      onMaximize={() => openLightbox('unassigned', linkedCapture.captureId)}
      leftButtonProps={{
        className: classes.assignButton,
        color: 'blue',
        onClick: () => {
          const patch: SamplingSuiteCapturePatchDTO = {
            sampleAnalysisLink: {
              samplingSuiteSampleAnalysisId:
                session.samplingSuiteSampleAnalysisId,
              materialClassAssignment: {
                materialClassId: session.activeMaterialClassId,
              },
            },
          };
          patchMutation.mutate(
            { capture: linkedCapture, patch },
            {
              // TODO: error/success actions
            },
          );
        },
        label: 'assign',
      }}
      rightButtonProps={{
        color: 'orange',
        onClick: () => {
          const patch: SamplingSuiteCapturePatchDTO = {
            sampleAnalysisLink: null,
          };
          patchMutation.mutate(
            { capture: linkedCapture, patch },
            {
              // TODO: error/success actions
            },
          );
        },
        label: 'unlink',
      }}
    />
  );
}

function UnlinkedCaptureSection(props: { session: SamplingSuiteSessionDTO }) {
  const { session } = props;
  // TODO: Toggle to include/exclude captures from other suites?

  // TODO: Render this only when there are unlinked captures

  const unlinkedCapturesQuery = useSamplingSuiteCaptures({
    unlinked: true,
    suiteId: null,
    archived: false,
  });

  let body: ReactNode;
  if (unlinkedCapturesQuery.data) {
    const unlinkedCaptures = unlinkedCapturesQuery.data;
    if (unlinkedCaptures.length === 0) {
      return null;
    }

    body = (
      <>
        <UnlinkedCaptureTable
          unlinkedCaptures={unlinkedCaptures}
          session={session}
        />
        <UnlinkedCapturesLightbox unlinkedCaptures={unlinkedCaptures} />
      </>
    );
  } else if (unlinkedCapturesQuery.isError) {
    body = (
      <Alert color='red' title='Error'>
        An error ocurred trying to load unlinked captures.
      </Alert>
    );
  }

  return (
    <Paper className={classes.stickyTool} p='sm' withBorder>
      <Stack align='center'>
        <Title order={3}>
          Unlinked{' '}
          {unlinkedCapturesQuery.data && (
            <Text span color='dimmed'>
              ({unlinkedCapturesQuery.data.length})
            </Text>
          )}
        </Title>
        {body}
      </Stack>
    </Paper>
  );
}

function UnlinkedCaptureTable(props: {
  unlinkedCaptures: SamplingSuiteCaptureDTO[];
  session: SamplingSuiteSessionDTO;
}) {
  const { unlinkedCaptures, session } = props;

  const pageSize = 5;
  const [activePage, setPage] = useState(1);

  const totalPages = Math.ceil(unlinkedCaptures.length / pageSize);
  const paginatedCaptures = unlinkedCaptures.slice(
    (activePage - 1) * pageSize,
    activePage * pageSize,
  );
  return (
    <Stack align='center'>
      {paginatedCaptures.map((capture) => (
        <UnlinkedCaptureRow
          key={capture.captureId}
          unlinkedCapture={capture}
          session={session}
        />
      ))}
      {totalPages > 1 ? (
        <Pagination value={activePage} onChange={setPage} total={totalPages} />
      ) : null}
    </Stack>
  );
}

function UnlinkedCaptureRow(props: {
  unlinkedCapture: SamplingSuiteCaptureDTO;
  session: SamplingSuiteSessionDTO;
}) {
  const { unlinkedCapture, session } = props;

  const patchMutation = usePatchSamplingSuiteCapture();
  const openLightbox = useCaptureLightboxStore((s) => s.open);

  return (
    <CapturePreview
      capture={unlinkedCapture}
      onMaximize={() => openLightbox('unlinked', unlinkedCapture.captureId)}
      leftButtonProps={{
        color: 'blue',
        label: 'link',
        onClick: () => {
          if (!patchMutation.isIdle) return;
          patchMutation.mutate({
            capture: unlinkedCapture,
            patch: {
              sampleAnalysisLink: {
                samplingSuiteSampleAnalysisId:
                  session.samplingSuiteSampleAnalysisId,
                materialClassAssignment: null,
              },
            },
          });
        },
      }}
      rightButtonProps={{
        color: 'red',
        onClick: () => {
          if (!patchMutation.isIdle) return;
          patchMutation.mutate({
            capture: unlinkedCapture,
            patch: {
              archived: true,
            },
          });
        },
        label: 'archive',
        icon: <IconArchive />,
      }}
    />
  );
}

function InstanceActionCluster() {
  return (
    <Stack spacing='xs'>
      <Button
        color='indigo'
        variant='outline'
        leftIcon={<IconScale />}
        onClick={() => {
          // TODO: Implement scale calibration
        }}
        disabled
      >
        Calibrate Scale
      </Button>
      <Button
        color='indigo'
        variant='outline'
        leftIcon={<IconCamera />}
        onClick={() => {
          // TODO: Implement manual capture trigger
        }}
        disabled
      >
        Trigger Capture
      </Button>
    </Stack>
  );
}
