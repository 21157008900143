import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import dayjs, { Dayjs } from 'dayjs';
import { z } from 'zod';
import { useFacilityContext } from '../Facility/FacilityContext';
import { ProcessSelect } from '../Process/ProcessSelect';
import { RequiredDayjsDateTimePicker } from '../Time/DayjsDateTimePicker';
import { ProcessId } from '../rest-client';

const ProcessBufferDepletionFormSchema = z
  .object({
    depletedAt: z.instanceof(dayjs as unknown as typeof Dayjs, {
      message: 'Depleted at time is required',
    }),
    processId: z.string().uuid().nullable(),
  })
  .refine(({ processId }) => processId !== null, {
    path: ['processId'],
    message: 'Process is required',
  });

export type ProcessBufferDepletionFormValues = z.infer<
  typeof ProcessBufferDepletionFormSchema
>;

export type ProcessBufferDepletionFormProps = {
  processId?: ProcessId;
};

export function useProcessBufferDepletionForm(
  props: ProcessBufferDepletionFormProps,
) {
  const { processId } = props;
  return useForm<ProcessBufferDepletionFormValues>({
    initialValues: {
      depletedAt: dayjs.utc(),
      processId: processId ?? null,
    },
    validate: zodResolver(ProcessBufferDepletionFormSchema),
  });
}

export function ProcessBufferDepletionFormFields(props: {
  form: UseFormReturnType<ProcessBufferDepletionFormValues>;
}) {
  const { form } = props;

  const facility = useFacilityContext();

  const processSelectDisabled =
    form.values.processId !== null && !form.isDirty('processId');

  return (
    <>
      <RequiredDayjsDateTimePicker
        label='Feedstock Depletion Time'
        description='The time at which the feedstock material was depleted or removed from the process.'
        tz={facility.timeZoneId}
        withAsterisk
        {...form.getInputProps('depletedAt')}
      />

      <ProcessSelect
        label='Process'
        description='Process having its feedstock material depleted.'
        placeholder='select process'
        disabled={processSelectDisabled}
        withAsterisk
        {...form.getInputProps('processId')}
      />
    </>
  );
}
